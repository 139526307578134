import PropTypes from 'prop-types';
import React from 'react';

import FrameSelection from '../shared/FrameSelection';
import Review from '../shared/Review';

import Background from './Background';
import Details from './Details';
import Details2 from './Details2';
import validate from './validate';

const Grandparent = (props, formProps) => (
    <>
        {/* TODO: Only pass in the formProps and props required */}
        {props.step === 'frame' && (
            <FrameSelection
                {...formProps}
                {...props}
                project={props.project?.project}
                validate={validate}
                fields={{
                    default: [
                        'baseColor',
                        'imageMeta',
                        'firstName',
                        'subject',
                        'nickname',
                        'birthDate',
                    ],
                    details: [
                        'luckyNumberOrCustom',
                        'luckyNumberCustomNumber',
                        'thought',
                        'residenceLocationData',
                    ],
                    details2: [
                        'parentType',
                        'descriptor',
                        'adjective1',
                        'adjective2',
                        'loveStatement',
                        'alwaysStatement',
                        'neverStatement',
                        'adviceStatement',
                        'lessonStatement',
                    ],
                    review: ['accept'],
                }}
            />
        )}
        {props.step === 'review' && (
            <Review
                {...formProps}
                {...props}
                project={props.project?.project}
            />
        )}
        {props.step === 'details2' && (
            <Details2
                {...formProps}
                {...props}
                project={props.project?.project}
            />
        )}
        {props.step === 'details' && (
            <Details
                {...formProps}
                {...props}
                project={props.project?.project}
            />
        )}
        {!props.step && (
            <Background
                {...formProps}
                {...props}
                project={props.project?.project}
            />
        )}
    </>
);

Grandparent.propTypes = {
    project: PropTypes.shape({
        project: PropTypes.object,
    }),
    step: PropTypes.string,
};

export default Grandparent;
