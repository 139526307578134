import PropTypes from 'prop-types';
import * as React from 'react';
import cx from 'classnames';
import omit from 'lodash/omit';

import styles from './Text.scss';

const Text = props => {
    const spreadProps = omit(props, [
        'isError',
        'isMonospace',
        'isWarning',
        'tag',
        'theme',
    ]);
    const TagName = props.tag;
    const classNames = cx(props.className, styles[props.theme], {
        [styles['is-error']]: props.isError,
        [styles['is-monospace']]: props.isMonospace,
        [styles['is-warning']]: props.isWarning,
    });

    if (props.number) {
        return (
            <div className={styles['with-number']}>
                <h6 className={styles.number}>{props.number}/</h6>
                <TagName {...spreadProps} className={props.className}>
                    {props.children}
                </TagName>
            </div>
        );
    }
    return (
        <TagName {...spreadProps} className={classNames}>
            {props.children}
        </TagName>
    );
};

Text.propTypes = {
    children: PropTypes.any,
    className: PropTypes.string,
    isError: PropTypes.bool,
    isMonospace: PropTypes.bool,
    isWarning: PropTypes.bool,
    number: PropTypes.any,
    tag: PropTypes.string,
    theme: PropTypes.string,
};

Text.defaultProps = {
    className: '',
    isError: false,
    isMonospace: false,
    isWarning: false,
    tag: 'span',
    theme: 'body1',
};

export default Text;
