import axios from 'axios';
import getConfig from 'core/config';

import * as Exceptions from './exceptions';

export default ({ timeout = 30000, onError = () => {} } = {}) => {
    const api = axios.create({
        baseURL: `${getConfig('apiEndpoint', '')}v2/`,
        timeout,
    });

    api.interceptors.request.use(
        async config => {
            // Global request interceptor
            config.headers['sn-fb-app-id'] = getConfig('fbAppId');
            config.headers['sn-client-url'] = getConfig('clientUrl');
            return config;
        },
        error => {
            // Do something with request error
            // Global request error interceptor
            return Promise.reject(error);
        }
    );

    api.interceptors.response.use(
        response => {
            // Global response interceptor
            return response;
        },
        error => {
            if (onError) {
                onError(error);
            }

            if (error.code == 'ECONNABORTED') {
                console.warn('Response Timeout');
                throw new Exceptions.TimeoutException(); // error handles how to deal with response
            }
            const response = error.response ? error.response : null;

            if (response?.data?.error?.type in Exceptions) {
                // We have a defined custom exception
                const ResponseException =
                    Exceptions[
                        // eslint-disable-next-line import/namespace
                        response?.data?.error?.type || 'UnknownException'
                        // eslint-disable-next-line import/namespace
                    ] || Exceptions.UnknownException;
                throw new ResponseException(response.data.error); // error handles how to deal with response
            }
            throw error;
        }
    );
    return api;
};
