import { nanoid } from 'nanoid';

export default class ProjectService {
    api;

    constructor(apiService) {
        this.api = apiService;
    }

    get store() {
        return window.localStorage;
    }

    async getNewId() {
        const id = await nanoid();
        return `projv2_${id}`;
    }

    async createAnonymous(project) {
        const {
            id,
            shopifyProductHandle,
            shopifyProductId,
            builderHandle,
            metadata,
            title,
        } = project;

        const response = await this.api.post('/projects', {
            id,
            title,
            shopifyProductHandle,
            shopifyProductId,
            builderHandle,
            metadata,
        });
        return response.data;
    }

    async create(project) {
        const {
            id,
            shopifyProductHandle,
            shopifyProductId,
            builderHandle,
            metadata,
            title,
        } = project;

        const response = await this.api.post(
            '/projects',
            {
                id,
                title,
                shopifyProductHandle,
                shopifyProductId,
                builderHandle,
                metadata,
            },
            { withSession: true, withUser: true }
        );
        return response.data;
    }

    async duplicate(projectId) {
        const response = await this.api.patch(
            `/projects/${projectId}/?action=duplicate`,
            {},
            {
                withUser: true,
                withSession: true,
            }
        );
        return response.data;
    }

    async getById(projectId) {
        const response = await this.api.get(`/projects/${projectId}`, {
            withUser: true,
            withSession: true,
        });
        return response.data;
    }

    async getByHandleAnonymous(handle) {
        const project = this.getTempProject(handle);
        return project;
    }

    async save(project) {
        const {
            title,
            metadata,
            builderHandle,
            shopifyProductHandle,
            shopifyProductId,
        } = project;
        const response = await this.api.patch(
            `/projects/${project.id}`,
            {
                title,
                shopifyProductHandle,
                shopifyProductId,
                builderHandle,
                metadata,
            },
            { withSession: true, withUser: true }
        );
        return response.data;
    }

    async delete(projectId) {
        const response = await this.api.delete(`/projects/${projectId}`, {
            withSession: true,
            withUser: true,
        });
        return response.data;
    }

    async getAll() {
        const response = await this.api.get(`/projects`, {
            withSession: true,
            withUser: true,
        });
        return response.data;
    }

    get tempStore() {
        return window.sessionStorage;
    }

    saveTempProject(project, handle) {
        this.tempStore.setItem(
            'projects',
            JSON.stringify({ ...this.projects, [handle]: project })
        );
        return project;
    }

    getTempProject(handle) {
        const projects = this.tempStore.getItem('projects');
        if (projects) {
            return (
                JSON.parse(projects, (key, value) => {
                    if (value === 'true' || value === 'false') {
                        return value === 'true';
                    }
                    return value;
                })[handle] || false
            );
        }
    }

    get projects() {
        const projects = this.tempStore.getItem('projects');
        if (projects) {
            return JSON.parse(projects) || {};
        }
        return {};
    }

    clearTempProject(handle) {
        this.tempStore.setItem(
            'projects',
            JSON.stringify({ ...this.projects, [handle]: undefined })
        );
    }
}
