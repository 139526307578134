import React from 'react';
import Field from 'redux-form/es/Field';
import { Col, Row } from 'stillnovel/components/UI/Grid';
import FieldInput from 'stillnovel/components/UI/Input/FieldInput';
import FieldSelect from 'stillnovel/components/UI/Input/FieldSelect';
import Text from 'stillnovel/components/UI/Text';

const Dog = () => {
    return (
        <>
            <Row alignment="bottom">
                <Col>
                    <Text tag="label" theme="form-title" htmlFor="breed">
                        Breed
                    </Text>
                    <Field
                        name="breed"
                        type="text"
                        component={FieldInput}
                        placeholder="EX: “Labradoodle” or “Mix Breed”"
                        autoComplete={`breed`}
                        autoCorrect="off"
                        maxLength={30}
                    />
                </Col>
                <Col>
                    <Text
                        tag="label"
                        theme="form-title"
                        htmlFor="dogBreedGroup"
                    >
                        Breed Group
                    </Text>
                    <Field name="dogBreedGroup" component={FieldSelect} stretch>
                        <option value="" disabled>
                            --
                        </option>
                        <option value="SPORTING">Sporting</option>
                        <option value="HOUND">Hound</option>
                        <option value="TERRIER">Terrier</option>
                        <option value="HERDING">Herding</option>
                        <option value="WORKING">Working</option>
                        <option value="NON_SPORTING">Non Sporting</option>
                        <option value="TOY">Toy</option>
                        <option value="RESCUE">Rescue</option>
                        <option value="MIXED_BREED">Mixed Breed</option>
                    </Field>
                </Col>
            </Row>
        </>
    );
};

export default Dog;
