import SN from 'stillnovel/services';
import { useCallback, useState } from 'react';
import { v4 as uuid } from 'uuid';
import { useSelector } from 'react-redux';
import AWS from 'aws-sdk';

const useUpload = () => {
    const [uploadProgress, setUploadProgress] = useState(0);

    const cognitoIdentityId = useSelector(
        state => state.auth?.user?.cognitoIdentityId
    );

    const upload = useCallback(
        async (file, path = '') => {
            if (!cognitoIdentityId) {
                throw new Error('No cognitoIdentityId');
            }

            await SN.auth.getSession();
            await SN.auth.refreshAWSSession();

            const S3Client = await SN.aws.s3();

            const extension = file.name.split('.').pop();
            const filename = `${uuid()}.${extension.toLowerCase()}`;
            const photoKey = `users/uploads/${
                cognitoIdentityId || 'unknown-cognitoIdentityId'
            }/${path}${filename}`;

            setUploadProgress(1); // instant feedback

            await S3Client.upload({
                Key: photoKey,
                Body: file,
                ACL: 'public-read',
                Metadata: {
                    name: file.name || 'null',
                    size: file.size.toString() || 'null',
                    type: file.type || 'null',
                },
            })
                .on('httpUploadProgress', function (progress) {
                    const loaded =
                        this.numParts > 1
                            ? this.totalUploadedBytes
                            : progress.loaded;

                    const partProgress = parseInt(
                        (loaded * 100) / progress.total,
                        10
                    );
                    if (
                        (this.activeParts > 0 && this.numParts > 1) ||
                        this.numParts === 1
                    ) {
                        setUploadProgress(partProgress);
                    }
                })
                .promise();
            setUploadProgress(100);

            return { filename, path: photoKey, cognitoIdentityId };
        },
        [cognitoIdentityId]
    );

    return [upload, uploadProgress];
};

export { useUpload };
