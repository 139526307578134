import PropTypes from 'prop-types';
import React, { forwardRef } from 'react';
import cx from 'classnames';
import SvgIcon from 'stillnovel/components/UI/SvgIcon';
import Text from 'stillnovel/components/UI/Text';
import { sanitizeString } from 'stillnovel/utils';

import styles from './Input.scss';

const Input = forwardRef(
    (
        {
            className,
            error,
            id,
            label,
            labelTheme,
            name,
            readOnly,
            showErrorText,
            stretch,
            touched,
            type,
            inline,
            pattern,
            theme,
            ...props
        },
        ref
    ) => {
        const classNames = cx(styles.input, styles[theme], className, {
            [styles.stretch]: stretch,
            [styles.inline]: inline,
            [styles.textarea]: type === 'textarea',
            [styles['has-error']]: touched && error,
        });
        if (type === 'radio') {
            return (
                <span
                    className={cx(styles.radio, className, {
                        [styles.inline]: inline,
                    })}
                >
                    <input
                        {...props}
                        ref={ref}
                        id={id || name}
                        className={styles.input}
                        type="radio"
                        readOnly={readOnly}
                    />
                    <Text
                        tag="label"
                        htmlFor={!readOnly ? id : null}
                        className={styles.label}
                        theme={labelTheme}
                    >
                        {label}
                    </Text>
                </span>
            );
        }
        if (type === 'select') {
            return (
                <div
                    className={cx(
                        styles.select,
                        touched && error && styles['has-error']
                    )}
                >
                    <select
                        {...props}
                        ref={ref}
                        id={id || name}
                        name={name}
                        className={classNames}
                        required
                    />
                    <SvgIcon
                        iconType="chevron"
                        className={styles['select-arrow']}
                    />
                </div>
            );
        }
        if (type === 'textarea') {
            return (
                <React.Fragment>
                    <textarea
                        {...props}
                        ref={ref}
                        id={id}
                        name={name}
                        className={classNames}
                        value={sanitizeString(props.value)}
                    />
                    {showErrorText && error && (
                        <Text isError>{showErrorText && touched && error}</Text>
                    )}
                </React.Fragment>
            );
        }
        return (
            <React.Fragment>
                <input
                    {...props}
                    ref={ref}
                    id={id || name}
                    pattern={pattern}
                    name={name}
                    type={type}
                    className={classNames}
                    spellCheck="false"
                    value={sanitizeString(props.value)}
                />
                {showErrorText && error && (
                    <Text isError>{showErrorText && touched && error}</Text>
                )}
            </React.Fragment>
        );
    }
);

Input.displayName = 'Input';

Input.propTypes = {
    className: PropTypes.string,
    error: PropTypes.string,
    id: PropTypes.string,
    inline: PropTypes.bool,
    label: PropTypes.string,
    labelTheme: PropTypes.string,
    name: PropTypes.string,
    pattern: PropTypes.any,
    readOnly: PropTypes.bool,
    showErrorText: PropTypes.bool,
    stretch: PropTypes.bool,
    theme: PropTypes.oneOf(Object.keys(styles)),
    touched: PropTypes.bool,
    type: PropTypes.string,
    value: PropTypes.string,
};

export default Input;
