import { registerActionTypes, registerStates } from 'stillnovel/constants';

export const actionTypes = registerActionTypes('PROJECTS', [
    'FETCH_REQUESTED',
    'FETCH_SUCCEEDED',
    'FETCH_FAILED',
    'DUPLICATE_REQUESTED',
    'DUPLICATE_SUCCEEDED',
    'DUPLICATE_FAILED',
    'DELETE_REQUESTED',
    'DELETE_SUCCEEDED',
    'DELETE_FAILED',
    'RESET',
    'UPDATE_PROJECT',
]);

export const duplicateProject = id => ({
    type: actionTypes.DUPLICATE_REQUESTED,
    payload: {
        id: id,
    },
});

export const getProjects = () => ({
    type: actionTypes.FETCH_REQUESTED,
});

export const deleteProject = id => ({
    type: actionTypes.DELETE_REQUESTED,
    payload: {
        id: id,
    },
});
export const resetProjects = () => ({
    type: actionTypes.RESET,
});
