// import { createBrowserHistory } from 'history';
// import { routerMiddleware } from 'connected-react-router';
import { createStore, applyMiddleware, compose } from 'redux';
import { thunk } from 'redux-thunk';
import createSagaMiddleware from 'redux-saga';
import * as Sentry from '@sentry/react';
import d from 'debug';
import toLower from 'lodash/toLower';
import startCase from 'lodash/startCase';

import rootReducer from './reducers';
import rootSagas from './sagas';

const debug = d('App');

const breadcrumbMiddleware = () => next => action => {
    const [category, event] = action.type.split('/');

    const ignore = ['@@redux-form', 'UPDATE_META_VALUE'];

    if (!ignore.includes(category) && !ignore.includes(event)) {
        Sentry.addBreadcrumb({
            category: startCase(toLower(category)),
            message: startCase(toLower(event)),
            level: 'info',
        });
    }

    next(action);
};

const sagaMiddleware = createSagaMiddleware({
    onError: error => {
        Sentry.captureException(error);
        const sagaErrorEvent = new CustomEvent('sagaError', {
            detail: error,
        });
        window.dispatchEvent(sagaErrorEvent);
        throw error;
    },
});

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const getMiddleware = () => {
    const middlewares = [thunk, sagaMiddleware, breadcrumbMiddleware];

    if (process.env.NODE_ENV !== 'production') {
        middlewares.push(
            require('redux-immutable-state-invariant').default({
                ignore: ['project.snapshot'],
            })
        );
    }

    return middlewares;
};

export default function configureStore(initialState) {
    const store = createStore(
        rootReducer,
        initialState,
        composeEnhancers(applyMiddleware(...getMiddleware()))
    );

    sagaMiddleware.run(rootSagas);

    if (module.hot) {
        // Enable webpack hot module replacement for reducers
        module.hot.accept('./reducers', async () => {
            debug('Reloading Reducers');
            store.replaceReducer(require('./reducers').default);
        });
    }
    return store;
}
