import React from 'react';
import PropTypes from 'prop-types';

import styles from './Container.scss';

const Container = ({ ...props }) => (
    <section className={styles.container} {...props} />
);

Container.propTypes = {
    children: PropTypes.node.isRequired,
};

export default Container;
