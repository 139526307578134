import {
    loggingIn,
    configure,
    authenticated,
} from 'stillnovel/store/auth/actions';
import SN from 'stillnovel/services';

/**
 * sets up auth.
 */
const setupAuth = async (store, config) => {
    const searchParams = new URLSearchParams(window.location.search);
    if (searchParams.has('code')) {
        const code = searchParams.get('code');
        window.history.replaceState(null, '', window.location.pathname);
        const user = await SN.auth.authenticateShopify(code);
        store.dispatch(authenticated(user));
    } else {
        store.dispatch(configure(config));

        store.dispatch(loggingIn());
    }
};

export { setupAuth };
