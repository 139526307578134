import PropTypes from 'prop-types';
import React from 'react';

import FrameSelection from '../shared/FrameSelection';
import Review from '../shared/Review';

import Theme from './01-Theme';
import Photo from './02-Photo';
import Style from './03-Style';
import Details from './04-Details';
import Stats from './05-Stats';
import validate from './validate';

const BirthStat = (props, formProps) => (
    <>
        {/* TODO: Only pass in the formProps and props required */}
        {props.step === 'frame' && (
            <FrameSelection
                {...formProps}
                {...props}
                project={props.project?.project}
                validate={validate}
                fields={{
                    default: ['firstName', 'lastName'],
                    photo: ['imageMeta'],
                    style: ['baseColor'],
                    details: [
                        'firstName',
                        'lastName',
                        'birthDate',
                        'birthLocationData',
                        'dueDate',
                        'thought',
                        'len',
                        'weightLbs',
                        'weightOz',
                    ],
                    stats: ['nickname', 'gender', 'childWord'],
                    review: ['accept'],
                }}
            />
        )}
        {props.step === 'stats' && (
            <Stats {...formProps} {...props} project={props.project?.project} />
        )}
        {props.step === 'review' && (
            <Review
                {...formProps}
                {...props}
                project={props.project?.project}
            />
        )}
        {props.step === 'details' && (
            <Details
                {...formProps}
                {...props}
                project={props.project?.project}
            />
        )}
        {props.step === 'style' && (
            <Style {...formProps} {...props} project={props.project?.project} />
        )}
        {props.step === 'photo' && (
            <Photo {...formProps} {...props} project={props.project?.project} />
        )}
        {!props.step && (
            <Theme {...formProps} {...props} project={props.project?.project} />
        )}
    </>
);

BirthStat.propTypes = {
    metadata: PropTypes.object,
    project: PropTypes.shape({
        project: PropTypes.object,
    }),
    step: PropTypes.string,
};
export default BirthStat;
