import PropTypes from 'prop-types';
import React from 'react';

import FrameSelection from '../shared/FrameSelection';
import Review from '../shared/Review';

import Background from './Background';
import Details from './Details';
import validate from './validate';

const TravelSnapshot = (props, formProps) => (
    <>
        {/* TODO: Only pass in the formProps and props required */}
        {props.step === 'frame' && (
            <FrameSelection
                {...formProps}
                {...props}
                project={props.project?.project}
                validate={validate}
                fields={{
                    default: [
                        'baseColor',
                        'imageMeta',
                        'firstName',
                        'lastName',
                    ],
                    details: ['birthDate', 'thought', 'timeOfDay', 'weather'],
                    review: ['accept'],
                }}
            />
        )}
        {props.step === 'details' && (
            <Details
                {...formProps}
                {...props}
                project={props.project?.project}
            />
        )}
        {props.step === 'review' && (
            <Review
                {...formProps}
                {...props}
                project={props.project?.project}
            />
        )}
        {!props.step && (
            <Background
                {...formProps}
                {...props}
                project={props.project?.project}
            />
        )}
    </>
);

TravelSnapshot.propTypes = {
    metadata: PropTypes.object,
    project: PropTypes.shape({
        project: PropTypes.object,
    }),
    step: PropTypes.string,
};

export default TravelSnapshot;
