import Shopify from 'shopify-buy';
import axios from 'axios';
import _memoize from 'lodash/memoize';
import getConfig from 'core/config';

import apiService from './api';
import AuthenticationService from './AuthenticationService';
import AWSService from './AWSService';
import CartService from './Cart/CartService';
import ProjectService from './ProjectService';
import SupportService from './SupportService';
import WeatherService from './WeatherService';
import OrderService from './OrderService';
import UserService from './UserService';
import AdminService from './AdminService';
import * as Exceptions from './exceptions/';

const SN = _memoize(config => {
    const api = apiService(config);

    const shopifyClient = Shopify.buildClient({
        storefrontAccessToken: getConfig('shopifyStorefrontAccessToken'),
        domain: getConfig('shopifyStorefrontDomain'),
    });

    return {
        api,
        shopifyClient,
        auth: new AuthenticationService(api, config.auth || {}),
        aws: new AWSService(),
        cart: new CartService(shopifyClient),
        order: new OrderService(api),
        user: new UserService(api),
        project: new ProjectService(api),
        support: new SupportService(axios.create()),
        weather: new WeatherService(api),
        admin: new AdminService(api),
    };
});

export { Exceptions };

export default SN;
