import querystring from 'querystring';

import PropTypes from 'prop-types';
import React, { cloneElement } from 'react';

import getConfig from 'core/config';

const AuthTrigger = props => {
    const handleClick = async () => {
        const query = querystring.parse(location.search.substring(1));

        const state = {
            shopifyAccountStoreId: getConfig('shopifyAccountStoreId'),
            shopifyAccountClientId: getConfig('shopifyAccountClientId'),
            clientUrl: getConfig('clientUrl'),
            pathname: window.location.pathname,
            checkoutUrl: query?.checkout_url,
        };

        const url = `${getConfig('apiEndpoint')}v2/auth/shopify/authorize?state=${Buffer.from(JSON.stringify(state)).toString('base64')}`;

        window.location = url;
    };

    return (
        <>
            {cloneElement(props.children, {
                onClick: handleClick,
            })}
        </>
    );
};

AuthTrigger.propTypes = {
    children: PropTypes.any,
};

export default AuthTrigger;
