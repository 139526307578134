import actions from 'redux-form/es/actions';
import { put } from 'redux-saga/effects';
import {
    scopedMetaUpdateHandler,
    handleImageMeta,
} from 'stillnovel/store/project/shared';

function* handleStyle() {
    // clear any incompatible values here on style change
    yield put(actions.change('project', 'baseColor', null));
}

// function* handleStartDate({ payload }) {
//     const { value } = payload;

//     yield put({
//         type: actionTypes.UPDATE_META_VALUE,
//         payload: {
//             field: 'startDate',
//             value: moment(value).format(),
//         },
//     });
// }

const derivedHandlers = {
    style: handleStyle,
    imageMeta: handleImageMeta,
    // startDate: handleStartDate,
};

export default scopedMetaUpdateHandler(
    'back-to-school-snapshot',
    derivedHandlers
);
