import React from 'react';
import { Helmet } from 'react-helmet';
import Text from 'stillnovel/components/UI/Text';
import AuthTrigger from 'stillnovel/components/App/Auth/Trigger';
import Button from 'stillnovel/components/UI/Button';
import { useSelector } from 'react-redux';

import styles from './NotFound.module.scss';

export default function NotFound() {
    const userRole = useSelector(state => state.auth?.user?.role);

    return (
        <div className={styles.root}>
            <Helmet>
                <title>Project missing or you don&rsquo;t have access</title>
            </Helmet>
            <div className={styles.inner}>
                <Text tag="h1" theme="jumbo">
                    Oops!
                </Text>
                <Text tag="h3" theme="title">
                    Project missing or you don&rsquo;t have access.
                </Text>
                <section className={styles.contentProject}>
                    {userRole !== 'guest' && (
                        <>
                            <Text tag="p" theme="body1-alt">
                                Please confirm that you have the correct link.
                                If you do, it&rsquo;s possible that you created
                                this project while signed in to another account
                                or the project doesn&rsquo;t exist.
                            </Text>
                            <Button className={styles.cta} block to="/">
                                Dashboard
                            </Button>
                        </>
                    )}
                    {userRole === 'guest' && (
                        <>
                            <Text tag="p" theme="body1-alt">
                                Please confirm that you have the correct link.
                                If you do, it&rsquo;s possible that you created
                                this project while signed in or on a different
                                device.
                            </Text>
                            <AuthTrigger>
                                <Button className={styles.cta} block>
                                    Login
                                </Button>
                            </AuthTrigger>
                        </>
                    )}
                </section>
            </div>
        </div>
    );
}
