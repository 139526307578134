import React from 'react';
import Field from 'redux-form/es/Field';
import { Col, Row } from 'stillnovel/components/UI/Grid';
import FieldSelect from 'stillnovel/components/UI/Input/FieldSelect';
import Text from 'stillnovel/components/UI/Text';

const breedGroup = [
    ['Abyssinian', 'ABYSSINIAN'],
    ['American Bobtail', 'AMERICAN_BOBTAIL'],
    ['American Curl', 'AMERICAN_CURL'],
    ['American Shorthair', 'AMERICAN_SHORTHAIR'],
    ['American Wirehair', 'AMERICAN_WIREHAIR'],
    ['Balinese Javanese', 'BALINESE_JAVANESE'],
    ['Bengal', 'BENGAL'],
    ['Birman', 'BIRMAN'],
    ['Bombay', 'BOMBAY'],
    ['British Shorthair', 'BRITISH_SHORTHAIR'],
    ['Burmese', 'BURMESE'],
    ['Chartreux', 'CHARTREUX'],
    ['Cornish Rex', 'CORNISH_REX'],
    ['Devon Rex', 'DEVON_REX'],
    ['Egyptian Mau', 'EGYPTIAN_MAU'],
    ['European Burmese', 'EUROPEAN_BURMESE'],
    ['Exotic Shorthair', 'EXOTIC_SHORTHAIR'],
    ['Havana Brown', 'HAVANA_BROWN'],
    ['Himalayan', 'HIMALAYAN'],
    ['Japanese Bobtail', 'JAPANESE_BOBTAIL'],
    ['Korat', 'KORAT'],
    ['Laperm', 'LAPERM'],
    ['Maine Coon', 'MAINE_COON'],
    ['Manx', 'MANX'],
    ['Munchkin', 'MUNCHKIN'],
    ['Norwegian Forest Cat', 'NORWEGIAN_FOREST_CAT'],
    ['Ocicat', 'OCICAT'],
    ['Oriental', 'ORIENTAL'],
    ['Persian', 'PERSIAN'],
    ['Peterbald', 'PETERBALD'],
    ['Pixie Bob', 'PIXIE_BOB'],
    ['Ragamuffin', 'RAGAMUFFIN'],
    ['Ragdoll', 'RAGDOLL'],
    ['Russian Blue', 'RUSSIAN_BLUE'],
    ['Savannah', 'SAVANNAH'],
    ['Scottish Fold', 'SCOTTISH_FOLD'],
    ['Selkirk Rex', 'SELKIRK_REX'],
    ['Siamese', 'SIAMESE'],
    ['Siberian', 'SIBERIAN'],
    ['Singapura', 'SINGAPURA'],
    ['Somali', 'SOMALI'],
    ['Sphynx', 'SPHYNX'],
    ['Tonkinese', 'TONKINESE'],
    ['Toyger', 'TOYGER'],
    ['Turkish Angora', 'TURKISH_ANGORA'],
    ['Turkish Van', 'TURKISH_VAN'],
    ['Rescue', 'RESCUE'],
    ['Mixed Breed', 'MIXED_BREED'],
];

const Cat = () => {
    return (
        <Row>
            <Col>
                <Text tag="label" theme="form-title" htmlFor="catBreedGroup">
                    Breed
                </Text>
                <Field name="catBreedGroup" component={FieldSelect} stretch>
                    <option value="" disabled>
                        --
                    </option>
                    {breedGroup.map(([label, key]) => {
                        return (
                            <option key={key} value={key}>
                                {label}
                            </option>
                        );
                    })}
                </Field>
            </Col>
        </Row>
    );
};

export default Cat;
