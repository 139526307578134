import React, { Fragment, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import ReactPlacesAutocomplete from 'react-places-autocomplete';
import Text from 'stillnovel/components/UI/Text';
import Input from 'stillnovel/components/UI/Input';
import useGeocodeByAddress from 'stillnovel/hooks/useGeocodeByAddress';
import { useKey } from 'react-use';

import LocationCard from './LocationCard';
import styles from './PlacesAutocomplete.scss';

const PlacesAutocomplete = ({
    input,
    meta: { touched, error },
    placeholder,
}) => {
    const inputRef = useRef(null);
    const geocodeByAddress = useGeocodeByAddress();
    const [search, setSearch] = useState('');

    const handleChange = value => {
        setSearch(value);
    };

    const handleSelect = async value => {
        const address = await geocodeByAddress(value);
        if (address) {
            input.onChange(address);
        } else {
            // geocode failed, reset search
            setSearch('');
        }
    };

    const handleClear = e => {
        e.preventDefault();
        setSearch('');
        input.onChange('');
    };

    // Disable enter key to prevent location card from clearing
    useKey('Enter', e => {
        if (inputRef.current !== document.activeElement) {
            e.preventDefault();
        }
    });

    if (input.value) {
        return <LocationCard input={input} onClear={handleClear} />;
    }

    return (
        <div className={styles.root}>
            <ReactPlacesAutocomplete
                searchOptions={{
                    types: ['(cities)'],
                }}
                highlightFirstSuggestion={true}
                value={search}
                onChange={handleChange}
                onSelect={handleSelect}
            >
                {({ getInputProps, suggestions, getSuggestionItemProps }) => (
                    <Fragment>
                        <Input
                            ref={inputRef}
                            stretch
                            id={input.id}
                            name={input.name}
                            {...getInputProps({
                                placeholder: `${placeholder}`,
                                className: `${cx('location-search-input', {
                                    [styles['has-error']]: touched && error,
                                })}`,
                            })}
                            autoComplete="off"
                        />
                        <ul
                            className={
                                styles['autocomplete-dropdown-container']
                            }
                            role="list"
                        >
                            {suggestions.map(suggestion => {
                                const className = cx(
                                    styles['suggestion-item'],
                                    {
                                        [styles['suggestion-item--active']]:
                                            suggestion.active,
                                    }
                                );
                                return (
                                    <Text
                                        {...getSuggestionItemProps(suggestion, {
                                            className,
                                        })}
                                        key={suggestion.placeId}
                                        tag="li"
                                    >
                                        <span>{suggestion.description}</span>
                                    </Text>
                                );
                            })}
                        </ul>
                    </Fragment>
                )}
            </ReactPlacesAutocomplete>
        </div>
    );
};

PlacesAutocomplete.propTypes = {
    input: PropTypes.object.isRequired,
    meta: PropTypes.object.isRequired,
    placeholder: PropTypes.string,
};

PlacesAutocomplete.defaultProps = {
    placeholder: 'Search...',
};

export default PlacesAutocomplete;
