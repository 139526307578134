import {
    authenticated,
    confirmationRequired,
    login,
    loginFailure,
    unauthenticated,
    mfaRequired,
    newPasswordRequired,
    logout,
} from 'stillnovel/store/auth/actions';
import { InvalidRefreshTokenException } from 'core/services/exceptions';
import 'aws-sdk/lib/credentials/cognito_identity_credentials';
import SN from 'stillnovel/services';
import d from 'debug';

const debug = d('Auth');

/**
 * establishes a session with the user pool, and logs into the federated identity
 * pool using a token from the session
 * @param {object} user - the CognitoUser object
 * @return {Promise<object>} an action to be dispatched
 */
const performLogin = async () => {
    try {
        const session = await SN.auth.getSession();

        if (!session) {
            return logout();
        }

        if (session.role === 'guest') {
            return unauthenticated(session.user);
        }

        return login(session.user);
    } catch (error) {
        console.warn(error);
        switch (true) {
            case error instanceof InvalidRefreshTokenException:
                debug('InvalidRefreshTokenException');
                return loginFailure(error.user, 'Invalid Session');
            // default:
            //     return loginFailure(error.user, 'Incorrect email/password');
            default:
                throw error;
        }
    }
};

export const performLogout = () => {
    SN.auth.logout();
};

const authenticate = async (email, password) => {
    try {
        const user = await SN.auth.authenticate(email, password);
        return authenticated(user);
    } catch (error) {
        switch (error.code) {
            case 'UserNotConfirmedException':
                return confirmationRequired();
            case 'MFARequiredException':
                return mfaRequired(error.user);
            case 'NewPasswordRequiredException':
                return newPasswordRequired(error.user);
            default:
                return loginFailure(error.user, 'Incorrect email/password');
        }
    }
};

export { authenticate, performLogin };
