import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Field from 'redux-form/es/Field';
import reduxForm from 'redux-form/es/reduxForm';
import StepNav from 'stillnovel/components/App/Project/Builders/ArtPrint/StepNav';
import { Container, Col, Row } from 'stillnovel/components/UI/Grid';
import DateInput from 'stillnovel/components/UI/Input/DateInput';
import FieldInput from 'stillnovel/components/UI/Input/FieldInput';
import Form from 'stillnovel/components/UI/Form';
import FormHeader from 'stillnovel/components/App/Project/Builders/ArtPrint/FormHeader';
import NavArrows from 'stillnovel/components/UI/NavArrows';
import PlacesAutocomplete from 'stillnovel/components/UI/PlacesAutocomplete';
import Text from 'stillnovel/components/UI/Text';

import validate from '../validate';

let Details = ({
    handleSubmit,
    invalid,
    metadata,
    previousPage,
    project,
    submitting,
}) => {
    return (
        <Fragment>
            <Form>
                <FormHeader heading="Details" />
                <Container>
                    <Row>
                        <Col>
                            <Text
                                tag="label"
                                theme="form-title"
                                htmlFor="birthLocationData"
                            >
                                Birth City, State
                            </Text>
                            <Field
                                name="birthLocationData"
                                component={PlacesAutocomplete}
                                placeholder="Ex: “Los Angeles, CA”"
                                autoComplete={`${project.id}-birthPlace`}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Text
                                tag="label"
                                theme="form-title"
                                htmlFor="thought"
                            >
                                A Thought (optional)
                            </Text>
                            <Field
                                stretch
                                id="thought"
                                name="thought"
                                type="textarea"
                                component={FieldInput}
                                maxLength="124"
                                showErrorText
                                placeholder="A sentence or two about what you think makes this person special."
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Text
                                tag="label"
                                theme="form-title"
                                htmlFor="birthDate"
                            >
                                Birth Date
                            </Text>
                            <Field
                                name="birthDate"
                                type="select"
                                component={DateInput}
                                showTime={false}
                            />
                        </Col>
                        {metadata?.isMemorial && (
                            <Col>
                                <Text
                                    tag="label"
                                    theme="form-title"
                                    htmlFor="deceasedDate"
                                >
                                    Deceased Date
                                </Text>
                                <Field
                                    name="deceasedDate"
                                    type="select"
                                    component={DateInput}
                                    showTime={false}
                                />
                            </Col>
                        )}
                    </Row>
                    <Row alignment="bottom">
                        <Col>
                            <Text
                                tag="label"
                                theme="form-title"
                                htmlFor="nickname"
                            >
                                Nickname
                            </Text>
                            <Field
                                name="nickname"
                                type="text"
                                component={FieldInput}
                                placeholder="Ex: “Sweety”"
                                autoComplete={`${project.id}-nickname`}
                                autoCorrect="off"
                                maxLength={30}
                            />
                        </Col>
                    </Row>
                </Container>
            </Form>
            <StepNav>
                <NavArrows
                    buttons={[
                        { onClick: previousPage },
                        {
                            onClick: handleSubmit,
                            disabled: invalid || submitting,
                        },
                    ]}
                />
            </StepNav>
        </Fragment>
    );
};

Details.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    invalid: PropTypes.bool,
    metadata: PropTypes.shape({
        isMemorial: PropTypes.bool,
    }),
    previousPage: PropTypes.func.isRequired,
    project: PropTypes.object.isRequired,
    submitting: PropTypes.bool,
};

Details.defaultProps = {
    invalid: false,
    submitting: false,
};

Details = reduxForm({
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: false,
    touchOnBlur: false,
    touchOnChange: true,
    validate,
})(Details);

export default Details;
