import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Field from 'redux-form/es/Field';
import reduxForm from 'redux-form/es/reduxForm';
import Form from 'stillnovel/components/UI/Form';
import NavArrows from 'stillnovel/components/UI/NavArrows';
import Text from 'stillnovel/components/UI/Text';
import ImageInput from 'stillnovel/components/UI/Input/ImageInput';
import productsConfig from 'stillnovel/components/App/Project/config';

import ColorSelector from '../../shared/ColorSelector';
import StyleSelector from '../../shared/StyleSelector';
import FormHeader from '../../../FormHeader';
import StepNav from '../../../StepNav';
import validate from '../validate';

let Design = ({
    handleSubmit,
    invalid,
    metadata,
    previousPage,
    submitting,
}) => {
    const config = productsConfig.wedding;
    const style = metadata?.style === 'vintage' ? 'vintage' : 'modern';
    const styleConfig = config.styles.find(
        styleConfig => style == styleConfig.styleHandle
    );

    return (
        <Fragment>
            <Form>
                <FormHeader heading="Design Setup" />
                <StyleSelector />
                <Field
                    name="imageMeta"
                    component={ImageInput}
                    targetWidth={styleConfig.photoTargetWidth}
                    targetHeight={styleConfig.photoTargetHeight}
                />
                <Field
                    name="baseColor"
                    component={ColorSelector}
                    style={metadata.style || 'modern'}
                />
                <Text tag="p" theme="caption">
                    *We apply a softening layer over all photos to allow for the
                    design and text to subtly pop over your image.
                </Text>
            </Form>
            <StepNav>
                <NavArrows
                    buttons={[
                        { onClick: previousPage, disabled: true },
                        {
                            onClick: handleSubmit,
                            disabled: invalid || submitting,
                        },
                    ]}
                />
            </StepNav>
        </Fragment>
    );
};

Design.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    invalid: PropTypes.bool,
    metadata: PropTypes.shape({
        baseColor: PropTypes.string,
        style: PropTypes.string,
    }),
    previousPage: PropTypes.func.isRequired,
    project: PropTypes.object.isRequired,
    submitting: PropTypes.bool,
};

Design.defaultProps = {
    invalid: false,
    submitting: false,
};

Design = reduxForm({
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: false,
    touchOnBlur: false,
    touchOnChange: true,
    validate,
})(Design);

export default Design;
