import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Field from 'redux-form/es/Field';
import reduxForm from 'redux-form/es/reduxForm';
import { useSelector } from 'react-redux';
import { Container, Col, Row } from 'stillnovel/components/UI/Grid';
import FieldSelect from 'stillnovel/components/UI/Input/FieldSelect';
import FieldInput from 'stillnovel/components/UI/Input/FieldInput';
import FieldRadio from 'stillnovel/components/UI/Input/FieldRadio';
import Form from 'stillnovel/components/UI/Form';
import NavArrows from 'stillnovel/components/UI/NavArrows';
import Text from 'stillnovel/components/UI/Text';

import FormHeader from '../../../FormHeader';
import validate from '../validate';
import StepNav from '../../../StepNav';

let Stats = ({ handleSubmit, invalid, previousPage, submitting, project }) => {
    const withoutAstrology = useSelector(state => {
        return state?.form?.project?.values?.withoutAstrology || 'false';
    });

    return (
        <Fragment>
            <Form>
                <FormHeader heading="Details (cont.)" />
                <Container>
                    <Row alignment="bottom">
                        <Col>
                            <Text
                                tag="label"
                                theme="form-title"
                                htmlFor="withoutAstrology"
                            >
                                Astrology
                            </Text>
                        </Col>
                        <Col>
                            <Field
                                name="withoutAstrology"
                                component={FieldRadio}
                                label="Include"
                                type="boolean"
                                id={'false'}
                            />
                        </Col>
                        <Col>
                            <Field
                                name="withoutAstrology"
                                component={FieldRadio}
                                label="Exclude"
                                type="boolean"
                                id={'true'}
                            />
                        </Col>
                    </Row>
                    {(withoutAstrology === 'true' ||
                        withoutAstrology === true) && (
                        <>
                            <Row>
                                <Col>
                                    <Text
                                        tag="label"
                                        theme="form-title"
                                        htmlFor="nickname"
                                    >
                                        Nickname
                                    </Text>
                                    <Field
                                        id="nickname"
                                        name="nickname"
                                        type="text"
                                        component={FieldInput}
                                        placeholder="Jojo"
                                        autoComplete={`${project.id}-nickname`}
                                        autoCorrect="off"
                                        maxLength={30}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Text
                                        tag="label"
                                        theme="form-title"
                                        htmlFor="gender"
                                    >
                                        Gender
                                    </Text>
                                    <Field
                                        name="gender"
                                        component={FieldSelect}
                                        stretch
                                    >
                                        <option value="" disabled>
                                            --
                                        </option>
                                        <option value="Male">Male</option>
                                        <option value="Female">Female</option>
                                        <option value="Omit">Omit</option>
                                    </Field>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Text
                                        tag="label"
                                        theme="form-title"
                                        htmlFor="childWord"
                                    >
                                        In a word, you are
                                    </Text>
                                    <Field
                                        id="childWord"
                                        name="childWord"
                                        type="text"
                                        component={FieldInput}
                                        placeholder={`Ex: “Charming” or ”Joyful”`}
                                        autoComplete={`${project.id}-childWord`}
                                        autoCorrect="off"
                                    />
                                </Col>
                            </Row>
                        </>
                    )}
                </Container>
            </Form>
            <StepNav>
                <NavArrows
                    buttons={[
                        { onClick: previousPage },
                        {
                            onClick: handleSubmit,
                            disabled: invalid || submitting,
                            label: 'Review',
                        },
                    ]}
                />
            </StepNav>
        </Fragment>
    );
};

Stats.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    invalid: PropTypes.bool,
    previousPage: PropTypes.func.isRequired,
    submitting: PropTypes.bool,
    project: PropTypes.object.isRequired,
};

Stats.defaultProps = {
    invalid: false,
    submitting: false,
};

Stats = reduxForm({
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: false,
    touchOnBlur: false,
    touchOnChange: true,
    validate,
})(Stats);

export default Stats;
