import PropTypes from 'prop-types';
import React, { Fragment, useState } from 'react';
import Footer from 'stillnovel/components/App/Footer';
import { Subnav } from 'stillnovel/components/App/Account/Subnav';

const MainLayout = ({ children }) => {
    const [footerVisible, setFooterVisibility] = useState(false);

    return (
        <Fragment>
            {children}
            <Subnav forceShow={footerVisible} />
            <Footer footerInView={setFooterVisibility} />
        </Fragment>
    );
};

MainLayout.propTypes = {
    children: PropTypes.node.isRequired,
};

export default MainLayout;
