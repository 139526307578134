import { call, put, takeEvery, debounce, select } from 'redux-saga/effects';
import SN from 'stillnovel/services';
import d from 'debug';
import { event } from 'react-ga';
import { sanitizeString } from 'stillnovel/utils';

import { actionTypes, saveProjectRequest } from './actions';

export const getProject = state => state.project;
export const getProjectFormValues = state => state.form.project;

export const getAuth = state => state.auth;

const debug = d('App:Project:');

function* handleInput(action) {
    const { project, isNew } = yield select(getProject);

    yield put({
        type: actionTypes.HANDLE_INPUT_REQUESTED,
    });

    const rootProperties = ['title', 'shopifyProductId'];
    const actionType = rootProperties.includes(action.meta.field)
        ? actionTypes.UPDATE_VALUE
        : actionTypes.UPDATE_META_VALUE;

    // Emojis and other non-supported characters — be gone!
    const potentiallyDirtyTextProperties = [
        'aboutAlways',
        'aboutSleep',
        'arrivedHomeNumber',
        'arrivedHomePeriod',
        'ateStatement',
        'ceremonyVenue',
        'choseReason',
        'description',
        'designNote',
        'drankStatement',
        'engagedLocationName',
        'experienceWord',
        'favoriteFood',
        'favoritePastime',
        'favoritePlace',
        'favoriteToy',
        'favoriteTreat',
        'firstName',
        'lastName',
        'lovedStatement',
        'metLocationName',
        'metLocationPreposition',
        'metPlaceName',
        'metPlacePreposition',
        'metPronoun',
        'metYear',
        'middleName',
        'motto',
        'neverForget1',
        'neverForget2',
        'officiatedBy',
        'party1FirstName',
        'party1Gender',
        'party1Gender',
        'party1Nickname',
        'party1Word',
        'party2FirstName',
        'party2Gender',
        'party2Nickname',
        'party2Word',
        'personality',
        'receptionPlace',
        'songArtist',
        'songLyric',
        'songTitle',
        'startedWith1',
        'startedWith2',
        'stayedStatement',
        'temperament1',
        'temperament2',
        'theProposal',
        'thought',
        'togetherWeAre',
        'tripAdjective',
        'weCelebratedPreposition',
        'weCelebratedWord',
        'wellNeverForget1',
        'wellNeverForget2',
        'whereWeMet',
        'woreStatement',
    ];

    const payload = potentiallyDirtyTextProperties.includes(action.meta.field)
        ? sanitizeString(action.payload)
        : action.payload;

    yield put({
        type: actionType,
        payload: {
            builderHandle: project.builderHandle,
            id: action.meta.form,
            field: action.meta.field,
            value: payload,
        },
    });

    if (isNew) {
        yield put(saveProjectRequest({ id: 'project' }));
    } else {
        yield put(saveProjectRequest({ id: project.id }));
    }
}

function* fetchProject(action) {
    const { id } = action.payload;

    try {
        const project = yield call(() => SN.project.getById(id));
        yield put({
            type: actionTypes.FETCH_SUCCEEDED,
            payload: { project },
        });
    } catch (e) {
        yield put({
            type: actionTypes.FETCH_FAILED,
            payload: { message: e.message },
        });
    }
}

export function* saveProject(opt) {
    const { project } = yield select(getProject);

    const auth = yield select(getAuth);

    try {
        const savedProject = yield call(() =>
            SN.project.save({
                ...project,
            })
        );

        yield put({
            type: actionTypes.SAVE_SUCCEEDED,
            payload: { project: savedProject, isAnonymous: !auth.user },
        });
    } catch (e) {
        debug(e);
        // Google Analytics
        // Track when a save fails
        event({
            category: 'Project',
            action: 'Save Failed',
            label: e.message,
            nonInteraction: true,
        });
        yield put({
            type: actionTypes.SAVE_FAILED,
            payload: { error: e },
        });
        if (opt?.saveOrFail) {
            throw e;
        }
    }
}

function* createProject() {
    const { project } = yield select(getProject);
    const { values } = yield select(getProjectFormValues); // make sure we grab all default values for initial save

    try {
        // Set isNew false
        yield put({
            type: actionTypes.CREATE_PROJECT_REQUESTED,
        });
        const savedProject = yield call(() => {
            return SN.project.create({
                ...project,
                metadata: { ...values, ...project.metadata },
            });
        });
        yield put({
            type: actionTypes.SAVE_SUCCEEDED,
            payload: { project: savedProject },
        });
    } catch (e) {
        debug(e);
        // Set isNew false
        yield put({
            type: actionTypes.CREATE_PROJECT_REQUESTED_FAILED,
        });
        yield put({
            type: actionTypes.SAVE_FAILED,
            payload: { message: e.message },
        });
    }
}

function* debounceSaveProject() {
    const { isNew } = yield select(getProject);

    // Fork
    if (isNew) {
        debug('Create...');
        yield call(createProject);
        debug('Complete');
    } else {
        debug('Saving...');
        yield call(saveProject);
        debug('Complete');
    }
}

export default [
    takeEvery(
        action =>
            action.type === '@@redux-form/CHANGE' &&
            action.meta.form === 'project',
        handleInput
    ),
    debounce(400, actionTypes.SAVE_REQUESTED, debounceSaveProject),
    takeEvery(actionTypes.FETCH_REQUESTED, fetchProject),
];
