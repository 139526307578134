import React from 'react';
import PropTypes from 'prop-types';

const Section = ({ ...props }) => <section {...props} />;

Section.propTypes = {
    children: PropTypes.node.isRequired,
};

export default Section;
