import Client from 'shopify-buy/index.unoptimized.umd';
import getConfig from 'core/config';
import { useQuery, gql } from '@apollo/client';

const FETCH_PRODUCT_BY_HANDLE = gql`
    query fetchProductByHandle($handle: String!) {
        productByHandle(handle: $handle) {
            id
            builderHandle: metafield(
                namespace: "custom"
                key: "builder_handle"
            ) {
                value
            }
            variants(first: 20) {
                edges {
                    node {
                        id
                        title
                        price {
                            amount
                        }
                        availableForSale
                        sku
                        discontinued: metafield(
                            namespace: "custom"
                            key: "discontinued"
                        ) {
                            id
                            value
                        }
                        framePreviewCSS: metafield(
                            namespace: "custom"
                            key: "frame_preview_css"
                        ) {
                            value
                        }
                        framePreview: metafield(
                            namespace: "custom"
                            key: "frame_preview"
                        ) {
                            reference {
                                ... on MediaImage {
                                    image {
                                        altText
                                        width
                                        height
                                        url
                                    }
                                }
                            }
                        }
                        frameDetail: metafield(
                            namespace: "custom"
                            key: "frame_detail"
                        ) {
                            id
                            value
                            reference {
                                ... on Metaobject {
                                    fields {
                                        key
                                        value
                                        type
                                        reference {
                                            ... on MediaImage {
                                                image {
                                                    altText
                                                    width
                                                    height
                                                    url(
                                                        transform: {
                                                            maxWidth: 1600
                                                            preferredContentType: WEBP
                                                        }
                                                    )
                                                }
                                            }
                                        }
                                        references(first: 10) {
                                            nodes {
                                                ... on MediaImage {
                                                    image {
                                                        altText
                                                        width
                                                        height
                                                        url(
                                                            transform: {
                                                                maxWidth: 1200
                                                                preferredContentType: WEBP
                                                            }
                                                        )
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        linkedProductionProductId: metafield(
                            namespace: "custom"
                            key: "linked_production_product_id"
                        ) {
                            id
                            value
                        }
                    }
                }
            }
        }
    }
`;

const client = Client.buildClient({
    storefrontAccessToken: getConfig('shopifyStorefrontAccessToken'),
    domain: getConfig('shopifyStorefrontDomain'),
});

export const useShopify = () => {
    return client;
};

export const useFetchByHandle = handle => {
    const { loading, error, data } = useQuery(FETCH_PRODUCT_BY_HANDLE, {
        skip: !handle,
        variables: { handle },
    });

    if (data && !loading && data.productByHandle === null) {
        return { error: `No shopify product handle found for /${handle}` };
    }

    if (data && !loading && !error) {
        return {
            ...data?.productByHandle,
            variants: data?.productByHandle?.variants?.edges?.map(
                ({ node }) => node
            ),
        };
    }

    if (data && !loading && error) {
        return { error };
    }

    return false;
};
