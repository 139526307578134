// This is a helper to make namespacing easy and make sure we dont allow mutation

function namespacedImmutableObject(baseObj, namespace, actions) {
    baseObj[namespace] = {};
    baseObj.__defineGetter__(namespace, () => ({}));
    baseObj.__defineSetter__(namespace, () => {
        throw new Error(`Namespace ${namespace} cannot be declared twice.`);
    });

    return actions.reduce((obj, action) => {
        obj.__defineGetter__(action, () => `${namespace}${action}`);
        obj.__defineSetter__(action, value => {
            throw new Error(`${action} cannot be mutated to ${value}.`);
        });
        return obj;
    }, baseObj[namespace]);
}

const actionTypes = {};
const appStates = {};

export function registerActionTypes(namespace, actions) {
    return namespacedImmutableObject(actionTypes, `${namespace}/`, actions);
}

export function registerStates(namespace, actions) {
    return namespacedImmutableObject(appStates, `${namespace}/`, actions);
}

export const SOCIALS = [
    { url: 'https://www.instagram.com/stillnovel/', slug: 'instagram' },
    { url: 'https://www.facebook.com/wearestillnovel', slug: 'facebook' },
    { url: 'https://www.pinterest.com/stillnovelinc/', slug: 'pinterest' },
    { url: 'https://www.linkedin.com/company/still-novel/', slug: 'linkedin' },
    { url: 'https://vimeo.com/stillnovel', slug: 'vimeo' },
];

export const ADMIN_CONFIG = {
    tz: 'America/Los_Angeles',
};

export const allowedCharacters =
    /[-A-Za-z0-9 ~!@#$%^&*()_+={n}[\]:;"'<,>.?|/`®“‘’”èéêëēėęÿûüùúūîïíīįìİôöòóœøōõàáâäæãåāßśšłžźżçćčñń°–—•€£¥₩₽§""„»«…¿¡‰]/;
