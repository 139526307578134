import { registerActionTypes, registerStates } from 'stillnovel/constants';

const actions = [
    'ADD_ITEM_REQUESTED',
    'ADD_ITEM_ERROR',
    'ADD_ITEM_SUCCESS',
    'REMOVE_ITEM',
    'REMOVE_ITEM_REQUESTED',
    'REMOVE_ITEM_ERROR',
    'REMOVE_ITEM_SUCCESS',
    'SET_ITEMS',
    'SET_STALE_PROJECT',
    'SET_LOAD_STATE',
    'UPDATE_ITEM_QTY',
    'UPDATE_ITEM_QTY_REQUESTED',
    'UPDATE_ITEM_QTY_ERROR',
    'UPDATE_ITEM_QTY_SUCCESS',
    'ADD_PROJECT_TO_CART',
    'FETCH_REQUESTED',
    'FETCH_SUCCEEDED',
    'FETCH_FAILED',
    'RESET',
    'CLEAR_CHECKOUT',
    'UPDATE_PRODUCTS',
];

export const actionTypes = registerActionTypes('CART', actions);

export const states = registerStates('CART', [
    'UNINITIALIZED',
    'LOADING',
    'LOADED',
    'ERROR',
]);

export const addProjectToCart = callback => ({
    type: actionTypes.ADD_PROJECT_TO_CART,
    payload: { meta: { callback } },
});

export const getCartItems = userId => {
    return {
        type: actionTypes.FETCH_REQUESTED,
        payload: { userId },
    };
};

export const removeCartItem = id => ({
    type: actionTypes.REMOVE_ITEM_REQUESTED,
    payload: { id },
});

export const clearCheckout = () => {
    return {
        type: actionTypes.CLEAR_CHECKOUT,
        payload: [],
    };
};

export const updateGiftCardCode = giftCardCode => ({
    type: actionTypes.UPDATE_GIFT_CARD_CODE,
    payload: { giftCardCode },
});

export const updateDiscountCode = discountCode => ({
    type: actionTypes.UPDATE_DISCOUNT_CODE,
    payload: { discountCode },
});

export const updateShippingOptionId = shippingOptionId => ({
    type: actionTypes.UPDATE_SHIPPING_OPTION,
    payload: { shippingOptionId },
});

export const updateShippingState = shippingState => ({
    type: actionTypes.UPDATE_SHIPPING_STATE,
    payload: { shippingState },
});

export const updateCartItemQty = (id, qty) => ({
    type: actionTypes.UPDATE_ITEM_QTY_REQUESTED,
    payload: { id, qty },
});

export const resetCart = () => ({
    type: actionTypes.RESET,
});

//TODO

export const createOrderRequest = order => ({
    type: actionTypes.CREATE_ORDER_REQUEST,
    payload: order,
});
