import { actionTypes, states } from './actions';

const { SET_NAV_STATE, SET_LOAD_STATE, SET_CART_STATE } = actionTypes;

const initialState = {
    navState: states.NAV_CLOSED,
    loadingState: states.LOADING,
    cartState: states.CART_CLOSED,
};

function reducer(state = initialState, action) {
    const cases = {
        [SET_LOAD_STATE]: loadingState => {
            return { ...state, loadingState };
        },
        [SET_NAV_STATE]: () => {
            return { ...state, navState: action.payload };
        },
        [SET_CART_STATE]: () => {
            return { ...state, cartState: action.payload };
        },
        default: () => state,
    };

    return (cases[action.type] || cases.default)(action.payload);
}

export default reducer;
