import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Field from 'redux-form/es/Field';
import reduxForm from 'redux-form/es/reduxForm';
import StepNav from 'stillnovel/components/App/Project/Builders/ArtPrint/StepNav';
import { Container, Col, Row } from 'stillnovel/components/UI/Grid';
import DateInput from 'stillnovel/components/UI/Input/DateInput';
import FieldInput from 'stillnovel/components/UI/Input/FieldInput';
import Form from 'stillnovel/components/UI/Form';
import FieldSelect from 'stillnovel/components/UI/Input/FieldSelect';
import FormHeader from 'stillnovel/components/App/Project/Builders/ArtPrint/FormHeader';
import NavArrows from 'stillnovel/components/UI/NavArrows';
import Text from 'stillnovel/components/UI/Text';
import PlacesAutocomplete from 'stillnovel/components/UI/PlacesAutocomplete';

import validate from '../validate';

let Details = ({
    handleSubmit,
    invalid,
    previousPage,
    submitting,
    project,
}) => {
    return (
        <Fragment>
            <Form>
                <FormHeader heading="Details" />
                <Container>
                    <Row>
                        <Col>
                            <Text tag="label" theme="form-title">
                                Start Date
                            </Text>
                            <Field
                                name="startDate"
                                type="select"
                                showErrorText
                                component={DateInput}
                                showTime={false}
                            />
                        </Col>
                        <Col>
                            <Text tag="label" theme="form-title">
                                End Date
                            </Text>
                            <Field
                                name="endDate"
                                type="select"
                                showErrorText
                                component={DateInput}
                                showTime={false}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Text
                                tag="span"
                                theme="form-title"
                                htmlFor="comfortStatement"
                            >
                                It was a/an{' '}
                                <Field
                                    inline
                                    stretch
                                    name="tripAdjective"
                                    component={FieldInput}
                                    placeholder="Ex: “Memorable”"
                                    maxLength={13}
                                    autoComplete={`${project.id}-tripAdjective`}
                                />{' '}
                                trip
                            </Text>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Text
                                tag="label"
                                theme="form-title"
                                htmlFor="numberOfTravelers"
                            >
                                Number of Travelers
                            </Text>
                            <Field
                                id="numberOfTravelers"
                                name="numberOfTravelers"
                                component={FieldSelect}
                                stretch
                            >
                                <option value="" disabled>
                                    --
                                </option>
                                {Array(30)
                                    .fill()
                                    .map((_, i) => {
                                        return (
                                            <option key={i} value={`${i + 1}`}>
                                                {i + 1}
                                            </option>
                                        );
                                    })}
                            </Field>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Text
                                tag="label"
                                theme="form-title"
                                htmlFor="tripLocationData"
                            >
                                Where did you go?
                            </Text>
                            <Field
                                name="tripLocationData"
                                component={PlacesAutocomplete}
                                placeholder="If multiple locations, list first city visited"
                                autoComplete={`${project.id}-tripLocation`}
                            />
                        </Col>
                        <Col>
                            <Text
                                tag="label"
                                theme="form-title"
                                htmlFor="knownFor"
                            >
                                Destination known for
                            </Text>
                            <Field
                                stretch
                                id="knownFor"
                                name="knownFor"
                                type="text"
                                component={FieldInput}
                                maxLength="25"
                                showErrorText
                                placeholder="Ex: “The food”"
                                autoComplete={`${project.id}-knownfor`}
                            />
                        </Col>
                        <Col>
                            <Text
                                tag="label"
                                theme="form-title"
                                htmlFor="inOneWord"
                            >
                                In a word
                            </Text>
                            <Field
                                stretch
                                id="inOneWord"
                                name="inOneWord"
                                type="text"
                                component={FieldInput}
                                maxLength="25"
                                showErrorText
                                placeholder="Ex: “Relaxing”"
                                autoComplete={`${project.id}-inoneword`}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Text
                                tag="label"
                                theme="form-title"
                                htmlFor="thought"
                            >
                                A Thought (optional)
                            </Text>
                            <Field
                                stretch
                                id="thought"
                                name="thought"
                                type="textarea"
                                component={FieldInput}
                                maxLength="124"
                                showErrorText
                                placeholder="A sentence or two about what you think makes this trip special."
                            />
                        </Col>
                    </Row>
                </Container>
            </Form>
            <StepNav>
                <NavArrows
                    buttons={[
                        { onClick: previousPage },
                        {
                            onClick: handleSubmit,
                            disabled: invalid || submitting,
                        },
                    ]}
                />
            </StepNav>
        </Fragment>
    );
};

Details.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    invalid: PropTypes.bool,
    previousPage: PropTypes.func.isRequired,
    project: PropTypes.object.isRequired,
    submitting: PropTypes.bool,
};

Details = reduxForm({
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: false,
    touchOnBlur: false,
    touchOnChange: true,
    validate,
})(Details);

export default Details;
