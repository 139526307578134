import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { change } from 'redux-form';
import { useUpdateEffect, usePrevious } from 'react-use';

import Field from 'redux-form/es/Field';
import reduxForm from 'redux-form/es/reduxForm';
import StepNav from 'stillnovel/components/App/Project/Builders/ArtPrint/StepNav';
import { Container, Col, Row } from 'stillnovel/components/UI/Grid';

import FieldInput from 'stillnovel/components/UI/Input/FieldInput';
import Form from 'stillnovel/components/UI/Form';
import FieldSelect from 'stillnovel/components/UI/Input/FieldSelect';
import FormHeader from 'stillnovel/components/App/Project/Builders/ArtPrint/FormHeader';
import NavArrows from 'stillnovel/components/UI/NavArrows';
import Text from 'stillnovel/components/UI/Text';
import { capitalize } from 'lodash';

import validate from '../validate';

const gradeOptions = [
    '1st year Preschool',
    '2nd year Preschool',
    'Pre-K',
    'TK',
    'Kindergarten',
    'First',
    'Second',
    'Third',
    'Fourth',
    'Fifth',
    'Sixth',
    'Seventh',
    'Eighth',
    'Freshman',
    'Sophomore',
    'Junior',
    'Senior',
];

const ageOfChildOptions = [...Array(30).keys()].map(i => (i + 1).toString());
const teacherNameOrFavoriteTeacherNameOptions = [
    'Teacher Name',
    'Favorite Teacher Name',
];

let Details = ({
    handleSubmit,
    invalid,
    previousPage,
    submitting,
    metadata,
}) => {
    const dispatch = useDispatch();

    const initialGrade = metadata?.grade || null;
    const [whichGrade, setWhichGrade] = useState(initialGrade);

    useEffect(() => {
        if (metadata.grade) {
            setWhichGrade(metadata.grade);
        }
    }, [metadata.grade]);

    const [startDateFinal, setStartDateFinal] = useState(null);

    useEffect(() => {
        if (startDateFinal) {
            dispatch(change('project', 'startDate', startDateFinal));
        }
    }, [startDateFinal, dispatch]);

    useUpdateEffect(() => {
        // Clear value when subject changes
        if (metadata.favoritesList) {
            dispatch(change('project', 'favoritesListText', ''));
        }
    }, [metadata.favoritesList, dispatch]);

    const higherGradesArray = [
        'Sixth',
        'Seventh',
        'Eighth',
        'Freshman',
        'Sophomore',
        'Junior',
        'Senior',
    ];

    const whenIGrowUpOrGoalsLabel = higherGradesArray.includes(whichGrade)
        ? 'My goal this year is'
        : 'When I grow up, I want to be';

    const prevLabel = usePrevious(whenIGrowUpOrGoalsLabel);

    useEffect(() => {
        // Only clear the field if the label has changed
        if (prevLabel !== whenIGrowUpOrGoalsLabel && prevLabel !== undefined) {
            dispatch(change('project', 'whenIgrowUpOrGoalsText', ''));
        }
    }, [whenIGrowUpOrGoalsLabel, prevLabel, dispatch]);

    const handleGradeSelect = e => {
        if (e && e.target.value) {
            setWhichGrade(e.target.value);
        }
    };

    const handleStartDateMonthSelect = e => {
        if (e && e.target.value) {
            const year = startDateFinal ? startDateFinal.split('-')[0] : '2024';

            const newDate = `${year}-${e.target.value}-01`;

            setStartDateFinal(moment(newDate).format());
        }
    };

    const handleStartDateYearSelect = e => {
        if (e && e.target.value) {
            const month = startDateFinal ? startDateFinal.split('-')[1] : '01';

            const newDate = `${e.target.value}-${month}-01`;

            setStartDateFinal(moment(newDate).format());
        }
    };

    const startDateMonthOptions = [
        '01',
        '02',
        '03',
        '04',
        '05',
        '06',
        '07',
        '08',
        '09',
        '10',
        '11',
        '12',
    ];

    const generateStartDateYearOptions = () => {
        const currentYear = new Date().getFullYear();
        const years = [];

        for (let i = 0; i < 100; i++) {
            years.push((currentYear - i).toString());
        }

        return years;
    };

    const startDateYearOptions = generateStartDateYearOptions();

    const whenIGrowUpOrGoalsPlaceholder = higherGradesArray.includes(whichGrade)
        ? 'Ex: to learn a new language'
        : 'Ex: a soccer player or a police man';

    const favoritesListOptions = ['color', 'movie', 'singer', 'band'];

    const favoriteListTextPlaceholder = {
        color: 'Ex: Red',
        movie: 'Ex: Sandlot',
        singer: 'Ex: Ed Sheeran',
        band: 'Ex: Imagine Dragons',
    };

    return (
        <Fragment>
            <Form>
                <FormHeader heading="Details" />
                <Container>
                    <Row alignment="bottom">
                        <Col>
                            <Text tag="label" theme="form-title">
                                School year start date
                            </Text>
                            <div />
                            <Field
                                id="startDateYear"
                                name="startDateYear"
                                component={FieldSelect}
                                stretch
                                onChange={handleStartDateYearSelect}
                            >
                                <option value="" disabled>
                                    YYYY
                                </option>
                                {startDateYearOptions.map((option, i) => (
                                    <option key={i} value={option}>
                                        {option}
                                    </option>
                                ))}
                            </Field>
                        </Col>
                        <Col colSpan={2}>
                            <Field
                                id="startDateMonth"
                                name="startDateMonth"
                                component={FieldSelect}
                                stretch
                                onChange={handleStartDateMonthSelect}
                            >
                                <option value="" disabled>
                                    MM
                                </option>
                                {startDateMonthOptions.map((option, i) => (
                                    <option key={i} value={option}>
                                        {option}
                                    </option>
                                ))}
                            </Field>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Text
                                tag="label"
                                theme="form-title"
                                htmlFor="grade"
                            >
                                What Grade?
                            </Text>
                            <Field
                                id="grade"
                                name="grade"
                                component={FieldSelect}
                                stretch
                                value={whichGrade}
                                onChange={handleGradeSelect}
                            >
                                <option value="" disabled>
                                    --
                                </option>
                                {gradeOptions.map((option, i) => (
                                    <option key={i} value={option}>
                                        {option}
                                    </option>
                                ))}
                            </Field>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Text tag="label" theme="form-title" htmlFor="age">
                                Age Of Student
                            </Text>
                            <Field
                                id="ageOfChild"
                                name="ageOfChild"
                                component={FieldSelect}
                                stretch
                            >
                                <option value="" disabled>
                                    --
                                </option>
                                {ageOfChildOptions.map((option, i) => (
                                    <option key={i} value={option}>
                                        {option}
                                    </option>
                                ))}
                            </Field>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Text
                                tag="label"
                                theme="form-title"
                                htmlFor="schoolName"
                            >
                                School Name
                            </Text>
                            <Field
                                stretch
                                id="schoolName"
                                name="schoolName"
                                type="text"
                                component={FieldInput}
                                maxLength="124"
                                showErrorText
                                placeholder="Ex: Moffett Elementary"
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Text
                                tag="label"
                                theme="form-title"
                                htmlFor="teacherNameOrFavoriteTeacherName"
                            >
                                Choose One
                            </Text>
                            <Field
                                id="teacherNameOrFavoriteTeacherName"
                                name="teacherNameOrFavoriteTeacherName"
                                component={FieldSelect}
                                stretch
                            >
                                <option value="" disabled>
                                    ----
                                </option>
                                {teacherNameOrFavoriteTeacherNameOptions.map(
                                    (option, i) => (
                                        <option key={i} value={option}>
                                            {option}
                                        </option>
                                    )
                                )}
                            </Field>
                            {metadata.teacherNameOrFavoriteTeacherName && (
                                <Field
                                    stretch
                                    id="teacherName"
                                    name="teacherName"
                                    type="text"
                                    component={FieldInput}
                                    maxLength="124"
                                    showErrorText
                                    placeholder="Ex: Mrs. Kent"
                                    style={{ marginTop: '10px' }}
                                />
                            )}
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Text
                                tag="label"
                                theme="form-title"
                                htmlFor="youLove"
                            >
                                I Love
                            </Text>
                            <Field
                                stretch
                                id="youLove"
                                name="youLove"
                                type="text"
                                component={FieldInput}
                                maxLength="124"
                                showErrorText
                                placeholder="Ex: basketball or LEGO or the beach"
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Text
                                tag="label"
                                theme="form-title"
                                htmlFor="favoriteSubject"
                            >
                                Favorite Subject
                            </Text>
                            <Field
                                stretch
                                id="favoriteSubject"
                                name="favoriteSubject"
                                type="text"
                                component={FieldInput}
                                maxLength="124"
                                showErrorText
                                placeholder="Ex: Science"
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Text
                                tag="label"
                                theme="form-title"
                                htmlFor="favoriteFood"
                            >
                                Favorite Food
                            </Text>
                            <Field
                                stretch
                                id="favoriteFood"
                                name="favoriteFood"
                                type="text"
                                component={FieldInput}
                                maxLength="124"
                                showErrorText
                                placeholder="Ex: Noodles"
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Text
                                tag="label"
                                theme="form-title"
                                htmlFor="favoritesList"
                            >
                                Choose One
                            </Text>
                            <Field
                                id="favoritesList"
                                name="favoritesList"
                                component={FieldSelect}
                                stretch
                            >
                                <option value="" disabled>
                                    ----
                                </option>
                                {favoritesListOptions.map((option, i) => (
                                    <option key={i} value={option}>
                                        {`Favorite ${capitalize(option)}`}
                                    </option>
                                ))}
                            </Field>
                            {metadata.favoritesList && (
                                <Field
                                    stretch
                                    id="favoritesListText"
                                    name="favoritesListText"
                                    type="text"
                                    component={FieldInput}
                                    maxLength="124"
                                    showErrorText
                                    placeholder={
                                        favoriteListTextPlaceholder[
                                            metadata.favoritesList
                                        ]
                                    }
                                    style={{ marginTop: '10px' }}
                                />
                            )}
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Text
                                tag="label"
                                theme="form-title"
                                htmlFor="whenIgrowUpOrGoalsText"
                            >
                                {whenIGrowUpOrGoalsLabel}
                            </Text>
                            <Field
                                stretch
                                id="whenIgrowUpOrGoalsText"
                                name="whenIgrowUpOrGoalsText"
                                type="text"
                                component={FieldInput}
                                maxLength="124"
                                showErrorText
                                placeholder={whenIGrowUpOrGoalsPlaceholder}
                            />
                        </Col>
                    </Row>
                </Container>

                <Field
                    name="startDate"
                    type="hidden"
                    showErrorText
                    component={FieldInput}
                />
            </Form>
            <StepNav>
                <NavArrows
                    buttons={[
                        { onClick: previousPage },
                        {
                            onClick: handleSubmit,
                            disabled: invalid || submitting,
                        },
                    ]}
                />
            </StepNav>
        </Fragment>
    );
};

Details.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    invalid: PropTypes.bool,
    previousPage: PropTypes.func.isRequired,
    submitting: PropTypes.bool,
    metadata: PropTypes.object,
};

Details.defaultProps = {
    invalid: false,
    submitting: false,
};

Details = reduxForm({
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: false,
    touchOnBlur: false,
    touchOnChange: true,
    validate,
})(Details);

export default Details;
