import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import styles from './Row.scss';

const Row = ({ children, className, tag, disabled }) => {
    const TagName = tag;
    const classNames = cx(styles.root, className, {
        [styles.disabled]: disabled,
    });
    return <TagName className={classNames}>{children}</TagName>;
};

Row.propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
    tag: PropTypes.string,
    disabled: PropTypes.bool,
};

Row.defaultProps = {
    classname: null,
    tag: 'span',
    disabled: false,
};

export default Row;
