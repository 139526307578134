import {
    trimWhitespaceFromValues,
    validateThought,
} from 'stillnovel/utils/validation';

export default values => {
    // Trim leading and trailing whitespace from values object
    values = trimWhitespaceFromValues(values);

    const modernOnly = values.style === 'modern';

    const modernOnlyFields = modernOnly
        ? {
              songLyric: !values.songLyric && 'Required',
          }
        : {};

    const errors = {
        accept:
            !values.accept &&
            values.accept !== true &&
            'Please confirm your artwork is error-free & ready for print.',
        baseColor: !values.baseColor && 'Required',
        ceremonyLocationData: !values.ceremonyLocationData && 'Required',
        engagementDate: !values.engagementDate && 'Required',
        engagementTimeOfDay: !values.engagementTimeOfDay && 'Required',
        experienceWord: !values.experienceWord && 'Required',
        heading: !values.heading && 'Required',
        imageMeta: !values.imageMeta && 'Required',
        metLocationName: !values.metLocationName && 'Required',
        metLocationPreposition: !values.metLocationPreposition && 'Required',
        metYear: !values.metYear && 'Required',
        party1FirstName: !values.party1FirstName && 'Required',
        party1Gender: !values.party1Gender && 'Required',
        party1Word: !values.party1Word && 'Required',
        party2FirstName: !values.party2FirstName && 'Required',
        party2Gender: !values.party2Gender && 'Required',
        party2Word: !values.party2Word && 'Required',
        shopifyProductId: !values.shopifyProductId && 'Required',
        songArtist: !values.songArtist && 'Required',
        songTitle: !values.songTitle && 'Required',
        theProposal: !values.theProposal && 'Required',
        thoughtValidation: validateThought(values.thought),
        weCelebratedPreposition: !values.weCelebratedPreposition && 'Required',
        weCelebratedWord: !values.weCelebratedWord && 'Required',
        wellNeverForget1: !values.wellNeverForget1 && 'Required',
        wellNeverForget2: !values.wellNeverForget2 && 'Required',
        ...modernOnlyFields,
    };

    return errors;
};
