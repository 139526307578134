import PropTypes from 'prop-types';
import React from 'react';
import cx from 'classnames';
import Loader from 'stillnovel/components/UI/Loader';
import Section from 'stillnovel/components/UI/Section';
import Text from 'stillnovel/components/UI/Text';
import { useGraph } from 'stillnovel/hooks/useContentful';
import { BLOCKS, MARKS, INLINES } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import Link from 'stillnovel/components/UI/Link';

import styles from './Summary.scss';

const options = {
    renderMark: {
        [MARKS.BOLD]: text => (
            <Text tag="span" theme="body1-alt">
                {text}
            </Text>
        ),
    },
    renderNode: {
        [BLOCKS.PARAGRAPH]: (node, children) => <Text tag="p">{children}</Text>,
        [BLOCKS.HEADING_1]: (node, children) => (
            <Text tag="h1" theme="display">
                {children}
            </Text>
        ),
        [BLOCKS.HEADING_2]: (node, children) => (
            <Text tag="h2" theme="header">
                {children}
            </Text>
        ),
        [BLOCKS.HEADING_3]: (node, children) => (
            <Text tag="h3" theme="title">
                {children}
            </Text>
        ),
        [BLOCKS.HEADING_4]: (node, children) => (
            <Text tag="h4" theme="title-alt3">
                {children}
            </Text>
        ),
        [BLOCKS.HEADING_5]: (node, children) => (
            <Text tag="h5" theme="subhead">
                {children}
            </Text>
        ),
        [BLOCKS.HEADING_6]: (node, children) => (
            <Text tag="h6" theme="body1-alt">
                {children}
            </Text>
        ),
        [BLOCKS.UL_LIST]: (node, children) => (
            <ul className={styles.list}>{children}</ul>
        ),
        [BLOCKS.LIST_ITEM]: (node, children) => (
            <li className={styles.listItem}>{children}</li>
        ),
        [INLINES.HYPERLINK]: node => (
            <Link href={node.data.uri} className={styles.link}>
                {node.content[0].value}
            </Link>
        ),
    },
};

function Summary(props) {
    const { cta, isLoading, totalSub } = props;
    const preview = false;

    const cartCalloutContent = useGraph({
        query: `
            query {
                blockCartCalloutCollection(preview: ${preview}) {
                    items {
                        richText {
                            json
                        }
                    }
                }
            }
        `,
    });

    const richText =
        cartCalloutContent?.data?.blockCartCalloutCollection?.items[0]?.richText
            ?.json;

    return (
        <div className={styles.root}>
            <Section
                className={cx(styles['cart-row'], styles['cart-row-sub-total'])}
            >
                <div className={styles['cart-rows']}>
                    <ul>
                        <li
                            className={cx(
                                styles['cart-sub-row'],
                                styles['cart-sub-row-sub-total']
                            )}
                        >
                            <Text theme="body1-alt">Sub-total</Text>
                            <Text theme="body1-alt">{totalSub}</Text>
                        </li>
                        {richText && (
                            <Text
                                tag="li"
                                theme="body1"
                                className={styles['shipping-message']}
                                isWarning
                            >
                                {documentToReactComponents(richText, options)}
                            </Text>
                        )}
                    </ul>
                    {isLoading && (
                        <div className={styles.loader}>
                            <Loader align="center" text="Calculating..." />
                        </div>
                    )}
                </div>
            </Section>
            {cta && (
                <Section
                    className={cx(
                        styles['cart-row'],
                        styles['cart-row-checkout']
                    )}
                >
                    {cta()}
                </Section>
            )}
        </div>
    );
}

Summary.propTypes = {
    cta: PropTypes.func,
    isLoading: PropTypes.any,
    totalSub: PropTypes.any,
};

Summary.defaultProps = {};

export default Summary;
