import {
    trimWhitespaceFromValues,
    validateThought,
} from 'stillnovel/utils/validation';

export default values => {
    // Trim leading and trailing whitespace from values object
    values = trimWhitespaceFromValues(values);

    const errors = {
        accept:
            !values.accept &&
            values.accept !== true &&
            'Please confirm your artwork is error-free & ready for print.',
        baseColor: !values.baseColor && 'Required',
        beganDatingDate: !values.beganDatingDate && 'Required',
        ceremonyLocationData: !values.ceremonyLocationData && 'Required',
        favoriteFood: !values.favoriteFood && 'Required',
        favoritePastime: !values.favoritePastime && 'Required',
        heading: !values.heading && 'Required',
        favoritePlace: !values.favoritePlace && 'Required',
        imageMeta: !values.imageMeta && 'Required',
        party1FirstName: !values.party1FirstName && 'Required',
        party1Gender: !values.party1Gender && 'Required',
        party1Nickname: !values.party1Nickname && 'Required',
        party1Word: !values.party1Word && 'Required',
        party2FirstName: !values.party2FirstName && 'Required',
        party2Gender: !values.party2Gender && 'Required',
        party2Nickname: !values.party2Nickname && 'Required',
        party2Word: !values.party2Word && 'Required',
        shopifyProductId: !values.shopifyProductId && 'Required',
        startedWith1: !values.startedWith1 && 'Required',
        startedWith2: !values.startedWith2 && 'Required',
        thought: validateThought(values.thought),
        togetherWeAre: !values.togetherWeAre && 'Required',
        whereWeMet: !values.whereWeMet && 'Required',
    };

    return errors;
};
