import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import Field from 'redux-form/es/Field';
import FieldInput from 'stillnovel/components/UI/Input/FieldInput';
import reduxForm from 'redux-form/es/reduxForm';
import { Col, Row } from 'stillnovel/components/UI/Grid';
import Form from 'stillnovel/components/UI/Form';
import FormHeader from 'stillnovel/components/App/Project/Builders/ArtPrint/FormHeader';
import NavArrows from 'stillnovel/components/UI/NavArrows';
import Text from 'stillnovel/components/UI/Text';

import StepNav from '../../../StepNav';

import styles from './Review.scss';

let Review = ({
    handleSubmit,
    submitting,
    checkpoints = [
        'Spelling mistakes',
        'Grammar issues',
        'Capitalization errors',
        'Accuracy of data (i.e. incorrect dates, times and locations)',
    ],
    previousPage,
    metadata,
}) => {
    return (
        <Fragment>
            <Form>
                <FormHeader heading="Artwork Review" />
                <div className={styles.root}>
                    <Text tag="h2" theme="form-title">
                        Please check your print for...
                    </Text>
                    <ul className={styles['unordered-list']} role="list">
                        {checkpoints.map((point, i) => (
                            <Text key={i} tag="li" theme="body1">
                                {point}
                            </Text>
                        ))}
                    </ul>
                    <Field
                        name="accept"
                        id="accept"
                        component={({ id, input }) => {
                            return (
                                <Text
                                    tag="label"
                                    theme="body1-alt"
                                    className={cx(styles['accept-checkbox'], {
                                        [styles.approved]: input.value === true,
                                    })}
                                >
                                    <input
                                        {...input}
                                        checked={!!input.value}
                                        id={id}
                                        name={input.name}
                                        type="checkbox"
                                    />{' '}
                                    <span>
                                        I confirm my artwork is error-free &amp;
                                        ready for print
                                        <br />
                                        <br />
                                        <Text theme="caption">
                                            *Note that cancellations are allowed
                                            only within one hour of placing your
                                            order. After that, only a 50% refund
                                            will be issued.
                                        </Text>
                                    </span>
                                </Text>
                            );
                        }}
                    />
                    <Row>
                        <Col>
                            <FormHeader heading="Order Note" />
                            <Field
                                stretch
                                id="designNote"
                                name="designNote"
                                type="textarea"
                                component={FieldInput}
                                maxLength="255"
                                showErrorText
                                placeholder="Questions or concerns? Let us know here."
                            />
                        </Col>
                    </Row>
                </div>
            </Form>
            <StepNav>
                <NavArrows
                    buttons={[
                        { onClick: previousPage },
                        {
                            onClick: handleSubmit,
                            disabled: metadata.accept !== true || submitting,
                            label: 'Finishing Options',
                        },
                    ]}
                />
            </StepNav>
        </Fragment>
    );
};

Review.propTypes = {
    checkpoints: PropTypes.array,
    handleSubmit: PropTypes.func.isRequired,
    metadata: PropTypes.shape({
        accept: PropTypes.bool,
    }),
    previousPage: PropTypes.func.isRequired,
    submitting: PropTypes.bool,
};

Review.defaultProps = {
    submitting: false,
};

Review = reduxForm({
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: false,
    touchOnBlur: true,
})(Review);

export default Review;
