export default class UserService {
    api;

    constructor(apiService) {
        this.api = apiService;
    }

    async me() {
        const response = await this.api.get(`/me`, {
            withSession: true,
        });
        return response.data;
    }

    async update(user) {
        const response = await this.api.patch(`/me`, user, {
            withSession: true,
        });
        return response.data;
    }
}
