export default class OrderService {
    api;

    constructor(apiService) {
        this.api = apiService;
    }

    async getById(id) {
        const response = await this.api.get(`/orders/${id}`, {
            withUser: true,
            withSession: true,
        });
        return response.data;
    }

    async cancel(id) {
        const response = await this.api.get(`/orders/${id}/cancel`, {
            withUser: true,
            withSession: true,
        });
        return response.data;
    }

    async getAll() {
        const response = await this.api.get(`/orders`, {
            withUser: true,
            withSession: true,
        });
        return response.data;
    }
}
