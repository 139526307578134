import { useState, useEffect } from 'react';
import { createClient as createContentfulClient } from 'contentful';

const SPACE = '7pvpkbs2ibh3';
const ACCESS_TOKEN =
    '10b97f0311a7d8bff24f29670b05a5238a7bc05ee2c7060f79431f45fc792172';
const client = createContentfulClient({
    space: SPACE,
    accessToken: ACCESS_TOKEN,
});

export const useContentful = () => {
    return client;
};

export const useEntries = ogQuery => {
    const [query] = useState(ogQuery);
    const [results, setResults] = useState(null);

    useEffect(() => {
        const getEntries = async () => {
            const results = await client.getEntries(query);
            setResults(results);
        };
        getEntries();
    }, [query]);

    return results;
};

export const useGraph = ogQuery => {
    const [query, setQuery] = useState(ogQuery);
    const [results, setResults] = useState(null);

    useEffect(() => {
        if (ogQuery.query !== query.query) {
            setQuery(ogQuery);
        }
    }, [ogQuery, query.query]);

    useEffect(() => {
        const getEntries = () => {
            fetch(
                `https://graphql.contentful.com/content/v1/spaces/${SPACE}/environments/master?include=10`,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${ACCESS_TOKEN}`,
                    },
                    body: JSON.stringify(query),
                }
            )
                .then(res => res.json())
                .then(result => setResults(result))
                .catch(error => {
                    throw error;
                });
        };
        getEntries();
    }, [query]);

    return results;
};
