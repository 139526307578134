import SN, { Exceptions } from 'core/services';
import d from 'debug';

import store from './store';
// eslint-disable-next-line no-unused-vars
import { states, actionTypes, logout } from './store/auth/actions';

const debug = d('Services');

const services = SN({
    onError: error => {
        const state = store.getState();
        if (error instanceof Exceptions.InvalidRefreshTokenException) {
            store.dispatch(logout());
            return;
        }
        // TODO global error handler
        debug({ ...error }, state, 'GLOBAL ERROR');
    },
    auth: {
        onSessionExpire: () => {
            store.dispatch(logout());
        },
    },
});

async function handleVisibilityChange() {
    if (document.visibilityState != 'hidden') {
        try {
            await services.auth.getSession();
        } catch (error) {
            if (error instanceof Exceptions.InvalidRefreshTokenException) {
                store.dispatch(logout());
            }
        }
    }
}

document.addEventListener('visibilitychange', handleVisibilityChange, false);

export default services;
