import ReactPixel from 'react-facebook-pixel';
import d from 'debug';

const debug = d('FB Pixel');

export const initFBPixel = () => {
    ReactPixel.init('347447412648295');
};

export const logPixelPageView = () => {
    ReactPixel.pageView();
};

export const trackPixelEvent = (event = '', data = {}) => {
    // https://www.facebook.com/business/help/402791146561655
    if (!event) {
        debug('FB Pixel: Please specify an event to track.');
        return false;
    }
    ReactPixel.track(event, data);
};
