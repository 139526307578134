import { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

const Portal = ({ children, selector = '#__portal__' }) => {
    const [container, setContainer] = useState(null);

    useEffect(() => {
        setContainer(document.querySelector(selector));
    }, [selector]);

    if (container) {
        return ReactDOM.createPortal(children, container);
    }

    return null;
};

Portal.propTypes = {
    selector: PropTypes.string,
    children: PropTypes.node.isRequired,
};

export default Portal;
