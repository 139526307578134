import { trimWhitespaceFromValues } from 'stillnovel/utils/validation';

export default values => {
    // Trim leading and trailing whitespace from values object
    values = trimWhitespaceFromValues(values);

    const errors = {
        accept:
            !values.accept &&
            values.accept !== true &&
            'Please confirm your artwork is error-free & ready for print.',
        baseColor: !values.baseColor && 'Required',
        image: !values.image && 'Required',
        imageMeta: !values.imageMeta && 'Required',
        shopifyProductId: !values.shopifyProductId && 'Required',
    };
    return errors;
};
