import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Page from 'stillnovel/components/UI/Page';
import NotFound from 'stillnovel/components/App/NotFound/NotFound';

import Dashboard from './Dashboard';

const Account = () => {
    return (
        <Page>
            <Helmet>
                <title>Account</title>
            </Helmet>
            <Routes>
                <Route path="/" element={<Dashboard />} />
                <Route path="*" element={<NotFound />} />
            </Routes>
        </Page>
    );
};

export default Account;
