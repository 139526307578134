import {
    trimWhitespaceFromValues,
    validateThought,
} from 'stillnovel/utils/validation';

export default values => {
    // Trim leading and trailing whitespace from values object
    values = trimWhitespaceFromValues(values);

    const dogFields =
        values.pet === 'DOG'
            ? {
                  breed: !values.breed && 'Required',
                  dogBreedGroup: !values.dogBreedGroup && 'Required',
              }
            : {};

    const catFields =
        values.pet === 'CAT'
            ? {
                  catBreedGroup: !values.catBreedGroup && 'Required',
              }
            : {};

    const otherFields =
        values.pet === 'OTHER'
            ? {
                  breed: !values.breed && 'Required',
                  otherBreedGroup: !values.otherBreedGroup && 'Required',
              }
            : {};

    const errors = {
        accept:
            !values.accept &&
            values.accept !== true &&
            'Please confirm your artwork is error-free & ready for print.',
        // Background
        pet: !values.pet && 'Required',
        firstName: !values.firstName && 'Required',
        nickname: !values.nickname && 'Required',
        gender: !values.gender && 'Required',
        dateType: !values.dateType && 'Required',
        date: !values.date && 'Required',
        image: !values.image && 'Required',
        imageMeta: !values.imageMeta && 'Required',
        baseColor: !values.baseColor && 'Required',
        // Details
        thought: validateThought(values.thought),
        temperament1: !values.temperament1 && 'Required',
        temperament2: !values.temperament2 && 'Required',
        favoriteToy: !values.favoriteToy && 'Required',
        favoriteTreat: !values.favoriteTreat && 'Required',
        // Details2
        metLocationData: !values.metLocationData && 'Required',
        arrivedHomeNumber: !values.arrivedHomeNumber && 'Required',
        arrivedHomePeriod: !values.arrivedHomePeriod && 'Required',
        metPronoun: !values.metPronoun && 'Required',
        metPlacePreposition: !values.metPlacePreposition && 'Required',
        metPlaceName: !values.metPlaceName && 'Required',
        choseReason: !values.choseReason && 'Required',
        description: !values.description && 'Required',
        aboutSleep: !values.aboutSleep && 'Required',
        aboutAlways: !values.aboutAlways && 'Required',
        shopifyProductId: !values.shopifyProductId && 'Required',
        ...dogFields,
        ...catFields,
        ...otherFields,
    };

    return errors;
};
