import { actionTypes } from './actions';

const {
    INIT,
    CREATE,
    FETCH_REQUESTED,
    FETCH_SUCCEEDED,
    FETCH_FAILED,
    UPDATE_META_VALUE,
    UPDATE_VALUE,
    SAVE_REQUESTED,
    SAVE_SUCCEEDED,
    SAVE_FAILED,
    UPDATE_PRODUCT,
    RESET,
    CREATE_PROJECT_REQUESTED,
    CREATE_PROJECT_REQUESTED_FAILED,
    HANDLE_INPUT_REQUESTED,
} = actionTypes;

const initialState = {
    project: {
        metadata: {},
    },
    isLoading: false,
    isDirty: false,
    isNew: true,
    isAnonymous: true,
    isError: false,
};

function projectsReducer(state = initialState, action) {
    const cases = {
        [INIT]: payload => ({
            ...state,
            project: {
                ...state.project,
                ...payload,
            },
        }),
        [RESET]: () => ({
            ...initialState,
        }),
        [UPDATE_PRODUCT]: handle => {
            return {
                ...state,
                isDirty: true,
                project: {
                    ...state.project,
                    handle: handle,
                    product: {
                        code: handle,
                    },
                },
            };
        },
        [UPDATE_VALUE]: payload => {
            return {
                ...state,
                isDirty: true,
                project: {
                    ...state.project,
                    [payload.field]: payload.value,
                },
            };
        },
        [UPDATE_META_VALUE]: payload => {
            return {
                ...state,
                isDirty: true,
                project: {
                    ...state.project,
                    metadata: {
                        ...state.project.metadata,
                        [payload.field]: payload.value,
                    },
                },
            };
        },
        [FETCH_REQUESTED]: () => {
            return {
                ...state,
                isDirty: false,
                isLoading: true,
                isError: false,
                error: false,
            };
        },
        [FETCH_FAILED]: () => {
            return {
                isError: true,
                isLoading: false,
                error: false,
            };
        },
        [FETCH_SUCCEEDED]: payload => {
            return {
                ...state,
                isDirty: false,
                isNew: false,
                project: {
                    ...state.project,
                    ...payload.project,
                },
                isLoading: false,
            };
        },
        [SAVE_REQUESTED]: () => {
            return {
                ...state,
                isDirty: true,
                isLoading: true,
                isError: false,
                error: false,
            };
        },
        [SAVE_FAILED]: payload => {
            return {
                ...state,
                isLoading: false,
                isError: true,
                error: payload.error || 'unknown',
            };
        },
        [SAVE_SUCCEEDED]: payload => {
            return {
                ...state,
                project: state.isNew
                    ? {
                          ...payload.project,
                          id: payload.project.id,
                      }
                    : state.project,
                isDirty: false,
                isAnonymous: payload.isAnonymous,
                isNew: false,
                isLoading: false,
                isError: false,
            };
        },
        [CREATE_PROJECT_REQUESTED]: () => {
            return {
                ...state,
                isNew: true,
                isError: false,
            };
        },
        [CREATE_PROJECT_REQUESTED_FAILED]: () => {
            return {
                ...state,
                isNew: false,
                isError: true,
            };
        },
        [CREATE]: payload => {
            return {
                ...state,
                project: {
                    id: payload.id,
                    shopifyProductId: payload.shopifyProductId,
                    productHandle: payload.productHandle,
                    metadata: {},
                },
                isDirty: true,
                isNew: true,
                isLoading: false,
                isError: false,
            };
        },
        [HANDLE_INPUT_REQUESTED]: () => {
            if (state.isDirty) {
                return state;
            }
            return {
                ...state,
                isDirty: true,
                isError: false,
            };
        },
        default: () => state,
    };

    return (cases[action.type] || cases.default)(action.payload);
}

export default projectsReducer;
