import React from 'react';
import loadGoogleMaps from 'stillnovel/utils/loadGoogleMaps';
import retry from 'stillnovel/utils/retry';
import Loader from 'stillnovel/components/UI/Loader';
import Resolve from 'stillnovel/components/UI/Resolve';

import validateBackToSchoolSnapshot from 'stillnovel/components/App/Project/Builders/ArtPrint/build-steps/BackToSchoolSnapshot/validate';
import validateBirthStat from 'stillnovel/components/App/Project/Builders/ArtPrint/build-steps/BirthStat/validate';
import validateBirthStatSnapshot from 'stillnovel/components/App/Project/Builders/ArtPrint/build-steps/BirthStatSnapshot/validate';
import validateWedding from 'stillnovel/components/App/Project/Builders/ArtPrint/build-steps/Wedding/validate';
import validateTravel from 'stillnovel/components/App/Project/Builders/ArtPrint/build-steps/Travel/validate';
import validateTravelSnapshot from 'stillnovel/components/App/Project/Builders/ArtPrint/build-steps/TravelSnapshot/validate';
import validateAllAboutYou from 'stillnovel/components/App/Project/Builders/ArtPrint/build-steps/AllAboutYou/validate';
import validateGrandparent from 'stillnovel/components/App/Project/Builders/ArtPrint/build-steps/Grandparent/validate';
import validateParent from 'stillnovel/components/App/Project/Builders/ArtPrint/build-steps/Parent/validate';
import validatePet from 'stillnovel/components/App/Project/Builders/ArtPrint/build-steps/Pet/validate';
import validateCouples from 'stillnovel/components/App/Project/Builders/ArtPrint/build-steps/Couples/validate';
import validateEngagement from 'stillnovel/components/App/Project/Builders/ArtPrint/build-steps/Engagement/validate';
import validateSimple from 'stillnovel/components/App/Project/Builders/ArtPrint/build-steps/Simple/validate';

import BackToSchoolSnapshot from './build-steps/BackToSchoolSnapshot';
import BirthStat from './build-steps/BirthStat';
import BirthStatSnapshot from './build-steps/BirthStatSnapshot';
import Wedding from './build-steps/Wedding';
import Travel from './build-steps/Travel';
import TravelSnapshot from './build-steps/TravelSnapshot';
import AllAboutYou from './build-steps/AllAboutYou';
import Parent from './build-steps/Parent';
import Grandparent from './build-steps/Grandparent';
import Pet from './build-steps/Pet';
import Couples from './build-steps/Couples';
import Engagement from './build-steps/Engagement';
import Simple from './build-steps/Simple';

const builderHandles = [
    {
        handle: 'back-to-school-snapshot',
        component: BackToSchoolSnapshot,
        validate: validateBackToSchoolSnapshot,
    },
    {
        handle: 'birth-stat',
        component: BirthStat,
        validate: validateBirthStat,
    },
    {
        handle: 'birth-stat-snapshot',
        component: BirthStatSnapshot,
        validate: validateBirthStatSnapshot,
    },
    {
        handle: 'wedding',
        component: Wedding,
        validate: validateWedding,
    },
    {
        handle: 'travel',
        component: Travel,
        validate: validateTravel,
    },
    {
        handle: 'travel-snapshot',
        component: TravelSnapshot,
        validate: validateTravelSnapshot,
    },
    {
        handle: 'all-about-you',
        component: AllAboutYou,
        validate: validateAllAboutYou,
    },
    {
        handle: 'memorial',
        component: AllAboutYou,
        validate: validateAllAboutYou,
    },
    {
        handle: 'parent-dad',
        component: Parent,
        validate: validateParent,
    },
    {
        handle: 'parent-mom',
        component: Parent,
        validate: validateParent,
    },
    {
        handle: 'grandpa',
        component: Grandparent,
        validate: validateGrandparent,
    },
    {
        handle: 'grandma',
        component: Grandparent,
        validate: validateGrandparent,
    },
    {
        handle: 'pet',
        component: Pet,
        validate: validatePet,
    },
    {
        handle: 'couples',
        component: Couples,
        validate: validateCouples,
    },
    {
        handle: 'engagement',
        component: Engagement,
        validate: validateEngagement,
    },
    {
        handle: 'simple-framed-print',
        component: Simple,
        validate: validateSimple,
    },
];

const LoadableArtPrint = props => (
    <Resolve
        promise={() =>
            Promise.all([
                retry(
                    () =>
                        import(
                            /* webpackChunkName: "artprint", webpackPreload: true */ './ArtPrint'
                        )
                ),
                loadGoogleMaps(),
            ])
        }
    >
        {resolved => {
            if (!resolved) {
                return <Loader fullscreen text="Initializing builder..." />;
            }
            const [ArtPrint, google] = resolved;
            return (
                <ArtPrint.default
                    google={google}
                    builderHandles={builderHandles}
                    {...props}
                />
            );
        }}
    </Resolve>
);

export default LoadableArtPrint;
