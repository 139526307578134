import React, { Fragment, useEffect } from 'react';
import {
    Outlet,
    Route,
    Routes,
    useLocation,
    useMatch,
    useNavigate,
} from 'react-router-dom';
import { useEvent } from 'react-use';
import { Helmet } from 'react-helmet';
import { initialize, pageview, plugin } from 'react-ga';
import { initFBPixel, logPixelPageView } from 'core/utils/fb-pixel';
import storageAvailable from 'core/utils/storageAvailable';
import cookies from 'js-cookie';
import getConfig from 'core/config';
import { AuthLayout, MainLayout } from 'stillnovel/components/layouts';

import Cart from 'stillnovel/components/App/Checkout/Cart';

import Account from './Account';

import Logout from './Auth/Logout';

import Project from './Project';
import styles from './App.scss';
import NotFound from './NotFound/NotFound';

const ProtectedProject = props => {
    return <Project {...props} />;
};

const App = () => {
    const navigate = useNavigate();
    const { pathname, hash, state } = useLocation();
    const matchProject = useMatch('/project/create?');

    console.info(
        'Build Version',
        process.env.VERCEL_GIT_COMMIT_SHA || 'unknown'
    );

    const handleVisibilityChange = async e => {
        const current = process.env.VERCEL_GIT_COMMIT_SHA || 'unknown';
        if (e.target.hidden) {
            const headers = new Headers();
            headers.append('pragma', 'no-cache');
            headers.append('cache-control', 'no-cache');
            const result = await fetch('/version', { headers })
                .then(response => {
                    return response.json();
                })
                .catch(() => {});

            const latestVersion = result?.version || 'unknown';
            console.info('Latest Version', latestVersion);

            if (latestVersion !== current) {
                console.info('Outdated client refresh page');
                window.location.reload();
            }
        }
    };

    useEvent('visibilitychange', handleVisibilityChange);

    if (matchProject) {
        navigate('/');
    }

    useEffect(() => {
        initFBPixel();
        logPixelPageView();
    }, []);

    useEffect(() => {
        pintrk('track', 'pagevisit');
    }, []);

    useEffect(() => {
        initialize('UA-126094983-1', {
            titleCase: false,
        });
        plugin.require('ecommerce');
    }, []);

    useEffect(() => {
        pageview(pathname);
    }, [pathname]);

    useEffect(() => {
        !hash && state?.scroll !== false && window.scrollTo(0, 0);
    }, [hash, pathname, state]);

    useEffect(() => {
        if (storageAvailable('localStorage')) {
            // Persist debug module in the browser
            getConfig('debug')
                ? (window.localStorage.debug =
                      window.localStorage.debug === 'true' ||
                      !window.localStorage.debug
                          ? 'App:*'
                          : window.localStorage.debug)
                : (window.localStorage.debug =
                      window.localStorage.debug || false);
        }
    }, []);

    useEffect(() => {
        if (process.env.ENV !== 'production') {
            cookies.set('ecomBaseUrl', getConfig('clientUrl'), {
                domain: 'stillnovel.com',
            });
        }
    }, []);

    return (
        <Fragment>
            <Helmet
                titleTemplate="%s | Still Novel"
                defaultTitle="Still Novel | Custom Photo Prints and Gifts"
            >
                <meta
                    name="description"
                    content="Well designed premium photo products. We make it easy to create custom photo prints and gifts. We don’t just print your photos, we bring them to life."
                />
            </Helmet>
            <main className={styles.app}>
                <Routes>
                    {/* AUTH LAYOUT */}
                    <Route
                        path="/logout"
                        element={
                            <AuthLayout>
                                <Logout />
                            </AuthLayout>
                        }
                    />
                    <Route path="/project" element={<Outlet />}>
                        <Route
                            path=""
                            element={
                                <MainLayout>
                                    <NotFound />
                                </MainLayout>
                            }
                        />
                        <Route path=":handle">
                            <Route
                                path=":step"
                                element={<ProtectedProject />}
                            />
                            <Route path="" element={<ProtectedProject />} />
                        </Route>
                    </Route>
                    {/* NO LAYOUT and CATCH-ALL */}
                    <Route
                        path="/cart/*"
                        element={
                            <MainLayout>
                                <Cart />
                            </MainLayout>
                        }
                    />
                    <Route
                        path="*"
                        element={
                            <MainLayout>
                                <Account />
                            </MainLayout>
                        }
                    />
                </Routes>
            </main>
        </Fragment>
    );
};

export default App;
