import React from 'react';
import PropTypes from 'prop-types';
import Text from 'stillnovel/components/UI/Text';

import styles from './FormHeader.scss';

const FormHeader = ({ heading }) => (
    <div className={styles.root}>
        <Text tag="h1" theme="form-header">
            {heading}
        </Text>
    </div>
);

FormHeader.propTypes = {
    heading: PropTypes.string.isRequired,
};

export default FormHeader;
