import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Field from 'redux-form/es/Field';
import reduxForm from 'redux-form/es/reduxForm';
import { Container, Col, Row } from 'stillnovel/components/UI/Grid';
import FieldSelect from 'stillnovel/components/UI/Input/FieldSelect';
import FieldInput from 'stillnovel/components/UI/Input/FieldInput';
import Form from 'stillnovel/components/UI/Form';
import NavArrows from 'stillnovel/components/UI/NavArrows';
import Text from 'stillnovel/components/UI/Text';
import { stripEndPeriod } from 'stillnovel/utils';

import TextPreview from 'stillnovel/components/UI/TextPreview';

import FormHeader from '../../../FormHeader';
import validate from '../validate';
import StepNav from '../../../StepNav';

let Stats = ({
    handleSubmit,
    invalid,
    previousPage,
    submitting,
    project,
    metadata,
}) => {
    const numberOfTravelers = metadata.numberOfTravelers || 1;
    const pronoun = numberOfTravelers > 1 ? 'We' : 'I';

    return (
        <Fragment>
            <Form>
                <FormHeader heading="Stats" />
                <Container>
                    <Row>
                        <Col>
                            <Text
                                tag="span"
                                theme="form-title"
                                htmlFor="comfortStatement"
                            >
                                It was a/an{' '}
                                <Field
                                    inline
                                    stretch
                                    name="tripAdjective"
                                    component={FieldInput}
                                    placeholder="Ex: “Memorable”"
                                    maxLength={13}
                                    autoComplete={`${project.id}-tripAdjective`}
                                />{' '}
                                trip
                            </Text>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Text
                                tag="span"
                                theme="form-title"
                                style={{ lineHeight: '3.5' }}
                            >
                                {pronoun} will never forget{' '}
                                <Field
                                    inline
                                    stretch
                                    name="neverForget1"
                                    component={FieldInput}
                                    placeholder="Ex: “The bumpy taxi ride”"
                                    autoComplete={`${project.id}-neverForget1`}
                                    autoCapitalize="off"
                                    maxLength={45}
                                    style={{ minWidth: '210px' }}
                                />{' '}
                                and{' '}
                                <Field
                                    inline
                                    stretch
                                    name="neverForget2"
                                    component={FieldInput}
                                    placeholder="Ex: “The amazing food”"
                                    autoComplete={`${project.id}-neverForget2`}
                                    autoCapitalize="off"
                                    maxLength={45}
                                    normalize={stripEndPeriod}
                                    style={{ minWidth: '190px' }}
                                />{' '}
                                .
                            </Text>
                            <TextPreview
                                string={`${pronoun} will never forget <em>${metadata.neverForget1 ?? ''}</em> and <em>${metadata.neverForget2 ?? ''}</em>.`}
                            />
                        </Col>
                    </Row>
                    <Row alignment="bottom">
                        <Col>
                            <Text
                                tag="label"
                                theme="form-title"
                                htmlFor="traveledStatement"
                            >
                                We traveled for
                            </Text>
                            <Field
                                id="traveledStatement"
                                name="traveledStatement"
                                component={FieldSelect}
                                stretch
                            >
                                <option value="" disabled>
                                    --
                                </option>
                                <option value="Fun">Fun</option>
                                <option value="Family">Family</option>
                                <option value="Adventure">Adventure</option>
                                <option value="Rest & Relaxation">
                                    Rest & Relaxation
                                </option>
                                <option value="Celebration">Celebration</option>
                                <option value="Work">Work</option>
                            </Field>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Text
                                tag="label"
                                theme="form-title"
                                htmlFor="rating"
                            >
                                Rate your trip
                            </Text>
                            <Field
                                id="rating"
                                name="rating"
                                component={FieldSelect}
                                stretch
                            >
                                <option value="" disabled>
                                    --
                                </option>
                                {Array(5)
                                    .fill()
                                    .map((_, i) => {
                                        return (
                                            <option key={i} value={`${i + 1}`}>
                                                {'★'.repeat(i + 1)}
                                            </option>
                                        );
                                    })}
                            </Field>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Text
                                tag="label"
                                theme="form-title"
                                htmlFor="lovedStatement"
                            >
                                Favorite Spot:
                            </Text>
                            <Field
                                stretch
                                id="lovedStatement"
                                name="lovedStatement"
                                type="text"
                                component={FieldInput}
                                maxLength="25"
                                showErrorText
                                placeholder="Ex: “The Botanical Gardens”"
                                autoComplete={`${project.id}-favorite-spot`}
                            />
                        </Col>
                    </Row>
                </Container>
            </Form>
            <StepNav>
                <NavArrows
                    buttons={[
                        { onClick: previousPage },
                        {
                            onClick: handleSubmit,
                            disabled: invalid || submitting,
                            label: 'Review',
                        },
                    ]}
                />
            </StepNav>
        </Fragment>
    );
};

Stats.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    invalid: PropTypes.bool,
    metadata: PropTypes.object.isRequired,
    previousPage: PropTypes.func.isRequired,
    project: PropTypes.object.isRequired,
    submitting: PropTypes.bool,
};

Stats.defaultProps = {
    invalid: false,
    submitting: false,
};

Stats = reduxForm({
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: false,
    touchOnBlur: false,
    touchOnChange: true,
    validate,
})(Stats);

export default Stats;
