import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import Text from 'stillnovel/components/UI/Text';
import { Col, Row } from 'stillnovel/components/UI/Grid';

import styles from './FilterSelector.scss';

const filterOptions = [
    { name: 'No filter', key: 'default' },
    { name: 'Color filter', key: 'color' },
    { name: 'Black and White filter', key: 'bw' },
];

const FilterSelector = ({ input, meta: { touched, error } }) => {
    return (
        <div
            className={cx(styles.root, {
                [styles['has-error']]: touched && error,
            })}
        >
            <Row alignment="bottom">
                {filterOptions.map((filter, i) => {
                    const selected = input.value === filter.key;
                    return (
                        <Col key={filter.name} colSpan={i + 1}>
                            <label
                                className={cx(styles.label, {
                                    [styles.selected]: selected,
                                })}
                            >
                                <Text tag="span" theme="body1">
                                    {filter.name}
                                </Text>
                                <input
                                    {...input}
                                    value={filter.key}
                                    checked={selected}
                                    type="radio"
                                />
                            </label>
                        </Col>
                    );
                })}
            </Row>
        </div>
    );
};

FilterSelector.propTypes = {
    input: PropTypes.shape({
        value: PropTypes.any,
    }),
    meta: PropTypes.shape({
        touched: PropTypes.bool,
        error: PropTypes.string,
    }),
};

export default FilterSelector;
