import {
    trimWhitespaceFromValues,
    validateThought,
} from 'stillnovel/utils/validation';

export default values => {
    // Trim leading and trailing whitespace from values object
    values = trimWhitespaceFromValues(values);

    const withoutAstrologyFields =
        values.withoutAstrology === 'true'
            ? {
                  gender: !values.gender && 'Required',
                  personality: !values.personality && 'Required',
                  motto: !values.motto && 'Required',
              }
            : {};

    const errors = {
        accept:
            !values.accept &&
            values.accept !== true &&
            'Please confirm your artwork is error-free & ready for print.',
        baseColor: !values.baseColor && 'Required',
        birthDate: !values.birthDate && 'Required',
        birthLocationData: !values.birthLocationData && 'Required',
        favoriteFood: !values.favoriteFood && 'Required',
        favoritePastime: !values.favoritePastime && 'Required',
        favoritePlace: !values.favoritePlace && 'Required',
        firstName: !values.firstName && 'Required',
        image: !values.image && 'Required',
        imageMeta: !values.imageMeta && 'Required',
        knownFor1: !values.knownFor1 && 'Required',
        knownFor2: !values.knownFor2 && 'Required',
        lastName: !values.lastName && 'Required',
        nickname: !values.nickname && 'Required',
        shopifyProductId: !values.shopifyProductId && 'Required',
        thought: validateThought(values.thought),
        ...withoutAstrologyFields,
    };
    return errors;
};
