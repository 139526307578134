import {
    trimWhitespaceFromValues,
    validateThought,
} from 'stillnovel/utils/validation';
import moment from 'moment';

export default values => {
    // Trim leading and trailing whitespace from values object
    values = trimWhitespaceFromValues(values);

    const withoutAstrologyFields =
        values.withoutAstrology === 'true'
            ? {
                  nickname: !values.nickname && 'Required',
                  gender: !values.gender && 'Required',
                  childWord: !values.childWord && 'Required',
              }
            : {};

    const errors = {
        accept:
            !values.accept &&
            values.accept !== true &&
            'Please confirm your artwork is error-free & ready for print.',
        baseColor: !values.baseColor && 'Required',
        birthDate: !values.birthDate && 'Required',
        birthLocationData: !values.birthLocationData && 'Required',
        dueDate: !values.dueDate && 'Required',
        firstName: !values.firstName && 'Required',
        image: !values.image && 'Required',
        imageMeta: !values.imageMeta && 'Required',
        lastName: !values.lastName && 'Required',
        len: !values.len && 'Required',
        thought: validateThought(values.thought),
        weightLbs: !values.weightLbs && 'Required',
        weightOz: !values.weightOz && 'Required',
        shopifyProductId: !values.shopifyProductId && 'Required',
        ...withoutAstrologyFields,
    };

    if (values.birthDate && values.dueDate) {
        const birthDate = moment(values.birthDate);
        const dueDate = moment(values.dueDate);

        // Check if the birth date is more than 1 month after the due date
        if (birthDate.isAfter(dueDate.clone().add(1, 'month'))) {
            errors.birthDate =
                'Warning: Dates entered are implausible. Birth date is more than 1 month after the due date.';
        }

        // Check if the birth date is more than 6 months before the due date
        if (birthDate.isBefore(dueDate.clone().subtract(6, 'months'))) {
            errors.birthDate =
                'Warning: Dates entered are implausible. Birth date is more than 6 months before the due date.';
        }
    }

    return errors;
};
