import { registerActionTypes, registerStates } from 'stillnovel/constants';

export const actionTypes = registerActionTypes('APP', [
    'SET_LOAD_STATE',
    'SET_NAV_STATE',
    'SET_CART_STATE',
]);

export const states = registerStates('APP', [
    'NAV_OPEN_SHOP',
    'NAV_CLOSED',
    'CART_OPENED',
    'CART_CLOSED',
    'LOADING',
    'LOADING_FINISHED',
]);

export const setAppLoadState = payload => ({
    type: actionTypes.SET_LOAD_STATE,
    payload,
});

const allowedNavStates = [states.NAV_OPEN_SHOP, states.NAV_CLOSED];

export const setNavState = navState => {
    if (!allowedNavStates.includes(navState)) {
        throw new Error(`Unknown setNavState state: ${navState}`);
    }
    return {
        type: actionTypes.SET_NAV_STATE,
        payload: navState,
    };
};

export const openCart = () => ({
    type: actionTypes.SET_CART_STATE,
    payload: states.CART_OPEN,
});

export const closeCart = () => ({
    type: actionTypes.SET_CART_STATE,
    payload: states.CART_CLOSED,
});

export const setDraggingOver = () => {};
