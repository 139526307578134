import cookies from 'js-cookie';
import getConfig from 'core/config';

export default class CartService {
    api;

    constructor(client) {
        this.client = client;
    }

    setUser(userId) {
        this.userId = userId;
    }

    async getAll() {
        const checkout = await this.getCheckout(false);
        return checkout;
    }

    async addItem(item) {
        // {
        //     variantId: project?.project?.shopifyProductId,
        //     quantity: 1,
        //     customAttributes: [
        //         { key: 'Style', value: project?.project.metadata.style },
        //         { key: '_projectId', value: project?.project.id },
        //     ],
        // }
        const checkout = await this.getCheckout(true);

        const result = await this.client.checkout.addLineItems(checkout.id, [
            item,
        ]);

        return result;
    }

    async updateItem(item) {
        const checkout = await this.getCheckout(true);

        const result = await this.client.checkout.updateLineItems(checkout.id, [
            item,
        ]);
        return result;
    }

    async deleteItem(id) {
        const checkout = await this.getCheckout(true);

        const result = await this.client.checkout.removeLineItems(checkout.id, [
            id,
        ]);

        return result;
    }

    async updateQTY(id, qty) {
        console.log('updateQTY', id, qty);
    }

    // async createOrder(order) {
    //     const response = await this.api.post(
    //         `/orders?${this.composeQuery()}`,
    //         order,
    //         {
    //             withSession: true,
    //             withUser: true,
    //         }
    //     );
    //     return response.data;
    // }

    get tempStore() {
        return cookies;
    }

    async getCheckout(autoCreate = false) {
        if (this.checkoutId) {
            try {
                const checkout = await this.client.checkout.fetch(
                    this.checkoutId
                );
                if (checkout.completedAt) {
                    throw Error('Checkout already completed');
                }
                this.setCheckoutId(checkout.id);
                return checkout;
            } catch (e) {
                console.warn(e);
                const checkout = await this.client.checkout.create();
                this.setCheckoutId(checkout.id);
                return checkout;
            }
        } else if (autoCreate) {
            const checkout = await this.client.checkout.create();
            this.setCheckoutId(checkout.id);
            return checkout;
        }
        return false;
    }

    setCheckoutId(checkoutId) {
        const stored = this.tempStore.get(getConfig('cookieNameCheckout'));
        const storedObject = stored ? JSON.parse(stored) : {};
        this.tempStore.set(
            getConfig('cookieNameCheckout'),
            JSON.stringify({
                ...storedObject,
                [this.userId]: checkoutId,
            }),
            { domain: getConfig('cookieDomain'), expires: 365 }
        );
    }

    get checkoutId() {
        const stored = cookies.get(getConfig('cookieNameCheckout'));
        const storedObject = stored ? JSON.parse(stored) : {};

        return storedObject[this.userId] || false;
    }

    clearCheckoutId() {
        cookies.remove(getConfig('cookieNameCheckout'));
    }
}
