import { combineReducers } from 'redux';
import formReducer from 'redux-form/es/reducer';

const reducers = {
    form: formReducer,
    app: require('./app/reducer').default,
    auth: require('./auth/reducer').default,
    cart: require('./cart/reducer').default,
    project: require('./project/reducer').default,
    projects: require('./projects/reducer').default,
};

export default combineReducers(reducers);
