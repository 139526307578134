import { useState, useCallback } from 'react';
import { useUnmount } from 'react-use';

export const useWait = () => {
    const [timeoutIds, setTimeoutIds] = useState([]);

    useUnmount(() => {
        timeoutIds.map(clearTimeout);
    });

    const wait = useCallback(
        ms => {
            let timeoutId;
            const promise = new Promise(resolve => {
                timeoutId = setTimeout(resolve, ms);
            }).catch(err => {
                console.error(err);
            });
            setTimeoutIds([...timeoutIds, timeoutId]);
            return promise;
        },
        [timeoutIds]
    );

    return wait;
};
