import actions from 'redux-form/es/actions';
import { put } from 'redux-saga/effects';
import {
    scopedMetaUpdateHandler,
    handleImageMeta,
} from 'stillnovel/store/project/shared';

function* handleStyle() {
    // clear any incompatible values here on style change
    yield put(actions.change('project', 'baseColor', null));
}

const derivedHandlers = {
    style: handleStyle,
    imageMeta: handleImageMeta,
};

export default scopedMetaUpdateHandler('couples', derivedHandlers);
