import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Field from 'redux-form/es/Field';
import reduxForm from 'redux-form/es/reduxForm';
import { Container, Col, Row } from 'stillnovel/components/UI/Grid';
import FieldInput from 'stillnovel/components/UI/Input/FieldInput';
import Form from 'stillnovel/components/UI/Form';
import NavArrows from 'stillnovel/components/UI/NavArrows';
import Text from 'stillnovel/components/UI/Text';

import TextPreview from 'stillnovel/components/UI/TextPreview';

import FormHeader from '../../../FormHeader';
import validate from '../validate';
import StepNav from '../../../StepNav';

let Stats = ({
    handleSubmit,
    invalid = false,
    previousPage,
    submitting = false,
    project,
    metadata,
}) => {
    return (
        <Fragment>
            <Form>
                <FormHeader heading="Additional Details" />
                <Container>
                    <Row>
                        <Col>
                            <Text
                                tag="label"
                                theme="form-title"
                                htmlFor="favoriteFood"
                            >
                                Favorite Food
                            </Text>
                            <Field
                                name="favoriteFood"
                                component={FieldInput}
                                placeholder="Ex: “Pizza”"
                                maxLength="29"
                                autoComplete={`${project.id}-favoriteFood`}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Text
                                tag="label"
                                theme="form-title"
                                htmlFor="favoritePlace"
                            >
                                Favorite Place
                            </Text>
                            <Field
                                name="favoritePlace"
                                component={FieldInput}
                                placeholder="Ex: “our couch” or “Big Bear Lake”"
                                maxLength="29"
                                autoComplete={`${project.id}-favoritePlace`}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Text
                                tag="label"
                                theme="form-title"
                                htmlFor="favoritePastime"
                            >
                                Favorite Pastime
                            </Text>
                            <Field
                                name="favoritePastime"
                                component={FieldInput}
                                placeholder="Ex: “mid-day movies” or “hiking”"
                                maxLength="29"
                                autoComplete={`${project.id}-favoritePastime`}
                            />
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <Text
                                tag="span"
                                theme="form-title"
                                style={{ lineHeight: '3.5' }}
                            >
                                It all started with{' '}
                                <Field
                                    inline
                                    name="startedWith1"
                                    component={FieldInput}
                                    placeholder="Ex: “a little smile”"
                                    maxLength="29"
                                    autoComplete={`${project.id}-startedWith1`}
                                />{' '}
                                and{' '}
                                <Field
                                    inline
                                    name="startedWith2"
                                    component={FieldInput}
                                    placeholder="Ex: “a phone call”"
                                    autoComplete={`${project.id}-startedWith`}
                                />
                            </Text>
                            <TextPreview
                                string={`It all started with <em>${metadata.startedWith1 ?? ''}</em> and <em>${metadata.startedWith2 ?? ''}</em>.`}
                            />
                        </Col>
                    </Row>
                </Container>
            </Form>
            <StepNav>
                <NavArrows
                    buttons={[
                        { onClick: previousPage },
                        {
                            onClick: handleSubmit,
                            disabled: invalid || submitting,
                            label: 'Review',
                        },
                    ]}
                />
            </StepNav>
        </Fragment>
    );
};

Stats.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    invalid: PropTypes.bool,
    previousPage: PropTypes.func.isRequired,
    submitting: PropTypes.bool,
    project: PropTypes.object.isRequired,
    metadata: PropTypes.object.isRequired,
};

Stats = reduxForm({
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: false,
    touchOnBlur: false,
    touchOnChange: true,
    validate,
})(Stats);

export default Stats;
