import {
    scopedMetaUpdateHandler,
    handleImageMeta,
} from 'stillnovel/store/project/shared';

const derivedHandlers = {
    imageMeta: handleImageMeta,
};

export default scopedMetaUpdateHandler(
    /^simple-framed-print-([a-zA-Z0-9-]+)$/,
    derivedHandlers
);
