import { actionTypes, states } from './actions';

// sometimes we don't get the attributes in later parts of the login flow
// but lets not clobber the ones we've got if we've not got them
const addAttributes = (state, attributes) => {
    const s2 = { ...state };
    if (attributes) {
        s2.attributes = attributes;
    }
    return s2;
};

/**
 * reducer function to be passed to redux combineReducers
 * @param {object} state
 * @param {object} action
 */

export default (state = {}, action) => {
    switch (action.type) {
        case actionTypes.CONFIGURE:
            return {
                ...state,
                config: action.config,
            };

        case actionTypes.AUTHENTICATED:
            return {
                ...state,
                cache: {
                    userName: null,
                    email: null,
                },
                error: '',
                state: states.AUTHENTICATED,
                user: action.user,
            };

        case actionTypes.CLEAR_CACHE:
            return {
                ...state,
                cache: {
                    userName: null,
                    email: null,
                },
            };

        case actionTypes.LOGGING_IN:
            return {
                ...state,
                state: states.LOGGING_IN,
                attributes: action.attributes,
            };

        case actionTypes.LOGIN:
            return {
                ...state,
                user: action.user,
                state: states.LOGGED_IN,
            };

        case actionTypes.LOGOUT:
            return {
                ...state,
                attributes: {},
                error: '',
                groups: [],
                user: null,
                state: states.LOGGED_OUT,
            };

        case actionTypes.UNAUTHENTICATED_USER:
            return {
                ...state,
                user: { ...action.payload.user },
                error: '',
                groups: [],
                state: states.UNAUTHENTICATED_USER,
            };

        case actionTypes.PARTIAL_LOGOUT:
            return {
                ...state,
                userName: state.user.username,
                error: '',
                groups: [],
                state: states.LOGGED_OUT,
            };

        case actionTypes.LOGIN_FAILURE:
            return {
                ...state,
                state: states.LOGGED_OUT,
                error: action.error,
            };

        case actionTypes.LOGIN_MFA_REQUIRED:
            return {
                ...state,
                error: '',
                state: states.MFA_REQUIRED,
            };

        case actionTypes.LOGIN_NEW_PASSWORD_REQUIRED:
            return {
                ...state,
                error: '',
                state: states.NEW_PASSWORD_REQUIRED,
            };

        case actionTypes.USER_UNCONFIRMED:
            return {
                ...state,
                state: states.CONFIRMATION_REQUIRED,
                cache: {
                    userName: action.user.username,
                    email: action.email ? action.email : state.cache.email,
                },
            };

        case actionTypes.USER_CONFIRM_FAILED:
            return {
                ...state,
                state: states.CONFIRMATION_REQUIRED,
                error: action.error,
            };

        case actionTypes.NEW_PASSWORD_REQUIRED_FAILURE:
            return {
                ...state,
                error: action.error,
                state: states.NEW_PASSWORD_REQUIRED,
            };

        case actionTypes.ACCOUNT_VERIFICATION_REQUIRED:
            return {
                ...state,
                ...addAttributes(
                    {
                        error: '',
                        state: states.ACCOUNT_VERIFICATION_REQUIRED,
                    },
                    action.attributes
                ),
            };

        case actionTypes.ACCOUNT_VERIFICATION_ERROR:
            return {
                ...state,
                state: states.ACCOUNT_VERIFICATION_ERROR,
                error: action.error,
            };

        case actionTypes.EMAIL_VERIFICATION_ERROR:
            return {
                ...state,
                state: states.EMAIL_VERIFICATION_ERROR,
                error: action.error,
            };

        case actionTypes.ACCOUNT_VERIFICATION_FAILED:
            return {
                ...state,
                ...addAttributes(
                    {
                        error: action.error,
                        state: states.ACCOUNT_VERIFICATION_REQUIRED,
                    },
                    action.attributes
                ),
            };

        case actionTypes.BEGIN_PASSWORD_RESET_FLOW:
            return {
                ...state,
                error: action.error,
            };

        case actionTypes.CONTINUE_PASSWORD_RESET_FLOW:
            return state;

        case actionTypes.FINISH_PASSWORD_RESET_FLOW:
            return state;

        // this moves us into the AUTHENTICATED state, potentially causing
        // a number of side-effects. this is so we can re-verify the email
        // address if we have to
        case actionTypes.UPDATE_USER_ATTRIBUTES:
            return {
                ...state,
                attributes: {
                    ...state.attributes,
                    ...action.attributes,
                },
                state: states.AUTHENTICATED,
            };

        default:
            return state;
    }
};
