import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Cell } from 'stillnovel/components/UI/Section';
import Text from 'stillnovel/components/UI/Text';

import styles from './Head.scss';

const Head = ({ cells, className, disabled }) => {
    const classNames = cx(styles.root, className, {
        [styles.disabled]: disabled,
    });
    return (
        <div className={classNames}>
            {cells.map((cell, i) => (
                <Cell key={i} colSpan={cell.colSpan || cells.length}>
                    <Text theme="body1-alt">{cell.label || cell}</Text>
                </Cell>
            ))}
        </div>
    );
};

Head.propTypes = {
    cells: PropTypes.array.isRequired,
    className: PropTypes.string,
    disabled: PropTypes.bool,
};

Head.defaultProps = {
    classname: null,
    disabled: false,
};

export default Head;
