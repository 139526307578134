import { useState, useEffect, useCallback } from 'react';
import { useUnmount } from 'react-use';

const Resolve = ({ promise, children }) => {
    const [resolved, setResolved] = useState(false);
    const [canceled, setCanceled] = useState(false);

    const loadPromise = useCallback(async () => {
        const resolved = await promise();
        if (!canceled) {
            setResolved(resolved);
        }
    }, [promise, canceled]);

    useEffect(() => {
        loadPromise();
    }, [loadPromise]);

    useUnmount(() => {
        setCanceled(true);
    });

    return children(resolved);
};

export default Resolve;
