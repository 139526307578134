export const trimWhitespaceFromValues = values => {
    return Object.fromEntries(
        Object.entries(values).map(([key, value]) => [
            key,
            typeof value === 'string' ? value.trim() : value,
        ])
    );
};

export const validateThought = (values = '') => {
    const MAX_CHAR = 125;
    if (values.length >= MAX_CHAR) {
        return `This copy is limited to ${MAX_CHAR} characters`;
    }
    return false;
};

export const validateStartDate = startDate => {
    if (!startDate) {
        return 'Required';
    }
    if (startDate >= new Date()) {
        return 'A date in the future is not allowed';
    }
};

export const validateEndDate = (endDate, startDate) => {
    if (!endDate) {
        return 'Required';
    }
    // Check if endDate is before startDate
    if (endDate < startDate) {
        return 'End date must be after start date';
    }
};
