import {
    trimWhitespaceFromValues,
    validateThought,
} from 'stillnovel/utils/validation';

export default values => {
    // Trim leading and trailing whitespace from values object
    values = trimWhitespaceFromValues(values);

    const errors = {
        accept:
            !values.accept &&
            values.accept !== true &&
            'Please confirm your artwork is error-free & ready for print.',
        adjective1: !values.adjective1 && 'Required',
        adjective2: !values.adjective2 && 'Required',
        adviceStatement: !values.adviceStatement && 'Required',
        alwaysStatement: !values.alwaysStatement && 'Required',
        baseColor: !values.baseColor && 'Required',
        birthDate: !values.birthDate && 'Required',
        descriptor: !values.descriptor && 'Required',
        firstName: !values.firstName && 'Required',
        image: !values.image && 'Required',
        imageMeta: !values.imageMeta && 'Required',
        lastName: !values.lastName && 'Required',
        lessonStatement: !values.lessonStatement && 'Required',
        loveStatement: !values.loveStatement && 'Required',
        neverStatement: !values.neverStatement && 'Required',
        nickname: !values.nickname && 'Required',
        parentDate: !values.parentDate && 'Required',
        parentType: !values.parentType && 'Required',
        residenceLocationData: !values.residenceLocationData && 'Required',
        shopifyProductId: !values.shopifyProductId && 'Required',
        subject: !values.subject && 'Required',
        thought: validateThought(values.thought),
    };
    return errors;
};
