import {
    trimWhitespaceFromValues,
    validateThought,
} from 'stillnovel/utils/validation';

export default values => {
    // Trim leading and trailing whitespace from values object
    values = trimWhitespaceFromValues(values);

    const modernOnlyFields =
        values.style === 'modern'
            ? { songLyric: !values.songLyric && 'Required' }
            : {};

    const guestCountOnlyFields =
        values.guestCountType === 'COUNT' || !values.guestCountType
            ? { guestCount: !values.guestCount && 'Required' }
            : {};

    const errors = {
        accept:
            !values.accept &&
            values.accept !== true &&
            'Please confirm your artwork is error-free & ready for print.',
        baseColor: !values.baseColor && 'Required',
        ceremonyLocationData: !values.ceremonyLocationData && 'Required',
        ceremonyVenue: !values.ceremonyVenue && 'Required',
        engagedLocationName: !values.engagedLocationName && 'Required',
        engagedYear: !values.engagedYear && 'Required',
        experienceWord: !values.experienceWord && 'Required',
        heading: !values.heading && 'Required',
        image: !values.image && 'Required',
        imageMeta: !values.imageMeta && 'Required',
        metLocationName: !values.metLocationName && 'Required',
        metLocationPreposition: !values.metLocationPreposition && 'Required',
        metYear: !values.metYear && 'Required',
        engagedLocationPreposition:
            !values.engagedLocationPreposition && 'Required',
        officiatedBy: !values.officiatedBy && 'Required',
        party1FirstName: !values.party1FirstName && 'Required',
        party1Gender: !values.party1Gender && 'Required',
        party1Word: !values.party1Word && 'Required',
        party2FirstName: !values.party2FirstName && 'Required',
        party2Gender: !values.party2Gender && 'Required',
        party2Word: !values.party2Word && 'Required',
        receptionPlace: !values.receptionPlace && 'Required',
        songArtist: !values.songArtist && 'Required',
        songTitle: !values.songTitle && 'Required',
        thought: validateThought(values.thought),
        weddingDate: !values.weddingDate && 'Required',
        shopifyProductId: !values.shopifyProductId && 'Required',
        ...modernOnlyFields,
        ...guestCountOnlyFields,
    };

    return errors;
};
