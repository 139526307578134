import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import styles from './Group.scss';

const Group = ({ children, className, tag, ...props }) => {
    const TagName = tag;
    const classNames = cx(styles.group, {
        [className]: className,
    });
    return (
        <TagName {...props} className={classNames}>
            {children}
        </TagName>
    );
};

Group.propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
    tag: PropTypes.node,
};

Group.defaultProps = {
    tag: 'div',
};

export default Group;
