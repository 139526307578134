import { registerActionTypes } from 'stillnovel/constants';

export const actionTypes = registerActionTypes('PROJECT', [
    'RESET',
    'FETCH_REQUESTED',
    'FETCH_SUCCEEDED',
    'FETCH_FAILED',
    'SAVE_REQUESTED',
    'SAVE_SUCCEEDED',
    'SAVE_FAILED',
    'UPDATE_VALUE',
    'UPDATE_META_VALUE',
    'UPDATE_SNAPSHOT',
    'CREATE',
    'CREATE_REDIRECT_REQUESTED',
    'UPDATE_PRODUCT',
    'CREATE_PROJECT_REQUESTED',
    'CREATE_PROJECT_REQUESTED_FAILED',
    'HANDLE_INPUT_REQUESTED',
    'INIT',
]);

export const updateMetaValue = (field, value) => ({
    type: actionTypes.UPDATE_META_VALUE,
    payload: {
        field,
        value,
    },
});

export const fetchProject = id => ({
    type: actionTypes.FETCH_REQUESTED,
    payload: {
        id: id,
    },
});

export const initProject = project => {
    return {
        type: actionTypes.INIT,
        payload: project,
    };
};

export const createProject = () => dispatch => {
    dispatch({
        type: actionTypes.CREATE,
    });
};

export const saveProjectRequest = id => ({
    type: actionTypes.SAVE_REQUESTED,
    payload: { id },
});

export const updateProduct = productCode => ({
    type: actionTypes.UPDATE_PRODUCT,
    payload: productCode,
});

export const resetProject = () => ({
    type: actionTypes.RESET,
});
