import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import SN from 'stillnovel/services';

import { actionTypes as projectActionTypes } from '../project/actions';

import { actionTypes } from './actions';

function* fetchProjects() {
    try {
        const projects = yield call(() => SN.project.getAll());
        yield put({
            type: actionTypes.FETCH_SUCCEEDED,
            payload: { items: projects },
        });
    } catch (e) {
        yield put({
            type: actionTypes.FETCH_FAILED,
            payload: { message: e.message },
        });
    }
}

function* deleteProject(action) {
    try {
        const projects = yield call(() => SN.project.delete(action.payload.id));
        yield put({
            type: actionTypes.DELETE_SUCCEEDED,
            payload: { items: projects },
        });
    } catch (e) {
        yield put({
            type: actionTypes.DELETE_FAILED,
            payload: { message: e.message },
        });
    }
}

function* duplicateProject(action) {
    const { id } = action.payload;
    try {
        const project = yield call(() => SN.project.duplicate(id));
        yield put({
            type: actionTypes.DUPLICATE_SUCCEEDED,
            payload: { ...project },
        });
    } catch (e) {
        yield put({
            type: actionTypes.DUPLICATE_FAILED,
            payload: { message: e.message },
        });
    }
}

function* updateProject(action) {
    yield put({
        type: actionTypes.UPDATE_PROJECT,
        payload: { ...action.payload.project },
    });
}

export default [
    takeLatest(actionTypes.FETCH_REQUESTED, fetchProjects),
    takeEvery(actionTypes.DUPLICATE_REQUESTED, duplicateProject),
    takeEvery(actionTypes.DELETE_REQUESTED, deleteProject),
    takeEvery(projectActionTypes.SAVE_SUCCEEDED, updateProject),
];
