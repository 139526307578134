import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { logout } from 'stillnovel/store/auth/actions';

const Logout = ({ logout, state }) => {
    useEffect(() => {
        logout();
    }, [logout]);

    return <p>logging out...</p>;
};

Logout.propTypes = {
    logout: PropTypes.func,
    state: PropTypes.string,
};

const mapStateToProps = state => ({
    state: state.auth.state,
});

const mapDispatchToProps = { logout };

const appState = connect(mapStateToProps, mapDispatchToProps);

export default compose(appState)(Logout);
