import PropTypes from 'prop-types';
import React from 'react';
import cx from 'classnames';

import Input from './Input';
import styles from './FieldInput.scss';

const FieldInput = ({ inline, input, meta: { touched, error }, ...props }) => {
    return (
        <div
            className={cx(styles.field, {
                [styles.inline]: inline,
            })}
        >
            <Input {...input} {...props} touched={touched} error={error} />
        </div>
    );
};

FieldInput.propTypes = {
    inline: PropTypes.bool,
    input: PropTypes.object,
    meta: PropTypes.object,
};

export default FieldInput;
