import React from 'react';
import { Helmet } from 'react-helmet';
import Text from 'stillnovel/components/UI/Text';

import styles from './NotFound.module.scss';

export default function NotFound() {
    return (
        <div className={styles.root}>
            <Helmet>
                <title>Page not found</title>
            </Helmet>
            <div className={styles.inner}>
                <Text tag="h1" theme="jumbo">
                    Oops!
                </Text>
                <Text tag="h3" theme="title">
                    We can&#39;t seem to find the page you&#39;re looking for.
                </Text>
            </div>
        </div>
    );
}
