import React from 'react';
import PropTypes from 'prop-types';

import Input from './Input';

const FieldRadio = ({
    className,
    id,
    input,
    label,
    labelTheme,
    checked,
    inline,
}) => {
    return (
        <Input
            {...input}
            key={id}
            checked={input.value === id || !!checked}
            className={className}
            id={`input-radio-${id}`}
            label={label}
            labelTheme={labelTheme}
            name={input.name}
            type="radio"
            value={id}
            inline={inline}
        />
    );
};

FieldRadio.propTypes = {
    className: PropTypes.string,
    id: PropTypes.string.isRequired,
    input: PropTypes.object.isRequired,
    checked: PropTypes.bool,
    label: PropTypes.string.isRequired,
    labelTheme: PropTypes.string,
    inline: PropTypes.bool,
};

FieldRadio.defaultProps = {
    className: null,
    labelTheme: 'body1',
};

export default FieldRadio;
