import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import Link from 'stillnovel/components/UI/Link';
import SvgIcon from 'stillnovel/components/UI/SvgIcon';
import Text from 'stillnovel/components/UI/Text';

import styles from './CloseButton.scss';

const CloseButton = ({ className, children, to, ...props }) => {
    const classNames = cx(styles.btn, className);
    if (to) {
        return (
            <Link {...props} className={classNames} to={to}>
                <SvgIcon iconType="close" />
                {children && (
                    <Text theme="body1-alt" className={styles.label}>
                        {children}
                    </Text>
                )}
            </Link>
        );
    }
    return (
        <button {...props} className={classNames}>
            <SvgIcon iconType="close" />
            {children && (
                <Text theme="body1-alt" className={styles.label}>
                    {children}
                </Text>
            )}
        </button>
    );
};

CloseButton.propTypes = {
    className: PropTypes.string,
    children: PropTypes.node,
    to: PropTypes.string,
};

CloseButton.defaultProps = {
    className: null,
    children: null,
    to: null,
};

export default CloseButton;
