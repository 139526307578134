import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import Text from 'stillnovel/components/UI/Text/Text';

import styles from './TextPreview.module.css';

const TextPreview = ({ string }) => {
    const [hasUppercase, setHasUppercase] = useState(false);
    const [formattedString, setFormattedString] = useState(string);

    useEffect(() => {
        // Get all <em> tags in the string
        const emTags = string.match(/<em>.*?<\/em>/g) || [];
        // Detect if there are any uppercase letters in the <em> tags
        const hasUppercase = emTags.some(tag => /[A-Z]/.test(tag.slice(4, -5)));
        setHasUppercase(hasUppercase);
    }, [string]);

    useEffect(() => {
        // Add .containsUppercase class to all <em> tags containing uppercase letters
        const formattedString = string.replace(
            /<em>([^<]*[A-Z][^<]*)<\/em>/g,
            `<em class="${styles.containsUppercase}">$1</em>`
        );
        setFormattedString(formattedString);
    }, [string]);

    return (
        <div className={styles.root}>
            <Text theme="body1-alt">Preview:</Text>
            <Text
                theme="body1"
                className={cx({
                    [styles.hasUppercase]: hasUppercase,
                })}
                dangerouslySetInnerHTML={{ __html: `"${formattedString}"` }}
            ></Text>
            {hasUppercase && (
                <Text theme="caption" className={styles.warning}>
                    {`⚠️ Warning: Your sentence contains uppercase letters, which may indicate a grammar issue. However, proper names and nouns are exceptions. Please review your text to ensure it appears as intended.`}
                </Text>
            )}
        </div>
    );
};

TextPreview.propTypes = {
    string: PropTypes.string.isRequired,
};

export default TextPreview;
