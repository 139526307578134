class ImageLoader {
    kill() {
        this.active = false;
    }

    load(
        img,
        { base64 = false, base64Type = 'image/jpeg' } = {
            base64: false,
            base64Type: 'image/jpeg',
        }
    ) {
        this.active = true;
        return new Promise((resolve, reject) => {
            const image = new Image();
            image.crossOrigin = 'anonymous';
            const addListeners = () => {
                image.addEventListener('load', handleSuccess, false);
                image.addEventListener('error', handleFailure, false);
            };
            const removeListeners = () => {
                image.removeEventListener('load', handleSuccess, false);
                image.removeEventListener('error', handleFailure, false);
            };
            const handleFailure = () => {
                removeListeners();
                reject(new Error(`Error loading ${img}`));
            };
            const handleSuccess = e => {
                removeListeners();

                if (base64 && this.active) {
                    const canvas = document.createElement('canvas');
                    const ctx = canvas.getContext('2d');
                    canvas.height = image.height;
                    canvas.width = image.width;
                    ctx.drawImage(image, 0, 0);
                    const dataURL = canvas.toDataURL(base64Type);
                    resolve(dataURL);
                } else if (this.active) {
                    resolve(image);
                } else {
                    reject(new Error('Aborted.'));
                }
            };
            addListeners();
            image.src = img;
            if (image.naturalWidth && image.naturalHeight && image.complete) {
                handleSuccess();
            }
        }).catch(err => {
            console.error(err);
        });
    }
}

export const loadImage = (url, options) => {
    return new ImageLoader().load(url, options);
};

export default ImageLoader;
