import {
    trimWhitespaceFromValues,
    validateEndDate,
    validateThought,
    validateStartDate,
} from 'stillnovel/utils/validation';

export default values => {
    // Trim leading and trailing whitespace from values object
    values = trimWhitespaceFromValues(values);

    const vintageOnlyFields =
        values.style === 'vintage'
            ? { woreStatement: !values.woreStatement && 'Required' }
            : {};

    const errors = {
        accept:
            !values.accept &&
            values.accept !== true &&
            'Please confirm your artwork is error-free & ready for print.',
        ateStatement: !values.ateStatement && 'Required',
        baseColor: !values.baseColor && 'Required',
        drankStatement: !values.drankStatement && 'Required',
        endDate: validateEndDate(values.endDate, values.startDate),
        heading: !values.heading && 'Required',
        image: !values.image && 'Required',
        imageMeta: !values.imageMeta && 'Required',
        lovedStatement: !values.lovedStatement && 'Required',
        neverForget1: !values.neverForget1 && 'Required',
        neverForget2: !values.neverForget2 && 'Required',
        numberOfTravelers: !values.numberOfTravelers && 'Required',
        rating: !values.rating && 'Required',
        region: !values.region && 'Required',
        shopifyProductId: !values.shopifyProductId && 'Required',
        startDate: validateStartDate(values.startDate),
        stayedStatement: !values.stayedStatement && 'Required',
        thought: validateThought(values.thought),
        traveledStatement: !values.traveledStatement && 'Required',
        tripAdjective: !values.tripAdjective && 'Required',
        tripLocationData: !values.tripLocationData && 'Required',
        weatherStatement: !values.weatherStatement && 'Required',
        ...vintageOnlyFields,
    };
    return errors;
};
