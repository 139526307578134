import { registerActionTypes, registerStates } from 'stillnovel/constants';

export const actionTypes = registerActionTypes('AUTH', [
    'AUTHENTICATED',
    'BEGIN_PASSWORD_RESET_FLOW',
    'CLEAR_CACHE',
    'CONFIGURE',
    'CONTINUE_PASSWORD_RESET_FLOW',
    'ACCOUNT_VERIFICATION_REQUESTED',
    'ACCOUNT_VERIFICATION_ERROR',
    'ACCOUNT_VERIFICATION_FAILED',
    'ACCOUNT_VERIFICATION_REQUIRED',
    'EMAIL_VERIFICATION_REQUESTED',
    'EMAIL_VERIFICATION_ERROR',
    'EMAIL_VERIFICATION_FAILED',
    'EMAIL_VERIFICATION_REQUIRED',
    'FINISH_PASSWORD_RESET_FLOW',
    'LOGGING_IN',
    'LOGIN',
    'LOGIN_FAILURE',
    'LOGIN_MFA_REQUIRED',
    'LOGIN_NEW_PASSWORD_REQUIRED',
    'LOGOUT',
    'UNAUTHENTICATED_USER',
    'NEW_PASSWORD_REQUIRED_FAILURE',
    'PARTIAL_LOGOUT',
    'UPDATE_USER_ATTRIBUTES',
    'USER_CONFIRM_FAILED',
    'USER_UNCONFIRMED',
    'PASSWORD_RESET_REQUESTED',
    'PASSWORD_RESET_ERROR',
]);

export const states = registerStates('AUTH', [
    'LOGGED_OUT',
    'AUTHENTICATED',
    'LOGGING_IN',
    'LOGGED_IN',
    'UNAUTHENTICATED_USER',
    'NEW_PASSWORD_REQUIRED',
    'MFA_REQUIRED',
    'ACCOUNT_VERIFICATION_REQUIRED',
    'ACCOUNT_VERIFICATION_ERROR',
    'EMAIL_VERIFICATION_REQUIRED',
    'EMAIL_VERIFICATION_ERROR',
    'CONFIRMATION_REQUIRED',
]);

export const configure = config => ({
    type: actionTypes.CONFIGURE,
    config,
});

export const loggingIn = attributes => ({
    type: actionTypes.LOGGING_IN,
    attributes,
});

export const login = user => ({
    type: actionTypes.LOGIN,
    user,
});

export const authenticated = user => ({
    type: actionTypes.AUTHENTICATED,
    user,
});

export const logout = () => ({
    type: actionTypes.LOGOUT,
});

export const unauthenticated = user => ({
    type: actionTypes.UNAUTHENTICATED_USER,
    payload: {
        user,
    },
});

export const partialLogout = () => ({
    type: actionTypes.PARTIAL_LOGOUT,
});

export const loginFailure = (user, error) => ({
    type: actionTypes.LOGIN_FAILURE,
    user,
    error,
});

export const mfaRequired = user => ({
    type: actionTypes.LOGIN_MFA_REQUIRED,
    user,
});

export const newPasswordRequired = user => ({
    type: actionTypes.LOGIN_NEW_PASSWORD_REQUIRED,
    user,
});

export const newPasswordRequiredFailure = (user, error) => ({
    type: actionTypes.NEW_PASSWORD_REQUIRED_FAILURE,
    user,
    error,
});

export const verifyAccount = (attributes, callback) => ({
    type: actionTypes.ACCOUNT_VERIFICATION_REQUESTED,
    payload: { attributes, meta: { callback } },
});

export const resetPassword = attributes => ({
    type: actionTypes.PASSWORD_RESET_REQUESTED,
    attributes,
});

export const verifyEmail = (attributes, callback) => ({
    type: actionTypes.EMAIL_VERIFICATION_REQUESTED,
    payload: { attributes, meta: { callback } },
});

export const emailVerificationRequired = attributes => ({
    type: actionTypes.ACCOUNT_VERIFICATION_REQUIRED,
    attributes,
});

export const emailVerificationFailed = (user, error) => ({
    type: actionTypes.ACCOUNT_VERIFICATION_FAILED,
    user,
    error,
});

export const beginPasswordResetFlow = (user, error) => ({
    type: actionTypes.BEGIN_PASSWORD_RESET_FLOW,
    user,
    error,
});

export const continuePasswordResetFlow = user => ({
    type: actionTypes.CONTINUE_PASSWORD_RESET_FLOW,
    user,
});

export const finishPasswordResetFlow = error => ({
    type: actionTypes.FINISH_PASSWORD_RESET_FLOW,
    error,
});

export const updateAttributes = attributes => ({
    type: actionTypes.UPDATE_USER_ATTRIBUTES,
    attributes,
});

export const confirmationRequired = (user, email) => ({
    type: actionTypes.USER_UNCONFIRMED,
    user,
    email,
});

export const confirmFailed = (user, error) => ({
    type: actionTypes.USER_CONFIRM_FAILED,
    user,
    error,
});

export const clearCache = () => ({
    type: actionTypes.CLEAR_CACHE,
});
