import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Field from 'redux-form/es/Field';
import reduxForm from 'redux-form/es/reduxForm';
import StepNav from 'stillnovel/components/App/Project/Builders/ArtPrint/StepNav';
import { Container, Col, Row } from 'stillnovel/components/UI/Grid';
import DateInput from 'stillnovel/components/UI/Input/DateInput';
import FieldInput from 'stillnovel/components/UI/Input/FieldInput';
import Form from 'stillnovel/components/UI/Form';
import FormHeader from 'stillnovel/components/App/Project/Builders/ArtPrint/FormHeader';
import NavArrows from 'stillnovel/components/UI/NavArrows';
import PlacesAutocomplete from 'stillnovel/components/UI/PlacesAutocomplete';
import Text from 'stillnovel/components/UI/Text';

import validate from '../validate';

let Details = ({
    handleSubmit,
    invalid,
    previousPage,
    project,
    submitting,
}) => {
    return (
        <Fragment>
            <Form>
                <FormHeader heading="How It All Began" />
                <Container>
                    <Row>
                        <Col>
                            <Text
                                tag="label"
                                theme="form-title"
                                htmlFor="ceremonyLocationData"
                            >
                                Where We Met (City & State)
                            </Text>
                            <Field
                                id="ceremonyLocationData"
                                name="ceremonyLocationData"
                                component={PlacesAutocomplete}
                                placeholder="Ex: “Los Angeles, CA”"
                                autoComplete={`${project.id}-ceremonyLocationData`}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Text
                                tag="label"
                                theme="form-title"
                                htmlFor="whereWeMet"
                            >
                                The Place
                            </Text>
                            <Field
                                id="whereWeMet"
                                name="whereWeMet"
                                component={FieldInput}
                                placeholder="Ex: Dean's House"
                                maxLength="23"
                                autoComplete={`${project.id}-whereWeMet`}
                                autoCorrect="off"
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Text
                                tag="label"
                                theme="form-title"
                                htmlFor="togetherWeAre"
                                autoCorrect="off"
                            >
                                Together We Are
                            </Text>
                            <Field
                                id="togetherWeAre"
                                name="togetherWeAre"
                                component={FieldInput}
                                placeholder="Ex: A family"
                                maxLength="23"
                                autoComplete={`${project.id}-togetherWeAre`}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Text tag="label" theme="form-title">
                                Anniversary date <br />
                                <Text theme="caption">
                                    (or when dating started)
                                </Text>
                            </Text>

                            <Field
                                name="beganDatingDate"
                                type="select"
                                component={DateInput}
                                showTime={false}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Text
                                tag="label"
                                theme="form-title"
                                htmlFor="thought"
                            >
                                A Thought (optional)
                            </Text>
                            <Field
                                stretch
                                id="thought"
                                name="thought"
                                type="textarea"
                                component={FieldInput}
                                maxLength="124"
                                showErrorText
                                placeholder="A sentence or two about what you think made this day special."
                            />
                        </Col>
                    </Row>
                </Container>
            </Form>
            <StepNav>
                <NavArrows
                    buttons={[
                        { onClick: previousPage },
                        {
                            onClick: handleSubmit,
                            disabled: invalid || submitting,
                        },
                    ]}
                />
            </StepNav>
        </Fragment>
    );
};

Details.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    invalid: PropTypes.bool,
    previousPage: PropTypes.func.isRequired,
    project: PropTypes.object.isRequired,
    submitting: PropTypes.bool,
};

Details.defaultProps = {
    invalid: false,
    submitting: false,
};

Details = reduxForm({
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: false,
    touchOnBlur: false,
    touchOnChange: true,
    validate,
})(Details);

export default Details;
