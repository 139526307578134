import React from 'react';
import PropTypes from 'prop-types';
import Text from 'stillnovel/components/UI/Text';
import CloseButton from 'stillnovel/components/UI/CloseButton';

import styles from './LowResolutionTips.module.scss';

const LowResolutionTips = ({ onClose }) => {
    return (
        <aside className={styles.root} role="complementary">
            <div className={styles.inner}>
                <CloseButton
                    type="button"
                    className={styles.closeButton}
                    aria-label="Close Tips"
                    onClick={onClose}
                />
                <Text
                    theme="body1-alt"
                    tag="h4"
                    className={styles.headline}
                    isWarning
                >
                    ⚠️ Low resolution photo – cropping disabled
                </Text>
                <Text theme="body1" className={styles.lead}>
                    The key to the best possible quality is finding the original
                    photo (often kept on your computer, the photographer’s
                    online portal, or within your smartphone library).
                </Text>
                <details className={styles.details}>
                    <summary className={styles.summary}>
                        <Text theme="body1-alt" tag="span">
                            Photo Quality Tips
                        </Text>
                    </summary>
                    <ul className={styles.tips}>
                        <li>
                            <Text>
                                <u>Social Media:</u> Images from Instagram or
                                Facebook are often compressed and low
                                resolution.
                            </Text>
                        </li>
                        <li>
                            <Text>
                                <u>Screenshots:</u> Avoid using screenshots;
                                upload the original photo instead.
                            </Text>
                        </li>
                        <li>
                            <Text>
                                <u>Unsure?</u> We can help! Email us at{' '}
                                <a href="mailto:support@stillnovel.com">
                                    support@stillnovel.com
                                </a>
                                .
                            </Text>
                        </li>
                    </ul>
                </details>
            </div>
        </aside>
    );
};

LowResolutionTips.propTypes = {
    onClose: PropTypes.func.isRequired,
};

export default LowResolutionTips;
