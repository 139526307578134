import { Loader } from '@googlemaps/js-api-loader';

export default async (config = {}) => {
    const apiKey = config.KEY || 'AIzaSyAsWVEAodVhNtC1OdAoGmKaET8i0Dwnq9Y';
    const libraries = config.LIBRARIES || ['places'];
    const version = 'weekly';
    const language = 'en';

    const loader = new Loader({
        apiKey,
        libraries,
        version,
        language,
    });

    return await loader.load();
};
