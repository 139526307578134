import PropTypes from 'prop-types';
import React from 'react';

import Text from 'stillnovel/components/UI/Text';

import styles from './Header.scss';

function Header({ headline, tag, children }) {
    return (
        <header className={styles.root}>
            {headline && (
                <Text tag={tag ?? 'h1'} theme="jumbo">
                    {headline}
                </Text>
            )}
            {children}
        </header>
    );
}

Header.propTypes = {
    children: PropTypes.node,
    headline: PropTypes.string,
    tag: PropTypes.string,
};

export default Header;
