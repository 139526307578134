import 'aws-sdk/clients/s3';
import AWS from 'aws-sdk/global';
import SN from 'stillnovel/services';

export default class AWSService {
    s3 = async () => {
        if (AWS.config.credentials.expired) {
            await SN.auth.refreshAWSSession();
        }

        return new AWS.S3({
            apiVersion: '2006-03-01',
            params: { Bucket: 'stillnovel' },
            region: 'us-west-1',
            bucket: 'stillnovel',
            credentials: AWS.config.credentials,
        });
    };
}
