export default class AdminService {
    api;

    constructor(apiService) {
        this.api = apiService;
    }

    async ordersGetById(id) {
        const response = await this.api.get(`/admin/orders/${id}`, {
            withSession: true,
        });
        return response.data;
    }

    async orders(offset = 0, limit = 50) {
        const response = await this.api.get(`/orders`, {
            params: {
                offset,
                limit,
            },
            withSession: true,
        });
        return response.data;
    }

    async userById(id) {
        const response = await this.api.get(`/admin/users/${id}`, {
            withSession: true,
        });
        return response.data;
    }

    async users(offset = 0, limit = 50) {
        const response = await this.api.get(`/users`, {
            params: {
                offset,
                limit,
            },
            withSession: true,
        });
        return response.data;
    }

    async nwOrdersGetById(id) {
        const response = await this.api.get(`/vendor/northwest/${id}`, {
            withSession: true,
        });
        return response.data;
    }

    async nwOrdersGetAll() {
        const response = await this.api.get(`/vendor/northwest/orders`, {
            withSession: true,
        });
        return response.data;
    }

    async nwOrderCreate(orderId) {
        const response = await this.api.post(
            `/vendor/northwest/orders/${orderId}`,
            {},
            {
                withSession: true,
            }
        );
        return response.data;
    }

    async statusShipped(orderId, trackingId) {
        const response = await this.api.post(
            `/admin/orders/${orderId}/ship`,
            { trackingId },
            {
                withSession: true,
            }
        );
        return response.data;
    }

    async discounts() {
        const response = await this.api.get(`/discounts`, {
            withSession: true,
        });
        return response.data;
    }
}
