import { useMount, useUnmount } from 'react-use';
import debounce from 'lodash/debounce';
import { saveProjectRequest } from 'stillnovel/store/project/actions';
import { useDispatch } from 'react-redux';

export const useKeyboardSaveProject = project => {
    const dispatch = useDispatch();

    const saveProjectRequestDebounced = debounce(
        () => dispatch(saveProjectRequest(project?.project?.id)),
        2000,
        { leading: true }
    );

    const handleSaveCommandKeydown = event => {
        var keyCode;

        if (window.event) {
            // IE/Safari/Chrome/Firefox(?)
            keyCode = event.keyCode;
        } else if (event.which) {
            // Netscape/Firefox/Opera
            keyCode = event.which;
        }

        var keyChar = String.fromCharCode(keyCode).toLowerCase();
        if (keyChar == 's' && event.metaKey) {
            event.preventDefault();

            saveProjectRequestDebounced();
            return false; // To prevent normal minimizing command
        }
    };

    useMount(() => {
        document.addEventListener('keydown', handleSaveCommandKeydown);
    });

    useUnmount(() => {
        document.removeEventListener('keydown', handleSaveCommandKeydown);
    });
};
