import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import Text from 'stillnovel/components/UI/Text';

import styles from './ColorSelector.scss';

const getConfigPrefix = (search, config) => {
    const key = Object.keys(config).find(key => {
        const prefix = new RegExp(`^${key}`);
        return prefix.test(search);
    });
    return key ? config[key] || false : false;
};

// TODO move into artistry?
const colorsMap = {
    vintage: [
        { key: 'MAUVE', name: 'Mauve', hex: '#dbcccb' },
        { key: 'SOFT_WHITE', name: 'Soft White', hex: '#fcfaf8' },
        { key: 'SAND', name: 'Sand', hex: '#eaddd2' },
        { key: 'DEFAULT', name: 'No Filter*', hex: '#efefef', opacity: 1 },
    ],
    modern: [
        { key: 'SMOKE', name: 'Smoke', hex: '#dcdfe4', opacity: 1 },
        { key: 'NUDE', name: 'Nude', hex: '#f5e9e5', opacity: 1 },
        { key: 'GREIGE', name: 'Greige', hex: '#e9eaea', opacity: 1 },
        {
            key: 'SOFT_WHITE',
            name: 'Soft White',
            hex: '#e0e1e1',
            opacity: 1,
        },
        { key: 'DEFAULT', name: 'No Filter*', hex: '#efefef', opacity: 1 },
    ],
    'Style 01': [
        { key: 'DEFAULT', name: 'Default', hex: '#FCFAF8', opacity: 1 },
    ],
    'Style 02': [
        {
            key: 'SOFT_WHITE',
            name: 'Soft White',
            hex: '#FCFAF8',
            textHex: '#4B4846',
            opacity: 1,
        },
        {
            key: 'DUSK',
            name: 'Dusk',
            hex: '#5b6374',
            textHex: '#f3eee2',
        },
        {
            key: 'SILVER_SAGE',
            name: 'Silver Sage',
            hex: '#96a6a1',
            textHex: '#f3eee2',
        },
        {
            key: 'FUSCHIA',
            name: 'Fuschia',
            hex: '#f2f6f4',
            textHex: '#ea0e6f',
        },
        {
            key: 'PLUM',
            name: 'Plum',
            hex: '#8b7872',
            textHex: '#dac3b9',
        },
        {
            key: 'DEFAULT',
            name: 'Dawn',
            hex: '#FBFAF8',
            textHex: '#262e31',
        },
    ],
};

// Studio Blue: R=11 G=29 B=61 at 15% opacity.
// Blush: R=226 G=200 B=201 at 40% opacity.
// Smoke: R=177 G=185 B=196 at 45% opacity.

const ColorSelector = ({ style, colors, input, meta: { touched, error } }) => {
    const classNames = cx([styles['input-group'], styles['color-selector']], {
        [styles['color-selector-error']]: touched && error,
    });

    const colorOptions = colors || getConfigPrefix(style, colorsMap);
    if (!colorOptions) {
        console.warn('Check colorOptions value.');
        return;
    }

    return (
        <div className={classNames}>
            <Text tag="h3" theme="form-title">
                Base color
                {input.value ? `: ${input.value?.replace('_', ' ')}` : ''}
            </Text>
            <div className={styles['color-selector-container']}>
                {colorOptions.map(color => {
                    const selected = input.value === color.key;
                    const itemClassNames = cx(styles['color-item'], {
                        [styles['color-item-selected']]: selected,
                    });
                    return (
                        <label key={color.name} className={itemClassNames}>
                            <span
                                style={{
                                    background: `${color.hex}`,
                                    opacity: `${color.opacity}`,
                                    color: `${color.textHex}`,
                                }}
                                className={styles['color-item-block']}
                            >
                                <Text tag="span" theme="body1">
                                    {color.name}
                                </Text>
                            </span>
                            <input
                                {...input}
                                value={color.key}
                                checked={selected}
                                type="radio"
                            />
                        </label>
                    );
                })}
            </div>
        </div>
    );
};

ColorSelector.propTypes = {
    colors: PropTypes.array,
    input: PropTypes.shape({
        value: PropTypes.any,
    }).isRequired,
    meta: PropTypes.any.isRequired,
    style: PropTypes.any,
};

export default ColorSelector;
