import { actionTypes } from './actions';

const {
    DUPLICATE_REQUESTED,
    DUPLICATE_SUCCEEDED,
    DUPLICATE_FAILED,
    FETCH_REQUESTED,
    FETCH_SUCCEEDED,
    FETCH_FAILED,
    DELETE_REQUESTED,
    DELETE_FAILED,
    DELETE_SUCCEEDED,
    RESET,
    UPDATE_PROJECT,
} = actionTypes;

const initialState = {
    items: [],
    isLoading: false,
};

function projectsReducer(state = initialState, action) {
    const cases = {
        [DUPLICATE_REQUESTED]: () => {
            return {
                ...state,
                isLoading: true,
            };
        },
        [DUPLICATE_FAILED]: () => {
            return {
                ...state,
                isLoading: false,
            };
        },
        [DUPLICATE_SUCCEEDED]: payload => {
            return {
                ...state,
                items: [...state.items, payload],
                isLoading: false,
            };
        },
        [FETCH_REQUESTED]: () => {
            return {
                ...state,
                isLoading: true,
            };
        },
        [FETCH_FAILED]: () => {
            return {
                ...state,
                isLoading: false,
            };
        },
        [FETCH_SUCCEEDED]: payload => {
            return {
                ...state,
                items: payload.items,
                isLoading: false,
            };
        },
        [DELETE_SUCCEEDED]: payload => {
            return {
                ...state,
                items: payload.items,
                isLoading: false,
            };
        },
        [DELETE_FAILED]: () => {
            return {
                ...state,
                isLoading: false,
            };
        },
        [DELETE_REQUESTED]: () => {
            return {
                ...state,
                isLoading: true,
            };
        },
        [RESET]: () => {
            return {
                ...initialState,
                isLoading: false,
            };
        },
        [UPDATE_PROJECT]: payload => {
            const newItems = state.items.map(project => {
                if (project.id == payload.id) {
                    return { ...payload };
                }
                return project;
            });

            return {
                ...state,
                items: newItems,
                isLoading: false,
            };
        },
        default: () => state,
    };

    return (cases[action.type] || cases.default)(action.payload);
}

export default projectsReducer;
