export default class SupportService {
    api;
    constructor(apiService) {
        this.api = apiService;
    }
    async getFAQs() {
        const response = await this.api.get(
            `https://stillnovel.zendesk.com/api/v2/help_center/en-us/articles.json`
        );
        return response.data;
    }
}
