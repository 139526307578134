export default class WeatherService {
    api;
    constructor(apiService) {
        this.api = apiService;
    }
    async getWeather(lat, lng, date, timeZone) {
        const response = await this.api.post(
            `/weather`,
            { lat, lng, date, timeZone },
            {
                withSession: true,
            }
        );
        return response.data;
    }
}
