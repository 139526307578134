import {
    trimWhitespaceFromValues,
    validateEndDate,
    validateStartDate,
} from 'stillnovel/utils/validation';

export default values => {
    // Trim leading and trailing whitespace from values object
    values = trimWhitespaceFromValues(values);

    const errors = {
        accept:
            !values.accept &&
            values.accept !== true &&
            'Please confirm your artwork is error-free & ready for print.',
        baseColor: !values.baseColor && 'Required',
        endDate: validateEndDate(values.endDate, values.startDate),
        heading: !values.heading && 'Required',
        image: !values.image && 'Required',
        imageMeta: !values.imageMeta && 'Required',
        inOneWord: !values.inOneWord && 'Required',
        knownFor: !values.knownFor && 'Required',
        numberOfTravelers: !values.numberOfTravelers && 'Required',
        shopifyProductId: !values.shopifyProductId && 'Required',
        startDate: validateStartDate(values.startDate),
        tripAdjective: !values.tripAdjective && 'Required',
        tripLocationData: !values.tripLocationData && 'Required',
    };

    return errors;
};
