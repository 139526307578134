import d from 'debug';
import { geocodeByAddress } from 'react-places-autocomplete';

const debug = d('App:Geocode');

const useGeocodeByAddress = () => {
    return async address => {
        try {
            const results = await geocodeByAddress(address);
            const location = results[0].geometry.location.toJSON();

            debug(`Address Components`, results[0].address_components);

            const state =
                results[0].address_components.filter(
                    object => object.types[0] === 'administrative_area_level_1'
                )[0] || null;

            const neighborhood =
                results[0].address_components.filter(object =>
                    object.types.includes('neighborhood')
                )[0] || null;

            const locality =
                results[0].address_components.filter(object =>
                    object.types.includes('locality')
                )[0] || null;

            const sublocality =
                results[0].address_components.filter(object =>
                    object.types.includes('sublocality')
                )[0] || null;

            const sublocalityLevel1 =
                results[0].address_components.filter(object =>
                    object.types.includes('sublocality_level_1')
                )[0] || null;

            const administrativeAreaLevel2 =
                results[0].address_components.filter(object =>
                    object.types.includes('administrative_area_level_2')
                )[0] || null;

            const country =
                results[0].address_components.filter(object =>
                    object.types.includes('country')
                )[0] || null;

            const city =
                locality ||
                neighborhood ||
                sublocality ||
                sublocalityLevel1 ||
                administrativeAreaLevel2 ||
                state;

            return {
                // timeZone: timeZone.data,
                state: city === state ? country : state,
                city,
                location,
            };
        } catch (e) {
            console.log(e);
            return false;
        }
    };
};

export default useGeocodeByAddress;
