import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Field from 'redux-form/es/Field';
import reduxForm from 'redux-form/es/reduxForm';
import StepNav from 'stillnovel/components/App/Project/Builders/ArtPrint/StepNav';
import { Container, Col, Row } from 'stillnovel/components/UI/Grid';
import FieldInput from 'stillnovel/components/UI/Input/FieldInput';
import Form from 'stillnovel/components/UI/Form';
import FormHeader from 'stillnovel/components/App/Project/Builders/ArtPrint/FormHeader';
import NavArrows from 'stillnovel/components/UI/NavArrows';
import Text from 'stillnovel/components/UI/Text';
import { stripEndPeriod } from 'stillnovel/utils';
import { FieldSelect } from 'stillnovel/components/UI/Input';

import validate from '../validate';

const grandpaTypes = [
    'Golf Grandpa',
    'Fix-it Grandpa',
    'Good Time Grandpa',
    'Facts Grandpa',
    'Silly Grandpa',
    'Super Gramps',
    'Come On Over Grandpa',
    'Not A Short Story Grandpa',
    'Couch Grandpa',
];

const grandmaTypes = [
    'Good Time Grandma',
    'Dont Call Me Grandma',
    'Fancy Granny',
    'The Wise Grandmother',
    'Storytime Grandma',
    'Rock n roll Granny',
    'Sporty Grandma',
    'Old Fashioned Grandma',
    'I Might Be Busy Grandma',
];

const descriptors = [
    'Chef',
    'Storyteller',
    'Cuddler',
    'Straight talker',
    'Motivator',
    'Nurturer',
    'Mentor',
    'Organizer',
    'Helper',
    'Friend',
    'Teacher',
    'Prankster',
    'Mediator',
    'Owie Fixer',
    'Provider',
    'Memory Keeper',
];

let Details = ({
    handleSubmit,
    invalid,
    metadata,
    previousPage,
    project,
    submitting,
}) => {
    const isVintageStyle = project.metadata?.style === 'vintage';
    const charLimit = isVintageStyle ? 30 : 27;
    const { subject } = metadata;
    const isGrandpa = subject === 'grandpa';

    return (
        <Fragment>
            <Form>
                <FormHeader heading="Details" />
                <Container>
                    <Row alignment="bottom">
                        <Col>
                            <Text
                                tag="label"
                                theme="form-title"
                                htmlFor="parentType"
                            >
                                Grandparent Type
                            </Text>
                            <Field
                                id="parent-type"
                                name="parentType"
                                component={FieldSelect}
                                stretch
                            >
                                <option value="" disabled>
                                    --
                                </option>
                                {(isGrandpa ? grandpaTypes : grandmaTypes).map(
                                    descriptor => (
                                        <option
                                            key={descriptor}
                                            value={descriptor}
                                        >
                                            {descriptor}
                                        </option>
                                    )
                                )}
                            </Field>
                        </Col>
                    </Row>
                    <Row alignment="bottom">
                        <Col>
                            <Text
                                tag="label"
                                theme="form-title"
                                htmlFor="descriptor"
                            >
                                World’s best
                            </Text>
                            <Field
                                id="worlds-best"
                                name="descriptor"
                                component={FieldSelect}
                                stretch
                            >
                                <option value="" disabled>
                                    --
                                </option>
                                {descriptors.map(descriptor => (
                                    <option key={descriptor} value={descriptor}>
                                        {descriptor}
                                    </option>
                                ))}
                            </Field>
                        </Col>
                    </Row>
                    <Row alignment="bottom">
                        <Col>
                            <Text
                                tag="label"
                                theme="form-title"
                                htmlFor="adjective1"
                            >
                                {`Two words that best describe ${
                                    isGrandpa ? 'him' : 'her'
                                }`}
                            </Text>
                            <Text
                                tag="span"
                                theme="form-title"
                                style={{ lineHeight: '3.5' }}
                            >
                                <Field
                                    inline
                                    stretch
                                    name="adjective1"
                                    component={FieldInput}
                                    placeholder="Ex: Selfless"
                                    autoComplete={`${project.id}-adjective1`}
                                    autoCapitalize="off"
                                    maxLength={45}
                                    style={{ minWidth: '258px' }}
                                />{' '}
                                and{' '}
                                <Field
                                    inline
                                    stretch
                                    name="adjective2"
                                    component={FieldInput}
                                    placeholder="Ex: Loving"
                                    autoComplete={`${project.id}-adjective2`}
                                    autoCapitalize="off"
                                    maxLength={45}
                                    normalize={stripEndPeriod}
                                    style={{ minWidth: '258px' }}
                                />{' '}
                                .
                            </Text>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Text
                                tag="label"
                                theme="form-title"
                                htmlFor="loveStatement"
                            >
                                {`${isGrandpa ? 'Grandpa' : 'Grandma'}, you love:`}
                            </Text>
                            <Field
                                name="loveStatement"
                                type="text"
                                component={FieldInput}
                                placeholder="Ex: A good book"
                                autoComplete={`${project.id}-loveStatement`}
                                autoCorrect="off"
                            />
                        </Col>

                        <Col>
                            <Text
                                tag="label"
                                theme="form-title"
                                htmlFor="alwaysStatement"
                            >
                                {`${isGrandpa ? 'Grandpa' : 'Grandma'}, you always:`}
                            </Text>
                            <Field
                                name="alwaysStatement"
                                type="text"
                                component={FieldInput}
                                placeholder="Ex: Kiss us goodnight"
                                autoComplete={`${project.id}-alwaysStatement`}
                                autoCorrect="off"
                            />
                        </Col>
                    </Row>
                    <Row alignment="bottom">
                        <Col>
                            <Text
                                tag="label"
                                theme="form-title"
                                htmlFor="neverStatement"
                            >
                                {`${isGrandpa ? 'Grandpa' : 'Grandma'}, you never:`}
                            </Text>
                            <Field
                                name="neverStatement"
                                type="text"
                                component={FieldInput}
                                placeholder="Ex: Sleep in"
                                autoComplete={`${project.id}-neverStatement`}
                                autoCorrect="off"
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Text
                                tag="label"
                                theme="form-title"
                                htmlFor="adviceStatement"
                            >
                                {`${isGrandpa ? 'His' : 'Her'} best advice`}
                            </Text>
                            <Field
                                name="adviceStatement"
                                type="text"
                                component={FieldInput}
                                placeholder="Ex: “Mind your P’s and Q’s” or “Wash your hands”"
                                autoComplete={`${project.id}-adviceStatement`}
                                autoCorrect="off"
                                maxLength={charLimit}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Text
                                tag="label"
                                theme="form-title"
                                htmlFor="lessonStatement"
                            >
                                {`${isGrandpa ? 'He' : 'She'} taught me/us`}
                            </Text>
                            <Field
                                name="lessonStatement"
                                type="text"
                                component={FieldInput}
                                placeholder="Ex: “how to cook” or “selflessness”"
                                autoComplete={`${project.id}-lessonStatement`}
                                autoCorrect="off"
                                maxLength={charLimit}
                            />
                        </Col>
                    </Row>
                </Container>
            </Form>
            <StepNav>
                <NavArrows
                    buttons={[
                        { onClick: previousPage },
                        {
                            onClick: handleSubmit,
                            disabled: invalid || submitting,
                        },
                    ]}
                />
            </StepNav>
        </Fragment>
    );
};

Details.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    invalid: PropTypes.bool,
    metadata: PropTypes.shape({
        subject: PropTypes.string,
    }),
    previousPage: PropTypes.func.isRequired,
    project: PropTypes.object.isRequired,
    submitting: PropTypes.bool,
};

Details.defaultProps = {
    invalid: false,
    submitting: false,
};

Details = reduxForm({
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: false,
    touchOnBlur: false,
    touchOnChange: true,
    validate,
})(Details);

export default Details;
