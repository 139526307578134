import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Field from 'redux-form/es/Field';
import reduxForm from 'redux-form/es/reduxForm';
import Form from 'stillnovel/components/UI/Form';
import NavArrows from 'stillnovel/components/UI/NavArrows';
import ImageInput from 'stillnovel/components/UI/Input/ImageInput';
import productsConfig from 'stillnovel/components/App/Project/config';

import FormHeader from '../../../FormHeader';
import StepNav from '../../../StepNav';
import validate from '../validate';

let BackgroundForm = ({
    handleSubmit,
    invalid,
    metadata,
    previousPage,
    project,
    submitting,
}) => {
    const config = productsConfig['birth-stat'];
    const style = metadata?.style === 'vintage' ? 'vintage' : 'modern';
    const styleConfig = config.styles.find(
        styleConfig => style == styleConfig.styleHandle
    );

    return (
        <Fragment>
            <Form>
                <FormHeader heading="Photo" />
                <Field
                    name="imageMeta"
                    component={ImageInput}
                    targetWidth={styleConfig.photoTargetWidth}
                    targetHeight={styleConfig.photoTargetHeight}
                />
            </Form>
            <StepNav>
                <NavArrows
                    buttons={[
                        { onClick: previousPage },
                        {
                            onClick: handleSubmit,
                            disabled: invalid || submitting,
                        },
                    ]}
                />
            </StepNav>
        </Fragment>
    );
};

BackgroundForm.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    invalid: PropTypes.bool,
    metadata: PropTypes.object.isRequired,
    previousPage: PropTypes.func.isRequired,
    project: PropTypes.object.isRequired,
    submitting: PropTypes.bool,
};

BackgroundForm.defaultProps = {
    invalid: false,
    submitting: false,
};

BackgroundForm = reduxForm({
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: false,
    touchOnBlur: false,
    touchOnChange: true,
    validate,
})(BackgroundForm);

export default BackgroundForm;
