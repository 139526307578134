import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Field from 'redux-form/es/Field';
import reduxForm from 'redux-form/es/reduxForm';
import { Col, Row } from 'stillnovel/components/UI/Grid';
import Form from 'stillnovel/components/UI/Form';
import NavArrows from 'stillnovel/components/UI/NavArrows';
import Text from 'stillnovel/components/UI/Text';

import FormHeader from '../../../FormHeader';
import ColorSelector from '../../shared/ColorSelector';
import StepNav from '../../../StepNav';
import validate from '../validate';

let BackgroundForm = ({
    handleSubmit,
    invalid,
    metadata,
    previousPage,
    project,
    submitting,
}) => {
    return (
        <Fragment>
            <Form>
                <FormHeader heading="Stylize" />
                <Row>
                    <Col>
                        <Field
                            name="baseColor"
                            component={ColorSelector}
                            style={metadata.style || 'modern'}
                        />
                        <Text tag="p" theme="caption">
                            *We apply a softening layer over all photos to allow
                            for the design and text to subtly pop over your
                            image.
                        </Text>
                    </Col>
                </Row>
            </Form>
            <StepNav>
                <NavArrows
                    buttons={[
                        { onClick: previousPage },
                        {
                            onClick: handleSubmit,
                            disabled: invalid || submitting,
                        },
                    ]}
                />
            </StepNav>
        </Fragment>
    );
};

BackgroundForm.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    invalid: PropTypes.bool,
    metadata: PropTypes.object.isRequired,
    previousPage: PropTypes.func.isRequired,
    project: PropTypes.object.isRequired,
    submitting: PropTypes.bool,
};

BackgroundForm.defaultProps = {
    invalid: false,
    submitting: false,
};

BackgroundForm = reduxForm({
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: false,
    touchOnBlur: false,
    touchOnChange: true,
    validate,
})(BackgroundForm);

export default BackgroundForm;
