import {
    trimWhitespaceFromValues,
    validateThought,
} from 'stillnovel/utils/validation';

export default values => {
    // Trim leading and trailing whitespace from values object
    values = trimWhitespaceFromValues(values);

    // Initialize errors object with all expected keys
    // so we can track progress in RequiredFields component
    const errors = {
        firstName: null,
        nickname: null,
        birthDate: null,
        baseColor: null,
        imageMeta: null,
        parentType: null,
        descriptor: null,
        adjective1: null,
        adjective2: null,
        loveStatement: null,
        alwaysStatement: null,
        neverStatement: null,
        adviceStatement: null,
        lessonStatement: null,
        residenceLocationData: null,
        // Initialize only first child and grandchild fields
        child1Name: null,
        grandchild1Name: null,
        // Frame selection
        shopifyProductId: !values.shopifyProductId,
    };

    // General validations
    if (!values.firstName) errors.firstName = 'Required';
    if (!values.nickname) errors.nickname = 'Required';
    if (!values.birthDate) errors.birthDate = 'Required';
    if (!values.baseColor) errors.baseColor = 'Required';
    if (!values.imageMeta) errors.imageMeta = 'Required';
    if (
        !values.luckyNumberCustomNumber &&
        values.luckyNumberOrCustom === 'custom' &&
        values.style === 'modern'
    ) {
        errors.luckyNumberCustomNumber = 'Required';
    }

    if (!values.parentType) errors.parentType = 'Required';
    if (!values.descriptor) errors.descriptor = 'Required';
    if (!values.adjective1) errors.adjective1 = 'Required';
    if (!values.adjective2) errors.adjective2 = 'Required';
    if (!values.loveStatement) errors.loveStatement = 'Required';
    if (!values.alwaysStatement) errors.alwaysStatement = 'Required';
    if (!values.neverStatement) errors.neverStatement = 'Required';
    if (!values.adviceStatement) errors.adviceStatement = 'Required';
    if (!values.lessonStatement) errors.lessonStatement = 'Required';

    // Validate thought
    const thoughtError = validateThought(values.thought);
    if (thoughtError) {
        errors.thought = thoughtError;
    }

    if (!values.residenceLocationData) {
        errors.residenceLocationData = 'Required';
    }

    // Validate children
    let childExists = false;
    for (let i = 1; i <= 20; i++) {
        const childName = values[`child${i}Name`];
        const childGender = values[`child${i}Gender`];

        if (childName) {
            childExists = true;
            if (!childGender) {
                errors[`child${i}Gender`] = 'Required';
            }
        }
    }
    if (!childExists) {
        errors.child1Name = 'At least one child is required';
    }

    // Validate grandchildren
    let grandchildExists = false;
    for (let i = 1; i <= 20; i++) {
        const grandchildName = values[`grandchild${i}Name`];

        if (grandchildName) {
            grandchildExists = true;
        }
    }
    if (!grandchildExists) {
        errors.grandchild1Name = 'At least one grandchild is required';
    }

    return errors;
};
