import {
    delay,
    put,
    takeEvery,
    takeLatest,
    select,
    call,
} from 'redux-saga/effects';
import { plugin, event } from 'react-ga';
import { trackPixelEvent } from 'core/utils/fb-pixel';
import SN from 'stillnovel/services';
import productConfig from 'stillnovel/components/App/Project/config';

import { getProjects } from '../projects/actions';
import { saveProject } from '../project/saga';

import { actionTypes } from './actions';

export const getProject = state => state.project;
export const getCart = state => state.cart;
export const getCartItem = (state, id) =>
    state.cart.find(item => item.id === id);

function doTracking(project) {
    try {
        const { id, builderHandle, shopifyProductHandle } = project;

        window._learnq.push([
            'track',
            'Add to Cart',
            {
                ProjectID: id,
                ProductName: builderHandle,
                SKU: shopifyProductHandle,
            },
        ]);

        // Send default GA event
        event({
            category: 'ecommerce',
            action: 'add_to_cart',
        });

        // GA Ecommerce: Add item to the shopping cart
        plugin.execute('ecommerce', 'addItem', {
            id: shopifyProductHandle,
            name: builderHandle,
        });

        //Track standard FB Pixel event
        trackPixelEvent('AddToCart', {
            // eslint-disable-next-line camelcase
            content_name: builderHandle,
            // eslint-disable-next-line camelcase
            content_category: shopifyProductHandle,
            // eslint-disable-next-line camelcase
            content_type: 'product',
        });

        // Pinterest
        pintrk('track', 'AddToCart', {
            // eslint-disable-next-line camelcase
            product_name: builderHandle,
            // eslint-disable-next-line camelcase
            product_category: shopifyProductHandle,
        });
    } catch (e) {
        console.error(e);
    }
}

function* addProjectToCart(action) {
    const { project } = yield select(getProject);
    const prevState = yield select(getCart);

    // check if in cart
    const inCart = prevState.lineItems?.find(item => {
        return (
            item.customAttributes.find(attr => attr.key === '_projectId')
                .value === project.id
        );
    });

    yield put({
        type: actionTypes.ADD_ITEM_REQUESTED,
    });

    try {
        yield call(saveProject, { saveOrFail: true });
    } catch (e) {
        yield put({
            type: actionTypes.ADD_ITEM_ERROR,
            payload: { state: prevState, message: e.message },
        });
        return;
    }

    const projectProductConfig = productConfig[project.builderHandle];

    const customAttributes = project => {
        return [
            ...(projectProductConfig?.shopifyLineItemAttributes?.(project) ||
                []),
            { key: '_projectId', value: project.id },
            {
                key: '_builderHandle',
                value: project.builderHandle,
            },
            { key: '_metadata', value: JSON.stringify(project.metadata) },
        ];
    };

    try {
        let response;
        if (inCart) {
            response = yield call(() =>
                SN.cart.updateItem({
                    variantId: project.shopifyProductId,
                    id: inCart.id,
                    customAttributes: customAttributes(project),
                })
            );
        } else {
            response = yield call(() =>
                SN.cart.addItem({
                    variantId: project.shopifyProductId,
                    quantity: 1,
                    customAttributes: customAttributes(project),
                })
            );
        }

        yield put(getProjects());

        yield put({
            type: actionTypes.ADD_ITEM_SUCCESS,
            payload: response,
        });

        action.payload?.meta?.callback();

        doTracking(project);
    } catch (e) {
        yield put({
            type: actionTypes.ADD_ITEM_ERROR,
            payload: { state: prevState, message: e.message },
        });
    }
}

function* fetchAllItems(action) {
    try {
        yield call(() => SN.cart.setUser(action.payload.userId));
        const items = yield call(() => SN.cart.getAll());

        yield put({
            type: actionTypes.FETCH_SUCCEEDED,
            payload: items,
        });
    } catch (e) {
        yield put({
            type: actionTypes.FETCH_FAILED,
            payload: { message: e.message },
        });
    }
}

function* updateItemQty(action) {
    const { id, qty } = action.payload;
    const prevState = yield select(getCart);

    yield put({
        type: actionTypes.UPDATE_ITEM_QTY,
        payload: action.payload,
    });

    yield delay(500);

    try {
        const response = yield call(() =>
            SN.cart.updateItem({
                id: id,
                quantity: qty,
            })
        );
        yield put({
            type: actionTypes.UPDATE_ITEM_QTY_SUCCESS,
            payload: response,
        });
    } catch (e) {
        yield put({
            type: actionTypes.UPDATE_ITEM_QTY_ERROR,
            payload: { state: prevState, message: e.message },
        });
    }
}

function* removeItem(action) {
    const prevState = yield select(getCart);
    yield put({
        type: actionTypes.REMOVE_ITEM,
        payload: action.payload,
    });
    const {
        payload: { id },
    } = action;
    try {
        const response = yield call(() => SN.cart.deleteItem(id));
        yield put({
            type: actionTypes.REMOVE_ITEM_SUCCESS,
            payload: response,
        });
    } catch (e) {
        yield put({
            type: actionTypes.REMOVE_ITEM_ERROR,
            payload: { state: prevState, message: e.message },
        });
    }
}

function* clearCheckout() {
    yield call(() => SN.cart.clearCheckoutId());
    yield call(fetchAllItems);
}

export default [
    takeEvery(actionTypes.ADD_PROJECT_TO_CART, addProjectToCart),
    takeEvery(actionTypes.FETCH_REQUESTED, fetchAllItems),
    takeLatest(actionTypes.UPDATE_ITEM_QTY_REQUESTED, updateItemQty),
    takeLatest(actionTypes.REMOVE_ITEM_REQUESTED, removeItem),
    takeLatest(actionTypes.CLEAR_CHECKOUT, clearCheckout),
];
