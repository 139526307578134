import { trimWhitespaceFromValues } from 'stillnovel/utils/validation';

export default values => {
    // Trim leading and trailing whitespace from values object
    values = trimWhitespaceFromValues(values);

    const errors = {
        accept:
            !values.accept &&
            values.accept !== true &&
            'Please confirm your artwork is error-free & ready for print.',
        baseColor: !values.baseColor && 'Required',
        startDate: !values.startDate && 'Required',
        heading: !values.heading && 'Required',
        firstName: !values.firstName && 'Required',
        grade: !values.grade && 'Required',
        ageOfChild: !values.ageOfChild && 'Required',
        teacherName: !values.teacherName && 'Required',
        youLove: !values.youLove && 'Required',
        favoriteSubject: !values.favoriteSubject && 'Required',
        favoriteFood: !values.favoriteFood && 'Required',
        favoritesListText: !values.favoritesListText && 'Required',
        whenIgrowUpOrGoalsText: !values.whenIgrowUpOrGoalsText && 'Required',
        schoolName: !values.schoolName && 'Required',
        image: !values.image && 'Required',
        imageMeta: !values.imageMeta && 'Required',
        shopifyProductId: !values.shopifyProductId && 'Required',
    };

    return errors;
};
