import PropTypes from 'prop-types';
import React from 'react';
import { Col, Row } from 'stillnovel/components/UI/Grid';
import Text from 'stillnovel/components/UI/Text';
import FieldRadio from 'stillnovel/components/UI/Input/FieldRadio';
import Field from 'redux-form/es/Field';

const defaultOptions = [
    { label: 'Modern', value: 'modern' },
    { label: 'Vintage', value: 'vintage' },
];

const StyleSelector = ({ options = defaultOptions, style }) => {
    const hideIfLessThan2Selections =
        options.length < 2 ? { display: 'none' } : {};
    return (
        <Row style={hideIfLessThan2Selections}>
            <Col colSpan={1}>
                <Text tag="label" theme="form-title" htmlFor="style">
                    Design
                </Text>
            </Col>
            {options.map(({ label, value }, i) => (
                <Col key={value} colSpan={i + 1}>
                    <Field
                        checked={style === value}
                        name="style"
                        component={FieldRadio}
                        label={label}
                        id={value}
                    />
                </Col>
            ))}
        </Row>
    );
};

StyleSelector.propTypes = {
    options: PropTypes.array,
    style: PropTypes.string,
};

export default StyleSelector;
