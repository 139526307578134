import ExtendableError from 'extendable-error';

export class InvalidRefreshTokenException extends ExtendableError {}

export class AuthException extends ExtendableError {}

export class ClientValidationException extends ExtendableError {
    errors = null;
    constructor({ errors }) {
        super(errors);
        this.errors = errors;
    }
}

export class EmailVerificationException extends ExtendableError {
    message =
        'This account has either already been validated or link is invalid';
    constructor({ message = '' }) {
        super(message);
        this.message = message;
    }
}

export class FacebookUserNotRegisteredException extends ExtendableError {
    message = 'This Facebook account is not registered';
    constructor({ message = '' }) {
        super(message);
        this.message = message;
    }
}

export class TokenVerificationException extends ExtendableError {
    message = 'Invalid token';
    constructor({ message = '' }) {
        super(message);
        this.message = message;
    }
}

export class TimeoutException extends ExtendableError {
    message = 'The request timed out. Please contact support@stillnovel.com.';
}

export class UnknownException extends ExtendableError {
    message =
        'An issue occurred processing your order. Please contact support@stillnovel.com.';
}

export class InvalidProjectTypeException extends ExtendableError {}
