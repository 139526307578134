import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Field from 'redux-form/es/Field';
import reduxForm from 'redux-form/es/reduxForm';
import StepNav from 'stillnovel/components/App/Project/Builders/ArtPrint/StepNav';
import { Container, Col, Row } from 'stillnovel/components/UI/Grid';
import DateInput from 'stillnovel/components/UI/Input/DateInput';
import FieldInput from 'stillnovel/components/UI/Input/FieldInput';
import Form from 'stillnovel/components/UI/Form';
import FormHeader from 'stillnovel/components/App/Project/Builders/ArtPrint/FormHeader';
import NavArrows from 'stillnovel/components/UI/NavArrows';
import PlacesAutocomplete from 'stillnovel/components/UI/PlacesAutocomplete';
import Text from 'stillnovel/components/UI/Text';
import { FieldSelect } from 'stillnovel/components/UI/Input';
import Button from 'stillnovel/components/UI/Button';

import validate from '../validate';

import styles from './Details.scss';

let Details = ({
    handleSubmit,
    invalid,
    metadata,
    previousPage,
    project,
    submitting,
}) => {
    const { subject } = metadata;
    const isDad = subject === 'dad';

    // Check for the existence of child[i][name|gender] in metadata
    // and create an array of the indexes of the children
    const childArray = Array(20)
        .fill(null)
        .map((_, i) => {
            if (
                metadata[`child${i + 1}Name`] &&
                metadata[`child${i + 1}Gender`]
            ) {
                return i + 1;
            }
            return 0;
        })
        .filter(Boolean);

    const childDataCount = Math.max(...childArray, 0);
    const [childCount, setChildCount] = useState(childDataCount);

    const handleAddChild = () => {
        setChildCount(childCount + 1);
        return false;
    };

    useEffect(() => {
        // If there are no children, show an empty child field
        !childCount && setChildCount(1);
    }, []);

    return (
        <Fragment>
            <Form>
                <FormHeader heading="Details" />
                <Container>
                    <Row alignment="bottom">
                        <Col>
                            <Text
                                tag="label"
                                theme="form-title"
                                htmlFor="parentDate"
                            >
                                {`Date ${isDad ? 'he' : 'she'} became a ${
                                    isDad ? 'dad' : 'mom'
                                }`}
                            </Text>
                            <Field
                                name="parentDate"
                                type="select"
                                component={DateInput}
                                showTime={false}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Text tag="h3" theme="form-title">
                                {`List ${isDad ? 'his' : 'her'} children`}
                            </Text>
                            <ul className={styles.childList} role="list">
                                {Array(childCount)
                                    .fill(null)
                                    .map((_, i) => (
                                        <li
                                            key={i}
                                            className={styles.childItem}
                                        >
                                            <div className={styles.childField}>
                                                <Text
                                                    tag="label"
                                                    theme="form-title"
                                                    htmlFor={`child${
                                                        i + 1
                                                    }Name`}
                                                >
                                                    {`Child #${i + 1}`}
                                                </Text>
                                                <Field
                                                    name={`child${i + 1}Name`}
                                                    type="text"
                                                    component={FieldInput}
                                                    placeholder="First Name"
                                                    autoComplete={`${
                                                        project.id
                                                    }-child${i + 1}Name`}
                                                    autoCorrect="off"
                                                />
                                                <Field
                                                    id={`child${i + 1}-gender`}
                                                    name={`child${i + 1}Gender`}
                                                    component={FieldSelect}
                                                    stretch
                                                >
                                                    <option value="" disabled>
                                                        Select Gender Icon
                                                    </option>
                                                    <option value="female">
                                                        Female
                                                    </option>
                                                    <option value="male">
                                                        Male
                                                    </option>
                                                </Field>
                                            </div>
                                        </li>
                                    ))}
                            </ul>
                            <Button
                                xsmall
                                block
                                theme="body1-alt"
                                disabled={childDataCount < childCount}
                                className={styles.addChildButton}
                                onClick={handleAddChild}
                            >
                                + Add more
                            </Button>
                        </Col>
                    </Row>
                    <Row alignment="bottom">
                        <Col>
                            <Text
                                tag="label"
                                theme="form-title"
                                htmlFor="thought"
                            >
                                {`A note to ${isDad ? 'dad' : 'mom'}`}
                            </Text>
                            <Field
                                stretch
                                id="thought"
                                name="thought"
                                type="textarea"
                                component={FieldInput}
                                maxLength="124"
                                showErrorText
                                placeholder={`A sentence or two sharing what ${
                                    isDad ? 'dad' : 'mom'
                                } means to you, i.e. a thank-you note.`}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Text
                                tag="label"
                                theme="form-title"
                                htmlFor="residenceLocationData"
                            >
                                {`Where ${isDad ? 'he' : 'she'} lives`}
                            </Text>
                            <Field
                                name="residenceLocationData"
                                component={PlacesAutocomplete}
                                placeholder="Ex: “Los Angeles, CA”"
                                autoComplete={`${project.id}-residenceLocationData`}
                            />
                        </Col>
                    </Row>
                </Container>
            </Form>
            <StepNav>
                <NavArrows
                    buttons={[
                        { onClick: previousPage },
                        {
                            onClick: handleSubmit,
                            disabled: invalid || submitting,
                        },
                    ]}
                />
            </StepNav>
        </Fragment>
    );
};

Details.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    invalid: PropTypes.bool,
    metadata: PropTypes.shape({
        subject: PropTypes.string,
    }),
    previousPage: PropTypes.func.isRequired,
    project: PropTypes.object.isRequired,
    submitting: PropTypes.bool,
};

Details.defaultProps = {
    invalid: false,
    submitting: false,
};

Details = reduxForm({
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: false,
    touchOnBlur: false,
    touchOnChange: true,
    validate,
})(Details);

export default Details;
