import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Field from 'redux-form/es/Field';
import reduxForm from 'redux-form/es/reduxForm';
import { useSelector } from 'react-redux';
import { stripQuotes } from 'stillnovel/utils';
import { Container, Col, Row } from 'stillnovel/components/UI/Grid';
import FieldInput from 'stillnovel/components/UI/Input/FieldInput';
import FieldRadio from 'stillnovel/components/UI/Input/FieldRadio';
import FieldSelect from 'stillnovel/components/UI/Input/FieldSelect';
import Form from 'stillnovel/components/UI/Form';
import NavArrows from 'stillnovel/components/UI/NavArrows';
import Text from 'stillnovel/components/UI/Text';

import TextPreview from 'stillnovel/components/UI/TextPreview';

import FormHeader from '../../../FormHeader';
import validate from '../validate';
import StepNav from '../../../StepNav';

const dates = new Array(100).fill('');

let Stats = ({
    handleSubmit,
    invalid = false,
    previousPage,
    submitting = false,
    project,
    metadata,
}) => {
    const guestCountType = useSelector(state => {
        return state?.form?.project?.values?.guestCountType || 'COUNT';
    });

    return (
        <Fragment>
            <Form>
                <FormHeader heading="Additional Details" />
                <Container>
                    <Row>
                        <Col>
                            <Text
                                tag="label"
                                theme="form-title"
                                htmlFor="officiatedBy"
                            >
                                Officiated by
                            </Text>
                            <Field
                                name="officiatedBy"
                                component={FieldInput}
                                placeholder="Ex: “Harrold Walkins”"
                                maxLength="29"
                                autoComplete={`${project.id}-officiatedBy`}
                            />
                        </Col>
                        {/* with just the two of us
                        with friends and family */}
                    </Row>
                    <Row>
                        <Col>
                            <Text
                                tag="label"
                                theme="form-title"
                                htmlFor="guestCount"
                            >
                                Guests
                            </Text>
                            <Field
                                name="guestCountType"
                                component={FieldRadio}
                                label="Just the two of us"
                                id="TWO_OF_US"
                            />
                            <Field
                                name="guestCountType"
                                component={FieldRadio}
                                label="Friends and family"
                                id="FRIENDS_FAMILY"
                            />
                            <Field
                                name="guestCountType"
                                checked={guestCountType === 'COUNT'}
                                component={FieldRadio}
                                label="Specify count"
                                id="COUNT"
                            />
                            {guestCountType === 'COUNT' && (
                                <Field
                                    name="guestCount"
                                    component={FieldInput}
                                    type="number"
                                    inputMode="numeric"
                                    pattern="[0-9]*"
                                    placeholder="200"
                                    autoComplete={`${project.id}-guestCount`}
                                />
                            )}
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Text
                                tag="label"
                                theme="form-title"
                                htmlFor="songTitle"
                            >
                                A song that reminds us of the day
                            </Text>
                            <Field
                                name="songTitle"
                                component={FieldInput}
                                placeholder="Ex: “Only love”"
                                autoComplete={`${project.id}-songTitle`}
                                autoCorrect="off"
                            />
                        </Col>

                        <Col>
                            <Text
                                tag="label"
                                theme="form-title"
                                htmlFor="songArtist"
                            >
                                Song Artist
                            </Text>
                            <Field
                                name="songArtist"
                                component={FieldInput}
                                placeholder="Ex: “Ben Howard -or- Uncle Kev”"
                                autoComplete={`${project.id}-songArtist`}
                                autoCorrect="off"
                            />
                        </Col>
                    </Row>
                    {project.metadata?.style === 'modern' && (
                        <Row>
                            <Col>
                                <Text
                                    tag="label"
                                    theme="form-title"
                                    htmlFor="songLyric"
                                >
                                    Song Lyric
                                </Text>
                                <Field
                                    name="songLyric"
                                    component={FieldInput}
                                    placeholder="Ex: “Darling you’re with me,
                            always around me.”"
                                    autoComplete={`${project.id}-songLyric`}
                                    maxLength="84"
                                    autoCorrect="off"
                                    normalize={stripQuotes}
                                />
                            </Col>
                        </Row>
                    )}
                    <Row>
                        <Col>
                            <Text
                                tag="span"
                                theme="form-title"
                                style={{ lineHeight: '3.5' }}
                            >
                                We met{' '}
                                <Field
                                    inline
                                    id="metLocationPreposition"
                                    name="metLocationPreposition"
                                    component={FieldSelect}
                                >
                                    <option value="" />
                                    <option value="at">AT</option>
                                    <option value="in">IN</option>
                                    <option value="on">ON</option>
                                </Field>{' '}
                                <Field
                                    inline
                                    name="metLocationName"
                                    component={FieldInput}
                                    placeholder="Ex: “College”"
                                    autoComplete={`${project.id}-metLocationName`}
                                />{' '}
                                in{' '}
                                <Field
                                    inline
                                    id="metYear"
                                    name="metYear"
                                    component={FieldSelect}
                                >
                                    <option value="" disabled>
                                        YEAR
                                    </option>
                                    {dates.map((o, i) => {
                                        const date =
                                            parseInt(new Date().getFullYear()) -
                                            i;
                                        return (
                                            <option key={date} value={date}>
                                                {date}
                                            </option>
                                        );
                                    })}
                                </Field>{' '}
                                and got engaged{' '}
                                <Field
                                    inline
                                    id="engagedLocationPreposition"
                                    name="engagedLocationPreposition"
                                    component={FieldSelect}
                                >
                                    <option value="" />
                                    <option value="at">AT</option>
                                    <option value="in">IN</option>
                                    <option value="on">ON</option>
                                </Field>{' '}
                                <Field
                                    inline
                                    name="engagedLocationName"
                                    component={FieldInput}
                                    placeholder="Ex: “Spain”"
                                    autoComplete={`${project.id}-engagedLocationName`}
                                />{' '}
                                in{' '}
                                <Field
                                    inline
                                    id="engagedYear"
                                    name="engagedYear"
                                    component={FieldSelect}
                                >
                                    <option value="" disabled>
                                        YEAR
                                    </option>
                                    {dates.map((o, i) => {
                                        const date =
                                            parseInt(new Date().getFullYear()) -
                                            i;
                                        return (
                                            <option key={date} value={date}>
                                                {date}
                                            </option>
                                        );
                                    })}
                                </Field>
                            </Text>
                            <TextPreview
                                string={`We met <em>${metadata.metLocationPreposition ?? ''}</em> <em>${metadata.metLocationName ?? ''}</em> in <em>${metadata.metYear ?? ''}</em> and got engaged <em>${metadata.engagedLocationPreposition ?? ''}</em> <em>${metadata.engagedLocationName ?? ''}</em> in <em>${metadata.engagedYear ?? ''}</em>.`}
                            />
                        </Col>
                    </Row>
                </Container>
            </Form>
            <StepNav>
                <NavArrows
                    buttons={[
                        { onClick: previousPage },
                        {
                            onClick: handleSubmit,
                            disabled: invalid || submitting,
                            label: 'Review',
                        },
                    ]}
                />
            </StepNav>
        </Fragment>
    );
};

Stats.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    invalid: PropTypes.bool,
    previousPage: PropTypes.func.isRequired,
    submitting: PropTypes.bool,
    project: PropTypes.object.isRequired,
    metadata: PropTypes.object.isRequired,
};

Stats = reduxForm({
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: false,
    touchOnBlur: false,
    touchOnChange: true,
    validate,
})(Stats);

export default Stats;
